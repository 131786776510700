import React,  { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { DatePicker, Table, Modal, Typography, Popconfirm, Input, notification, Button, Select, Switch } from 'antd'
import './RapBackSummary.scss'
import { BsFillPersonLinesFill } from 'react-icons/bs';
import {
  PrinterOutlined
} from "@ant-design/icons";
//import { mockrap } from '../../components/ApplicantDetailsBody/Results/CustomEditor/mockrap'
import { mockRapXML } from '../../components/ApplicantDetailsBody/Results/CustomEditor/mockRapXML';
import { getDropdownOptions} from './functions';
import { ColumnsVisibilityButton } from './components';
import { ALL_COLUMNS,stateSpecficDefaultRapbackColumns } from './RapbackSummaryTableData';
import { MdDeleteForever } from 'react-icons/md';
import { RangeValue, disabledDate } from 'components/DateRangePicker/DateRangePicker';
import dayjs from "dayjs";
import debounce from "debounce";
import { SortOrder } from "antd/lib/table/interface";
//import  { useRapBack } from "hooks/useRapBack"
import  { useQueryRapBack } from "hooks/useQueryRapBack"
import  { useRapBackCount } from "hooks/useRapBackCount"
import { useUserCognito } from "hooks/useUserCognito";
import { Navigate } from "react-router-dom";
import printText from "utils/printText";
import { useAudit } from "hooks/useAudit";
import { Auth } from 'aws-amplify';
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { TableFilters } from 'pages/TransactionSummary/TransactionSummaryTable/TransactionSummaryTableData';


export enum FilterMode {
  AND = "and",
  OR = "or",
}

enum Ref {
  firstNameRef = "firstNameRef"
}

enum TableScroll {
  GT1859 = "calc(100vh - 356px)",
  LT1859 = "calc(100vh - 378px)",
}

export const filterModeOptions = [
  { value: FilterMode.AND, label: "And" },
  { value: FilterMode.OR, label: "Or" },
]

export const RapBackSummary: React.FC<any> = () => {
  const [scrollBreakPoint, setScrollBreakPoint] = useState(window.innerWidth > 1859 ? TableScroll.GT1859 : TableScroll.LT1859);
  const [dropDownOptions] = useState(getDropdownOptions(ALL_COLUMNS));
  const [activeColumns, setActiveColumns] =useState<readonly string[]>(stateSpecficDefaultRapbackColumns(`${process.env.REACT_APP_STAGE}`)!);
  const [permissions, setPermissions ] = useState<any>({})
  const [total, setTotal] = useState(0);
  const [sortOrder, setSortOrder] = useState<any>('desc');
  const [currentSortedColumn, setCurrentSortedColumn] = useState<string>('rapbackdate');
  const { addAuditEvent } = useAudit();
  const [filterText, setFilterText] = useState("")
  const [columnName, setColumnName] = useState('default');
  const [allowed, setAllowed] = useState(true)
  const { usersPermissions } = useUserCognito()
  const [openRapBackModal, setOpenRapBackModal] = useState(false)
  const [editingKey, setEditingKey] = useState<string | number>("");
  const [tableFilters, setTableFilters] = useState<any>({})
  const handleFilteringRef = React.useRef<any>(null);
  const viewRef = React.useRef<any>("")
  const firstNameRef = React.useRef<any>("")
  const middleNameRef = React.useRef<any>("")
  const lastNameRef = React.useRef<any>("")
  const orisRef = React.useRef<any>("")
  const ssnRef = React.useRef<any>("")
  const agencyRef = React.useRef<any>("")
  const sidRef = React.useRef<any>("")
  const dobRef = React.useRef<any>("")
  const reasonRef = React.useRef<any>("")
  const isHitRef = React.useRef<any>("")
  const rapBackDateRef = React.useRef<any>("")
  const insertdateRef = React.useRef<any>("")
  const [filterMode, setFilterMode] = useState(FilterMode.AND);
  const showToastAgainRef = React.useRef<boolean>(true);
  const tableFiltersString = JSON.stringify(tableFilters)
  const [{currentPage, pageSize}, setPaginationState] = useState({pageSize: 25, currentPage: 1});
  const { count } = useRapBackCount()
  const [details, setDetails] = useState("")
  const [sidCurrent, setSid] = useState("")
  const [ itemCount, setCount ] = useState("")
  const [ calls, setCalls ] = useState(0)
  const [displaySSN, setDisplaySSN ] = useState(null)
  const [refs, setRefs] = useState<any>({firstNameRef,
    middleNameRef, 
    lastNameRef, 
    orisRef,
    ssnRef,
    agencyRef,
    dobRef,
    reasonRef,
    isHitRef,
    rapBackDateRef,
    insertdateRef
    })
  const countRef = React.useRef();

  const refName: any = "firstNameRef"

  const { data: rapBacks, totalRecords, loading }: any = useQueryRapBack(pageSize, currentPage, sortOrder, currentSortedColumn, tableFilters)
  const [tableData, setTableData] = useState<Array<any>>(rapBacks);

  useEffect(() => {
    setRefs({firstNameRef, middleNameRef, 
      lastNameRef, 
      orisRef,
      ssnRef,
      agencyRef,
      dobRef,
      reasonRef,
      isHitRef,
      rapBackDateRef,
      insertdateRef})
  }, [firstNameRef, middleNameRef, 
    lastNameRef, 
    orisRef,
    ssnRef,
    agencyRef,
    dobRef,
    reasonRef,
    isHitRef,
    rapBackDateRef,
    insertdateRef])

  useEffect(() => {
    console.log('rapBackSummary usersPermissions updated: ', usersPermissions)
    setAllowed(usersPermissions.viewRapBackDetails)
    setPermissions(usersPermissions)
  }, [usersPermissions])

  useEffect(() => {
    console.log('rapBackSummary rapbacks in rapbackSummary: ', rapBacks)
    setTableData(rapBacks)
    setTotal(totalRecords)
    //if(rapBacks) setDetails(rapBacks.fileData)
  }, [rapBacks])

  const { RangePicker } = DatePicker;

  const formatFilterText = (text: any) => {
    if (!text) return "";
    return text.toString().toLowerCase().trim();
  };

  const openNotification = () => {
    notification.warning({
      message: 'No Results Found',
      description:
        'Check spelling or try modifying your filter criteria.',
      duration: 2
    });
  };

  const dateRangeFilter = useCallback((rowDate: any) => {
    console.log('rapBackSummary debug filter dateRangeFilter tableFilters: ', tableFilters)
   
    const rowDateAsDate = Date.parse(dayjs(rowDate).format("MM/DD/YYYY"));
    let startDateAsDate: any;
    let endDateAsDate: any;

    if(tableFilters.rapbackdate) {
     

      startDateAsDate = tableFilters.rapbackdate[0] ?
        Date.parse(dayjs(tableFilters.rapbackdate[0]).format("MM/DD/YYYY")) :
        Date.parse(dayjs(EARLIEST_DATE).format("MM/DD/YYYY"));

      endDateAsDate =
        tableFilters.rapbackdate[1] ?
          Date.parse(dayjs(tableFilters.rapbackdate[1]).format("MM/DD/YYYY")) :
          Date.parse(dayjs(TODAY).format("MM/DD/YYYY"));
    }

    if(tableFilters.dob) {
      startDateAsDate = tableFilters.dob[0] ?
        Date.parse(dayjs(tableFilters.dob[0]).format("MM/DD/YYYY")) :
        Date.parse(dayjs(EARLIEST_DATE).format("MM/DD/YYYY"));

      endDateAsDate =
        tableFilters.dob[1] ?
          Date.parse(dayjs(tableFilters.dob[1]).format("MM/DD/YYYY")) :
          Date.parse(dayjs(TODAY).format("MM/DD/YYYY"));
    }
    if(tableFilters.insertdate) {
      startDateAsDate = tableFilters.dob[0] ?
        Date.parse(dayjs(tableFilters.insertdate[0]).format("MM/DD/YYYY")) :
        Date.parse(dayjs(EARLIEST_DATE).format("MM/DD/YYYY"));

      endDateAsDate =
        tableFilters.dob[1] ?
          Date.parse(dayjs(tableFilters.insertdate[1]).format("MM/DD/YYYY")) :
          Date.parse(dayjs(TODAY).format("MM/DD/YYYY"));
    }
    

    return ((rowDateAsDate >= startDateAsDate) && (rowDateAsDate <= endDateAsDate))

  },[tableFilters]);

  const handleFilterChange = (value: any, filerType:string) => {
    console.log(`dateRangeFilter value: ${JSON.stringify(value, null, "\t")}`)
    console.log('dateRangeFilter Object.keys(value).length: ', Object.keys(value).length)
    switch(filerType){
      case 'rapbackdate':
        if(Object.keys(value).length > 0) {
          setTableFilters((currentTableFilters: TableFilters) => (
            {
                ...currentTableFilters,
                rapbackdate: [value?.["0"] && dayjs(value?.["0"]).format('MM/DD/YYYY'), value?.["1"] && dayjs(value?.["1"]).format('MM/DD/YYYY')]
            }
            ))
        } else {
          setTableFilters((currentTableFilters: any) => {
            console.log('dateRangeFilter currentTableFilters: ', currentTableFilters)
            delete currentTableFilters.rapbackdate
            return {
                ...currentTableFilters,
            }
          })
        }
        break;
        case 'dob':
          if(Object.keys(value).length > 0) {
            setTableFilters((currentTableFilters: TableFilters) => (
              {
                  ...currentTableFilters,
                  dob: [value?.["0"] && dayjs(value?.["0"]).format('MM/DD/YYYY'), value?.["1"] && dayjs(value?.["1"]).format('MM/DD/YYYY')]
              }
              ))
          } else {
            setTableFilters((currentTableFilters: any) => {
              console.log('dateRangeFilter currentTableFilters: ', currentTableFilters)
              delete currentTableFilters.dob
              return {
                  ...currentTableFilters,
              }
            })
          }
          break;
        case 'insertdate':
          if(Object.keys(value).length > 0) {
            setTableFilters((currentTableFilters: TableFilters) => (
              {
                  ...currentTableFilters,
                  insertdate: [value?.["0"] && dayjs(value?.["0"]).format('MM/DD/YYYY'), value?.["1"] && dayjs(value?.["1"]).format('MM/DD/YYYY')]
              }
              ))
          } else {
            setTableFilters((currentTableFilters: any) => {
              console.log('dateRangeFilter currentTableFilters: ', currentTableFilters)
              delete currentTableFilters.insertdate
              return {
                  ...currentTableFilters,
              }
            })

        }
    }
  }


  const filterElementConfig: any = useMemo(() =>  { 
    return {
        rapbackdate: {
          element: <RangePicker
              disabledDate={disabledDate}
              bordered={false}
              format="MM/DD/YYYY"
              allowEmpty={[true, true]}
              onChange={
                (value) => {
                  console.log('debug filter dateRangeFilter value in onChange: ', value)
                  handleFilterChange({...value},'rapbackdate')
                }
            }
          />,
          filterFunction: dateRangeFilter
        },
        insertdate: {
          element: <RangePicker
              disabledDate={disabledDate}
              bordered={false}
              format="MM/DD/YYYY"
              allowEmpty={[true, true]}
              onChange={
                (value) => {
                  console.log('debug filter dateRangeFilter value in onChange: ', value)
                  handleFilterChange({...value},'insertdate')
                }
            }
          />,
          filterFunction: dateRangeFilter
        },
        dob: {
          element: <RangePicker
              disabledDate={disabledDate}
              bordered={false}
              format="MM/DD/YYYY"
              allowEmpty={[true, true]}
              onChange={
                (value) => {
                  console.log('debug filter dateRangeFilter value for dob onChange: ', value)
                  handleFilterChange({...value},'dob')
                }
            }
          />,
          filterFunction: dateRangeFilter
        }
  }

  
}, [dateRangeFilter, setTableFilters])

  /*useEffect(() => {
    console.log('tableFilters useEffect: ', tableFilters)
    const revertTableData = () => setTableData(rapBacks);

    const filterbyMode = (mode: FilterMode) => {
      console.log('filter mode: ', mode)

      const filteredData = tableData.filter((rowData: any) => {
        console.log('rowData: ', rowData)
        return Object.entries(tableFilters)[mode === FilterMode.AND ? "every" : "some"](
          ([columnName, filterValue]) => {
            console.log(`columnName: ${columnName} filterValue: ${filterValue}`)
            console.log('filterElementConfig: ', filterElementConfig)
            const customFilterData: any = filterElementConfig[columnName]?.filterFunction?.(rowData[columnName]);
            console.log('customFilterData: ', customFilterData)
            const defaultFilter = formatFilterText(rowData[columnName]).toLowerCase().includes(formatFilterText(filterValue));
            console.log('defaultFilter: ', defaultFilter)
            return customFilterData || defaultFilter;
          }
        );
      });

      console.log('filteredData: ', filteredData)
  
      if (!filteredData.length && showToastAgainRef.current) { openNotification(); showToastAgainRef.current = false; }
      if (filteredData.length && !showToastAgainRef.current) { showToastAgainRef.current = true; }
  
      setTableData(filteredData)
    };
  

    Object.keys(tableFilters).length ? filterbyMode(filterMode) : revertTableData();
  }, [rapBacks, tableFiltersString, filterMode, tableFilters, filterElementConfig]);*/

  /* ===== FILTERS ===== */
  const handleFiltering = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    handleFilteringRef.current = handleFiltering;

    const columnName = e.target.id;
    const filterValue: string = e.target.value;
    console.log(`debug filters columnName: ${columnName} filterVal: ${filterValue}`);

    filterValue
      ? setTableFilters((currentTableFilters: TableFilters) => {
          console.log(
            "currentTableFilters in setTableFilters: ",
            currentTableFilters
          );
          const tableFilters = {
            ...currentTableFilters,
            [columnName]: filterValue,
          };
          console.log("debug filters tableFilters: ", tableFilters);
          return tableFilters;
        })
      : setTableFilters(
          ({
            [columnName]: removedColumnFilter,
            ...filtersWithoutRemovedColumnFilter
          }) => filtersWithoutRemovedColumnFilter
        );
  }, 1000);

  

  const camelToTitleCase = (str: string) => {
      const result = str.replace(/([A-Z])/g, " $1");
      return result[0].toUpperCase() + result.slice(1);
  };

  const smallerColumns = ["firstName", "lastName", "status", "agency", "dob", "isHit", "oris", "sid"]

  const EARLIEST_DATE = dayjs("1900-01-01T00:00:00").format('L');
  const TODAY = dayjs();
  let newCount
  const renderColumnFilter = useCallback( (dataIndex: string, title: string, overrides: Record<string, any> = {}, ref: any = {}) => {
      console.log('renderColumnFilter running for: ', dataIndex)
      console.log('ref: ', ref)
      console.log('ref viewRef: ', viewRef)
      
      return {
        ...{
          title,
          dataIndex,
          sorter: (a: any, b: any, sortOrder: any) => handleSorting(dataIndex, sortOrder),
          children: [
            {
              width: smallerColumns.includes(dataIndex) ? 150 : 190,
              dataIndex,
              title:
              dataIndex in filterElementConfig 
                ? filterElementConfig[dataIndex].element 
                : <Input
                    name={title}
                    id={dataIndex}
                    placeholder={`Filter ${title}`}
                    ref={ref}
                    onChange={handleFiltering}
                    onFocus={() => handleFilteringRef.current?.flush()}
                    bordered={false}
                  />
            }
          ]
        }, ...overrides
      }
  
    }, [filterElementConfig, handleFiltering]);

  const viewRapBack = useCallback( async (text: string) => {
    console.log('viewRapback text', text)
    const { username } = await Auth.currentAuthenticatedUser()
    console.log('rapBackSummary tableData: ', tableData)
    console.log('rapBackSummary rapBack: ', rapBacks)
    const details = tableData.find((obj: any) => obj.key === text).fileData
    const sidCurrent = tableData.find((obj: any) => obj.key === text).sid
    console.log('rapback found details: ', details)
    setDetails(details)
    setSid(sidCurrent)
    console.log('click event rapback viewRapBack text: ', text)
    addAuditEvent("RapBack Details viewed", `${username} viewed rapback details at ${new Date()}`, new Date(), undefined,)
    setOpenRapBackModal(true)
  }, [tableData]) 
  
  const handleOk = () => {
      setOpenRapBackModal(false)
  }

  const deleteRapBack = (rapback: any) => {

  }

  const createId = () => {
    setCount(c => c + 1);
    
    return ""
  }

  let prevCount = 0;

  const renderTableTop = () => (
    <div className="tableControls">
      {/*<div className="selectFilterMode">
        <span className="filterModeText">Filter Mode: </span>
        <Select
          defaultValue={FilterMode.AND}
          size="middle"
          style={{ width: 120 }}
          options={filterModeOptions}
          onChange={(newMode: FilterMode) => setFilterMode(newMode)}
        />
      </div>*/}
      <ColumnsVisibilityButton 
        setActiveColumns={setActiveColumns} 
        defaultColumns={activeColumns}
        dropDownOptions={dropDownOptions} />
      <Button  className="button" type="primary" size="large" onClick={() => clearFilters()}>Clear Filters</Button>
      {/*<ColumnsVisibilityButton setActiveColumns={setActiveColumns} />*/}
    </div>
  );

  const handleClickShowSSN = useCallback( async (record: any) => {
    if (displaySSN !== record.key) {
      setDisplaySSN(record.key)
    } else {
      setDisplaySSN(null)
    }
    }, [displaySSN])

  const columnsConfig: any = useMemo(() => {
    console.log('usersPErsmissions rapback ', usersPermissions)
      return [
          renderColumnFilter("key", "View", {
              children: [{ 
                dataIndex: "key", 
                title: () => { }, 
                width: 80,
                render: (text: string) => (
                  console.log('view text', text),
                    usersPermissions.viewRapBackDetails
                      ? <div style={{display: "flex", flexDirection: "column", justifyContent: "center", "alignItems": "center", }}>
                          <BsFillPersonLinesFill id={itemCount} style={{ fontSize: '28px', color: '#8566C1', cursor: 'pointer'}} onClick={() => viewRapBack(text)}/>
                        </div>
                      : <div style={{display: "flex", flexDirection: "column", justifyContent: "center", "alignItems": "center", }}>
                          <BsFillPersonLinesFill style={{ fontSize: '28px', color: 'grey', cursor: 'pointer'}}/>
                        </div>
                )
                  }], 
                sorter: null 
              },
              viewRef
              ),
          // renderColumnFilter("delete", "Delete", {
          //   children: [{
          //     dataIndex: "delete",
          //     title: () => {},
          //     width: 80,
          //     render: (_: any, rapback: any) => {
          //       return (
          //         <Popconfirm
          //           title="Sure to delete?"
          //           onConfirm={() => deleteRapBack(rapback)}
          //           cancelButtonProps={{ className: "button-outline" }}
          //           okButtonProps={{ className: "button" }}
          //           disabled={permissions.deleteExistingAgencies ? false : true}
          //         >
          //           <MdDeleteForever
          //             style={{
          //               color: editingKey ? "gray" : "#F55542",
          //               fontSize: "30px",
          //               cursor: "pointer",
          //             }}
          //           />
          //         </Popconfirm>
          //       );
          //     }
          // }]
          // }),
          renderColumnFilter("firstName", "First Name", {}, firstNameRef),
          renderColumnFilter("middleName", "Middle Name", {}, middleNameRef),
          renderColumnFilter("lastName", "Last Name", {}, lastNameRef),
          renderColumnFilter("oris", "ORI", {}, orisRef),
          renderColumnFilter("ssn", "SSN", {
            children: [{
              dataIndex: 'ssn',
              title: 
                <Input
                name={'ssn'}
                id={'ssn'}
                placeholder={`Filter ${'SSN'}`}
                onChange={handleFiltering}
                onFocus={() => handleFilteringRef.current?.flush()}
                bordered={false}
                />, 
              width: 150,    
              render: (text: any, record: any ) => {
                const isCellRevealed = displaySSN === record.key
                return (
                   !isCellRevealed ?  
                      <span style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        {'***-**-****'} {<EyeInvisibleOutlined id={itemCount} onClick={() => handleClickShowSSN(record)} style={{marginLeft: '1.4em'}}/>}
                      </span> 
                   : <span style={{display: 'flex', justifyContent: 'space-evenly'}}>
                      {text} {<EyeOutlined id={itemCount} onClick={() => handleClickShowSSN(record)}/>}
                     </span> 
                )
              }
            }],
          }, ssnRef),
          renderColumnFilter("sid", "SID", {}, sidRef),
          renderColumnFilter("agency", "Agency", {}, agencyRef),
          renderColumnFilter("dob", "DOB", {
            defaultSortOrder: "descend" as SortOrder,
            sorter: (a: any, b: any) => {
              const aDate = new Date(a.dob);
              const bDate = new Date(b.dob);
              return aDate.getTime() - bDate.getTime();
            }
          }, dobRef),
          renderColumnFilter("reason", "Fingerprint Reason", {}, reasonRef),
          renderColumnFilter("isHit", "Is Hit", {}, isHitRef),
          renderColumnFilter("rapbackdate", "Rapback Date", {
            defaultSortOrder: "descend" as SortOrder,
            sorter: (a: any, b: any) => {
              const aDate = new Date(a.rapbackdate);
              const bDate = new Date(b.rapbackdate);
              return aDate.getTime() - bDate.getTime();
            }
          }, rapBackDateRef),
          renderColumnFilter("insertdate", "Date Recieved", {
            defaultSortOrder: "descend" as SortOrder,
            sorter: (a: any, b: any) => {
              const aDate = new Date(a.insertdate);
              const bDate = new Date(b.insertdate);
              return aDate.getTime() - bDate.getTime();
            }
          }, insertdateRef),
          /*renderColumnFilter("removalRapBack", "Removal Rap Back")*/
      ]
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFiltering, itemCount,activeColumns, tableData, displaySSN, firstNameRef, middleNameRef, lastNameRef, orisRef, ssnRef, sidRef, dobRef, reasonRef, isHitRef, rapBackDateRef,insertdateRef, viewRef, usersPermissions])

  const createMarkup = (details: any) => {
    console.log('rapBackSummary createMarkup rapBacks: ', rapBacks)
    if(rapBacks)
    return {__html: `${process.env.REACT_APP_STAGE === "dev" ? mockRapXML : details || rapBacks.fileData}`};
  }

  const clearFilters = () => {
    console.log('refs: ', refs)
    Object.keys(refs).forEach((ref: any) => {
      console.log('ref: ', ref)
      if(refs[ref].current) {
        refs[ref].current.input.value = ""
      }
    })
  
    setTableFilters({})
    
  }

  const handlePageChange = (currentPage: number, pageSize: number) => {
    setPaginationState({ currentPage, pageSize });
  };

  const changeSortOrder = (columnName: string, chosenSortOrder: 'asc' | 'desc') => {
    console.log(`changeSortOrder before changing newSortOrder to ${chosenSortOrder}`);

    let newSortedColumn = columnName;

    console.log(`changeSortOrder after changing newSortOrder to ${chosenSortOrder}`);
    
    setCurrentSortedColumn(newSortedColumn);
    setSortOrder(chosenSortOrder);
  }

  const printRapbackDetails = () => {
    printText(details ?? "", "", "", {
      printBackground: true,
    })
    addAuditEvent("RapBack Details printed", "Print data for SID: " +  sidCurrent, new Date(), undefined, undefined)
  };

  const handleSorting = (columnName: any, sortOrder: any) => {
    if(sortOrder === 'ascend') {
      sortOrder = 'asc'
    }
    else if (sortOrder === 'descend') {
      sortOrder = 'desc'
    }
    console.log(`changeSortOrder columnName: ${columnName} sortOrder: ${sortOrder}`)
    console.log(`changeSortOrder currentSortedColumn: ${currentSortedColumn}`)
    console.log(`changeSortOrder check: ${currentSortedColumn === columnName}`)

    changeSortOrder(columnName, sortOrder)
  }

  console.log('rapBackSummary tableData before return: ', tableData)

  return (
    <>
      {
        allowed === true || allowed === undefined 
          ? <div className='rapback-summary'>
              <Modal
                  title="RapBack Details"
                  closable={false}
                  open={openRapBackModal}
                  onOk={handleOk}
                  okText={<>Close</>}
                  cancelText={<>Print</>}
                  cancelButtonProps={{className: "ant-btn-primary button", icon: usersPermissions.viewRapBackDetails && <PrinterOutlined style={{ fontSize: 15 }} />}}
                  onCancel={printRapbackDetails}
                  width={"50vw"}
                  centered
                  //cancelButtonProps={{ style: { display: 'none' } }}
                  okButtonProps={{ className: "button" }}
                  bodyStyle={{height: "800px"}}
              
              >
                  <div style={{display: "flex", flexDirection: "column", justifyContent: "center", height: "100%"}}>
                      <div dangerouslySetInnerHTML={createMarkup(details)} style={{height: "100%", overflowY: "auto"}}/>
                  </div>
                  
              </Modal>
              <Table 
                columns={columnsConfig.filter((column: any) =>
                  activeColumns.includes(column.title)
                )} 
                dataSource={tableData}
                //footer={() => <Button onClick={() => clearFilters()}>Clear</Button>}
                title={renderTableTop}
                loading={loading}
                scroll={{ y: scrollBreakPoint }}
                pagination={{
                  position: ["bottomLeft"],
                  current: currentPage,
                  pageSize,
                  defaultPageSize: 25,
                  total, 
                  pageSizeOptions: ["10", "25", "50", "100"],
                  locale: { items_per_page: '' },
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  onChange: (currentPage, pageSize) => {
                    handlePageChange(currentPage, pageSize);
                  },
                  showSizeChanger: true,
                }}/>
            </div>
          : <Navigate to="/" />
      }
    </>
      
  )
}


