/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

export enum UserPermission {
  viewAgencies = "viewAgencies",
  addNewAgencies = "addNewAgencies",
  editExistingAgencies = "editExistingAgencies",
  deleteExistingAgencies = "deleteExistingAgencies",
  viewAllDetailsData = "viewAllDetailsData",
  allowManualAdjudication = "allowManualAdjudication",
  createNewNotes = "createNewNotes",
  editOwnNotes = "editOwnNotes",
  printNotes = "printNotes",
  viewOthersNotes = "viewOthersNotes",
  editOthersNotes = "editOthersNotes",
  viewOriginalResults = "viewOriginalResults",
  printOriginalResults = "printOriginalResults",
  viewRedactedResults = "viewRedactedResults",
  printRedactedResults = "printRedactedResults",
  editRedactedResults = "editRedactedResults",
  createNewRedactedResults = "createNewRedactedResults",
  viewTransactionSummary = "viewTransactionSummary",
  sortTransactionSummary = "sortTransactionSummary",
  saveTransactionSummarySearches = "saveTransactionSummarySearches",
  viewRapBack = "viewRapBack",
  viewRapBackDetails = "viewRapBackDetails",
  viewAllTransactionsNoLimit = "viewAllTransactionsNoLimit",
  canResubmitTransaction= "canResubmitTransaction",
  extendTransactionRecord = 'extendTransactionRecord'
}

const adminConfig = {
  agencyManagement: [
    UserPermission.viewAgencies,
    UserPermission.addNewAgencies,
    UserPermission.editExistingAgencies,
    UserPermission.deleteExistingAgencies,
  ],
  detailsTab: [UserPermission.viewAllDetailsData],
  manualAdjudication: [UserPermission.allowManualAdjudication],
  notesTab: [
    UserPermission.createNewNotes,
    UserPermission.editOwnNotes,
    UserPermission.printNotes,
    UserPermission.viewOthersNotes,
    UserPermission.editOthersNotes,
  ],
  resultsTabOriginal: [
    UserPermission.viewOriginalResults,
    UserPermission.printOriginalResults,
  ],
  resultsTabRedaction: [
    UserPermission.viewRedactedResults,
    UserPermission.printRedactedResults,
    UserPermission.editRedactedResults,
    UserPermission.createNewRedactedResults,
  ],
  transactionSummary: [
    UserPermission.viewTransactionSummary,
    UserPermission.sortTransactionSummary,
    UserPermission.saveTransactionSummarySearches,
    UserPermission.canResubmitTransaction,
    UserPermission.extendTransactionRecord,
    UserPermission.viewAllTransactionsNoLimit
  ],
  rapBackSummary: [
    UserPermission.viewRapBack,
    UserPermission.viewRapBackDetails
  ]
};

export const test = {
  viewAgencies: true,
  addNewAgencies: true,
  editExistingAgencies: false,
  deleteExistingAgencies: true,
  viewAllData: true,
  allowManualAdjudication: false,
  createNew: true,
  editOwn: true,
  print: false,
  viewOthers: true,
  editOthers: true,
  viewOriginal: true,
  printOriginal: false,
  viewRedacted: true,
  printRedacted: true,
  editRedacted: true,
  createNewRedacted: true,
  view: true,
  searchAndSort: true,
  saveSearches: true,
  sortTransactionSummary: true,
  saveTransactionSummarySearches: true,
  canResubmitTransaction:true
};

const allPermissions = Object.values(adminConfig).flat();

export const formatPermissions = (values: any) => {
  console.log("permissions formatPermissions: ", values);
  const permissionsArray = Object.values(values).flat();
  console.log("permissions permissionsArray: ", permissionsArray);
  const formattedPermissions = {} as any;
  allPermissions.forEach((permission) => {
    console.log(
      "permissions formatPermissions forEach permission: ",
      permission
    );
    formattedPermissions[permission] = permissionsArray.includes(permission);
  });
  console.log("permissions formattedPermissions: ", formattedPermissions);
  return formattedPermissions;
};

export const formatPermissionsAfterFetch = (permissions: any) => {
  console.log("debugging formatpermissions permissions", permissions);
  const currentPermissions = permissions[0].data.map(({ id }: any) => id);
  console.log("debugging currentPermissions", currentPermissions);
  const formattedPermissions = {} as any;
  allPermissions.forEach((permission) => {
    formattedPermissions[permission] = currentPermissions.includes(permission);
    console.log(
      "debugging allpermissions",
      (formattedPermissions[permission] =
        currentPermissions.includes(permission))
    );
  });
  return formattedPermissions;
};

const invertPermissions = (permissions: { [key: string]: string[] }) =>
  Object.entries(permissions).reduce((acc, [key, values]) => {
    values.forEach((value) => {
      acc[value] = key;
    });
    return acc;
  }, {} as { [key: string]: string });

export function formatPermissionsForForm(obj: Record<string, boolean>) {
  if (!obj) return {};
  const keyMappings = invertPermissions(adminConfig);

  return Object.entries(obj).reduce(
    (newObj, [key, value]) => {
      const newKey = keyMappings[key];
      //@ts-ignore
      if (value && newKey) newObj[newKey].push(key);
      return newObj;
    },
    Object.keys(adminConfig).reduce(
      (acc, key) => ({ ...acc, [key]: [] }),
      {} as Record<string, string[]>
    )
  );
}
