import { Auth } from 'aws-amplify';
import { useCookies } from "react-cookie";


export const updateToken = async () => {

    let token;
    try {
        let currentSession: any = await Auth.currentSession()
        token = currentSession.idToken.jwtToken
    } catch (e: any) {
        console.error(`Error updating token: ${e}`)
    }

    console.log('token: ', token)

    return token 
};