/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useState } from "react";

export function useLocalStorage<T>(key?: string, initialValue?: T) {
    const [loggedIn, setLoggedIn] = useState<any>()
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
      if (typeof window === "undefined" && key) {
        return initialValue;
      }
      if(key) {
        try {
          // Get from local storage by key
          const item =  window.localStorage.getItem(key);
          // Parse stored json or if none return initialValue
          return item ? JSON.parse(item) : initialValue;
        } catch (error) {
          // If error also return initialValue
          console.log(error);
          return initialValue;
        }
      }
      
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== "undefined" && key) {
             window.localStorage.setItem(key, JSON.stringify(valueToStore));  
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    };
    const value = () => {
      let loggedInStatus = localStorage.getItem("loggedIn")
      console.log('httpOnly getloggedIn: ', loggedInStatus)
      if(loggedInStatus) {
        setLoggedIn(loggedInStatus)
      }
    }

    useEffect(() => {
      console.log('httpOnly loggedIn updated: ', loggedIn)

      let getValue: any;

      if(!loggedIn) {

        getValue = setInterval(() => {

          /*console.log(`httpOnly loggedIn === true: ${loggedIn === true}
                       loggedIn == true: ${loggedIn == true}
                       ${loggedIn} loggedIn: ${typeof loggedIn}`)*/
   
            value()
          
        }, 1000)

      } 
      
      return () => {
        clearInterval(getValue)
      }
      
    }, [loggedIn])

    

    return [storedValue, setValue, loggedIn] as const;
  }