/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { ActivityResultType, Audit } from '@storm/ts-audit-service';
import { useState, useEffect } from 'react'
import { currentConfig } from '../amplifyConfig'

import { v4 as uuid } from 'uuid'
import { urls } from '../urls'
import { Auth } from 'aws-amplify';
import { useCookies } from "react-cookie";
import { getDomainName } from "../utils/getDomainName";

interface AuditEvent {
    audit?: Audit
    key?: string
}

interface AuditHook {
    auditList: Audit[],
    addAuditEvent: (activityAction: string, details: any, activityStartTime?: Date, key?: string, audit?:Partial<Audit>) => Promise<Audit>,
    undoLastAudit: () => void,
    clearAudits: () => void,
    auditMap: AuditEvent | undefined
}

const APP_NAME = 'CARES Application';


console.log('currentConfig: ', currentConfig)



//if (!process.env.QUEUE_NAME) throw new Error("A required env var: QUEUE_NAME is undefined...");



export const useAudit = (): AuditHook => {
    
    const [auditList, setAuditList] = useState<Audit[]>([])
    const [auditMap, setAuditMap] = useState<AuditEvent>()
    const [cookies, , removeCookie] = useCookies(["token"]);

    const url = urls.AUDIT
    console.log('useAudit url: ', url)

    const [auditResponse, setAuditResponse] = useState(false)
    const [activityAction, setActivityAction] = useState("")
     
    useEffect(() => {
        
        if(auditList.length > 0) {
            const sendAuditEventToSqs = async (): Promise<string | undefined> => {
                return new Promise( async (resolve, reject) => {
                    let messageId;
                    const controller = new AbortController()
                    const { signal } = controller
                    console.log('debug audit sendAuditEventToSqs auditList: ', auditList)
                    let res: any;
                    try {
                        const lastIndex = auditList.length - 1
                        const audit = auditList[lastIndex]    
                        const auditBody = {
                            audit,
                            currentConfig
                        }
            
                        console.log('debug audit sendAuditEventToSqs auditBody: ', auditBody)
            
                        res = await fetch(`${process.env.NODE_ENV === 'production' ? url : ""}/audit`, {
                            method: 'POST',
                            credentials: 'include',
                            signal,
                            body: JSON.stringify(auditBody)
                        })
                        
                        if(res.ok) {
                            resolve("Audit Succes")
                        }
            
                    } catch (e: any) {
                        console.log('Error posting audit: ', e)
                        reject(e)
                    }
                
                })
                
            }
    
            sendAuditEventToSqs()/*.then((res: any) => {
                console.log('sendAutidEventToSqs res: ', res)
                setAuditResponse(true)
            })*/
        }

    }, [auditList.length, auditList, url])


    useEffect(() => {
        if(auditResponse) {
            if(activityAction === "Session timeout") {
                console.log('removing cookie')
                removeCookie('token', { path: '/', domain: getDomainName() })
                //window.location.reload()
            }
        }
    }, [auditResponse])

    


    const addAuditEventToList = (auditEvent: AuditEvent) => {
        return new Promise( async (resolve, reject) => {
            console.log('debug audit add audit event to list auditEvent: ', auditEvent)
            if(auditEvent.audit) setAuditList([...auditList, auditEvent?.audit!])
            resolve("Audit List Updated")
        })
        
    }

    const addAuditEvent = async (activityAction: string, details: any, activityStartTime: Date = new Date(), key:string = uuid(), auditObject?:Partial<Audit>): Promise<Audit> => {
        return new Promise( async (resolve, reject) => {
            const activityDetails = details;
            const activityApplication = APP_NAME;
            const activityEndTime = new Date();
            const activityResult = ActivityResultType.SUCCESS;
            const { username } = await Auth.currentAuthenticatedUser()
            console.log('key: ', key)
            const audit: Audit = {
                activityAction,
                activityDetails,
                operatorName: username,
                activityStartTime,
                activityEndTime,
                activityApplication,
                activityResult,
                dataInternalId: uuid()
            };

            console.log('audit: ', audit)

            const updatedAudit:Audit = { ...audit, ...auditObject };
            await addAuditEventToList({audit: updatedAudit, key});
            console.log('activityAction === "Session timeout": ', activityAction === "Session timeout")
            //setActivityAction(activityAction)
           
            resolve(updatedAudit)
        })
        
    }

    const undoLastAudit = () => {
        const auditListCopy = auditList
        auditListCopy.slice(0, -1)
        setAuditList(auditListCopy)
    }

    const clearAudits = () => {
        setAuditMap({})
        setAuditList([])
    }


    return {
        auditList, addAuditEvent, undoLastAudit, clearAudits, auditMap
    }
}