/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { NotesList } from "./NotesList/NotesList";
import { NoteEditor } from "./NoteEditor/NoteEditor";
import { useCallback, useEffect, useRef, useState } from "react";
import "./Notes.scss";
import { Button, InputRef, Result, Spin } from "antd";
import { PlusCircleFilled, PlusOutlined } from "@ant-design/icons";
import useNotes from "components/ApplicantDetailsBody/Results/CustomEditor/useNotes";
import { EditView } from "components/EditView/EditView";

interface NoNotesProps {
  createTemporaryNote: () => void;
}

const NoNotes: React.FC<NoNotesProps> = ({ createTemporaryNote }) => {
  return (
    <div className="no-notes">
      <Result
        icon={
          <EditView>
            <PlusCircleFilled className="plus-circle" onClick={createTemporaryNote} />
          </EditView>
        }
        title="This registration doesn't have any notes yet!"
        extra={
          <Button type="primary" onClick={createTemporaryNote}>
              CREATE A NOTE
              <PlusOutlined className="plus-outlined" />
            </Button>
        }
      />
    </div>
  );
};

interface NotesProps {
  personalInfo: any;
  userRole: string
}

export const Notes: React.FC<NotesProps> = ({ userRole, personalInfo }) => {
  const { notesJSON, fetchStatus } = useNotes("note");
  const inputRef = useRef<InputRef>(null);

  const [notes, setNotes] = useState(notesJSON);
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);
  const [hasBlankNote, setHasBlankNote] = useState(false);

  const focusTextArea = () => {
    setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };
  
  const handleSelectedNoteChange = useCallback((id: string | null)=> {
    setSelectedNoteId(id);
    focusTextArea();
  }, []) 

  useEffect(() => {
    const sortedNotes = notesJSON.sort((a: any, b: any) => {
      const dateA = new Date(a.datelastmodified || a.datecreated);
      const dateB = new Date(b.datelastmodified || b.datecreated);
      return dateB.getTime() - dateA.getTime();
    });
    setNotes(sortedNotes);
    handleSelectedNoteChange(sortedNotes[0]?.id || null);
  }, [fetchStatus, handleSelectedNoteChange, notesJSON]);


  const createTemporaryNote = () => {
    setHasBlankNote(true);

    const temporaryId = `${Date.now()}`;
    handleSelectedNoteChange(temporaryId);

    const newNote = {
      temporaryId,
      body: {
        note: "",
      },
      datelastmodified: Date.now(),
    };
    setNotes([newNote, ...notes]);
  };

  // const errorFetching = fetchStatus === 'error';
  const isLoading = fetchStatus === "loading" || fetchStatus === "idle";

  const hasNotes = notes.length;

  if (isLoading) {
    return (
      <div className="notes-container">
        <div className="loading-container">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className="notes-container">
      {hasNotes ? (
        <>
          <NotesList
            userRole={userRole}
            personalInfo={personalInfo}
            notesState={{ notes, setNotes }}
            selectedNoteIdState={{ selectedNoteId, handleSelectedNoteChange }}
            blankNoteState={{hasBlankNote, setHasBlankNote}}
            createTempNote={createTemporaryNote}
          />
          <NoteEditor
            userRole={userRole}
            noteState={{ notes, setNotes }}
            selectedNoteId={selectedNoteId}
            setHasBlankNote={setHasBlankNote}
            ref={inputRef}
          />
        </>
      ) : (
        <NoNotes createTemporaryNote={createTemporaryNote} />
      )}
    </div>
  );
};
