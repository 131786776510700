/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

export const genderList = [
    { 
        value: 'M', 
        label: "Male" 
    },
    { 
        value: 'F', 
        label: "Female" 
    },
    { 
        value: 'Other', 
        label: "Other" 
    }
];

export const suffixList = [
    {
        value: "",
        label: "N/A"
    },
    { 
        value: 'Mr', 
        label: "Mr" 
    },
    { 
        value: 'Mrs', 
        label: "Mrs" 
    },
    { 
        value: 'Ms', 
        label: "Ms" 
    }
];

export const statusHistoryColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Status Date',
      dataIndex: 'statusDate',
      key: 'statusDate',
    }
];