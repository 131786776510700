/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { countries, eyeColors, hairColors, placesOfBirth, races } from "constants/personAttributes";
import { genderList } from 'components/ApplicantDetailsBody/ApplicantDetailsForm/ApplicantDetailsFormData';

export const getHairColor = (hairColor: string) => {
    if(hairColor) {
        let personHairColor = hairColors.blk;
        hairColor = hairColor.toLowerCase();
    
        Object.entries(hairColors).forEach(([key, value]) => {
            if(hairColor === key) {
                personHairColor = value;
            }
        });
    
        return personHairColor;
    }
}


export const getEyeColor = (eyeColor: string) => {
    if(eyeColor) {
        let personEyeColor = hairColors.blk;
        eyeColor = eyeColor.toLowerCase();
    
        Object.entries(eyeColors).forEach(([key, value]) => {
            if(eyeColor === key) {
                personEyeColor = value
            }
        });
    
        return personEyeColor;
    }
} 

export const getRace = (race: string) => {
    let personRace = races.W;

    Object.entries(races).forEach(([key, value]) => {
        if(race === key) {
            personRace = value;
        }
    });

    return personRace;
}

export const getPOB = (pob: string) => {
    let personPOB = placesOfBirth.NA;

    Object.entries(placesOfBirth).forEach(([key, value]) => {
        if(pob === key) {
            personPOB = value;
        }
    });

    return personPOB;
}

export const getGender = (gender: string) => genderList.find(({ value }) => value === gender)?.label ?? '';

export const getCountry = (country: string) => {
    let personCountry = countries.US;

    Object.entries(personCountry).forEach(([key, value]) => {
        if(country === key) {
            personCountry = value;
        }
    });

    return personCountry;
}