
  export const stateSpecficDefaultRapbackColumns = ( program:string ) => {
    switch(program){
      case 'caresnmdpsprod':
      case 'nmpreprod':
         return nmDefaultColumns;
      default:
        return ALL_COLUMNS;
    }
  }

  export const nmDefaultColumns = [
    "View",
    "First Name",
    "Middle Name",
    "Last Name",
    "ORI",
    "SSN",
    "SID",
    "Agency",
    "Fingerprint Reason",
    "Is Hit",
    "Rapback Date",
    "Date Recieved"
    ];
  
  
  export const ALL_COLUMNS = [    
  "View",
  "First Name",
  "Middle Name",
  "Last Name",
  "ORI",
  "SSN",
  "SID",
  "Agency",
  "Fingerprint Reason",
  "Is Hit",
  "Rapback Date",
  "Date Recieved"
  ];
  
