/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect, useRef } from 'react'
import { useConfig } from './useConfig';
import { useUserRole } from './useUserRole';
import { useUserCognito } from './useUserCognito';
import { urls } from '../urls'
import { useCookies } from "react-cookie";


const transform = (data: any, i: number) => {
    console.log('agencyManagement transform data: ', data)
    return {
            "json_data": 
            {
                id: data.ori,
                ori: data.ori,
                reasonFingerPrinted: data.reasonFingerPrinted,
                agency_name: data.agency_name,
                distribution_method: data.distribution_method,
                agency_email: [data.agency_email],
                agency_address: data.agency_address,
                agency_city: data.agency_city,
                agency_county: data.agency_county,
                agency_state: data.agency_state,
                agency_zipcode: data.agency_zipcode
        }
    }
}


export const useQueryAgencyManagement = (
    pageSize: number,
    currentPage: number,
    order: string,
    columnName: string,
    tableFiltersData: any) => {

    const [mappedData, setMappedData] = useState<any>()
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false)
    const [countError, setError] = useState();
    const { userORIs } = useUserCognito();
    const {userRole} = useUserRole()
    const { config } = useConfig()
    const [, setCookie, removeCookie] = useCookies();

    let today = new Date()
    let todayTime = today.getTime()


    const url = urls.QUERY

    console.log('agencyManagementService useAgencyManagement node env: ', process.env.NODE_ENV)
    console.log('agencyManagementService useAgencyManagement url: ', url)

    useEffect(() => {
        const controller = new AbortController()
        const { signal } = controller


        const allData: any = []

        if(userORIs.length > 0) {
            const adminRoles = ["mc_admin"];
            const isAdmin = adminRoles.includes(userRole);
            const queryParams = [
                {
                    "value": columnName ? columnName : "default" , 
                    "name": "orderBy"
                },
                {
                    "value": order === ("desc" || "asc")? order : "default",
                    "name": "order"
                }
            ]

            let agencyManagementBodyRequest = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryAgencyTable"
                    }
                ]
            }

            let agencyManagementCountRequest = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": 0,
                        "queryName": "queryAgencyTableCount"
                    }
                ]
            }

            const fetchAgencyManagementData = async (agencyManagementBodyRequest: any, agencyManagementCountRequest: any) => {


                try {
                    const agencyManagementUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;

                    let i: number = 1;
                    setLoading(true)
    
                    const data = await (await fetch(agencyManagementUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(agencyManagementBodyRequest)})).json();

                    const countData = await (await fetch(agencyManagementUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(agencyManagementCountRequest)})).json();
                    

                    const {results} = data['queriesResults'][0];
                    const count = countData['queriesResults'][0].results[0]['count'];
                    
                    console.log("Agency Count: " + count);
                    const transformedData = results.map((obj: any, index: number) => transform(obj, index));
                    console.log('transformedData agency: ', transformedData)
                    setMappedData(transformedData);
                    setTotalRecords(count);
                    setLoading(false);
    
                } catch (err: any) {
                    setError(err);
                    setLoading(false);
                }
            }

    
            setLoading(true);
            fetchAgencyManagementData(agencyManagementBodyRequest, agencyManagementCountRequest)
        }


        return () => {
            controller.abort();
        }
    }, [url, order, currentPage, pageSize, userRole, userORIs, tableFiltersData])

    return { data: mappedData, totalRecords, countError, loading }
}