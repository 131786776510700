/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React from "react";
import {
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
  MenuOutlined,
  LogoutOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Layout, Button, Dropdown } from "antd";
import "./Navbar.scss";
import { useTheme } from "components/Theme";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import NavLinks from "./components/NavLinks";
import { useAuth } from "../../hooks/useAuth";

const { Header } = Layout;

export const Navbar: React.FC = () => {
  //const [,, removeCookie] = useCookies(['token']);
  //const navigate = useNavigate();

  const { theme, toggleTheme } = useTheme();
  const isDark = theme === "theme-dark";
  const { logout } = useAuth();

  const handleLogout = () => {
    if (logout) logout();
    /*console.log('delete token')
    removeCookie('token', { path: '/', domain: 'athena.us.idemia.io' })
    navigate('/')*/
  };

  const items = [
    {
      key: "1",
      label: (
        <a target="_blank" rel="noopener noreferrer" href="../../help.pdf">
          <Button className="log-out">Help</Button>
        </a>
      ),
      icon: <QuestionCircleOutlined />,
    },
    {
      key: "2",
      label: (
        <Button onClick={toggleTheme} className="theme-toggle">
          {isDark ? "Dark Theme " : "Light Theme"}
        </Button>
      ),
      icon: isDark ? (
        <IoMdMoon style={{ fontSize: 16 }} />
      ) : (
        <IoMdSunny style={{ fontSize: 16 }} />
      ),
    },
    {
      key: "3",
      label: (
        <Button onClick={handleLogout} className="log-out">
          {"Logout"}
        </Button>
      ),
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Header className="header">
      <div className="logo-nav-container">
        <div className="logo">
          <LeftOutlined style={{ color: "white" }} />
          <span className="logoText">CARES</span>
          <RightOutlined style={{ color: "white" }} />
        </div>

        <NavLinks />
      </div>

      <Dropdown menu={{ items }}>
        <MenuOutlined />
      </Dropdown>

      {/*<div style={{display: 'flex'}}>
        <Button onClick={toggleTheme} className="theme-toggle">{isDark ? <IoMdMoon style={{fontSize: 16}}/> : <IoMdSunny style={{fontSize: 16}}/>}</Button>
        <Button onClick={handleLogout} className="log-out">{'Logout'}<ArrowRightOutlined /></Button>
      </div>*/}
    </Header>
  );
};
