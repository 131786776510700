/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import TransactionSummarySearch from "./TransactionSummarySearch/TransactionSummarySearch";
import TransactionSummaryTable from "./TransactionSummaryTable/TransactionSummaryTable";
import "./TransactionSummary.scss";
import SavedSearches, { SavedSearchItem } from "./SavedSearches/SavedSearches";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Form, Alert } from "antd";
import { parseFilterForSearch } from "components/DateRangePicker/DateRangePicker";
import { TableFilters } from "./TransactionSummaryTable/TransactionSummaryTableData";
import { useUserCognito } from "hooks";
import { useAudit } from "hooks/useAudit";
import { useConfigService } from "../../hooks/useConfigService";
import { GetMaintenanceSchedule } from "utils/getMaintenanceSchedule";

enum SavedSearchActionTypes {
  DELETE_SEARCH='DELETE_SEARCH',
  LOAD_SEARCHES='LOAD_SEARCHES',
  ADD_SAVED_SEARCH='ADD_SAVED_SEARCH'
}

const SearchesContainer: React.FC<any> = ({ setTableFilters }) => {
  const [currentSavedSearches, setCurrentSavedSearches] = useState({})
  const savedSearchesReducer = (currentState: any, action: any) => {
    switch (action.type) {
      case SavedSearchActionTypes.DELETE_SEARCH:
        console.log(`debug saved search: DELETING SAVED SEARCH ${action.searchName} currrentState before delete`, currentState)
        delete currentState[action.searchName];
        setCurrentSavedSearches(currentState)
        setIsPost(true)
        return {
          ...currentState
        };
      case SavedSearchActionTypes.ADD_SAVED_SEARCH:
        console.log(`debug saved search: ADDING SAVED SEARCH ${JSON.stringify(action.savedSearch, null, 2)}`)
        let keys = Object.keys(action.savedSearch)
        let key =  keys[0]
        currentState[key] = action.savedSearch[key]
        setCurrentSavedSearches(currentState)
        setIsPost(true)
        return {
          ...currentState
        }
      case SavedSearchActionTypes.LOAD_SEARCHES:
        return {
          ...action.data,
        };
      default: throw new Error('Invalid action type');
    }
  };

  const { addAuditEvent } = useAudit();
  console.log('search setTableFilters: ', setTableFilters)
  const [form] = Form.useForm();
  const [isPost, setIsPost] = useState(false);
  const { data } = useConfigService('sticky-settings.json', currentSavedSearches, true, isPost);
  const loadedData = useMemo(() => ({...data}), [data]);
  const [savedSearches, dispatch] = useReducer(savedSearchesReducer, loadedData);

  useEffect(() => {
    if (data) {
      dispatch({type: SavedSearchActionTypes.LOAD_SEARCHES, data})
    }
  }, [data]);
  
  const onSavedSearch = (tableFilter: any) => {
            setTableFilters(tableFilter);
            form.resetFields();
            form.setFieldsValue(parseFilterForSearch(tableFilter));
  };

  const renderSavedSearches = Object.entries(savedSearches).map(
    ([searchName, tableFilter]) => {
      return (
        <SavedSearchItem
          key={searchName}
          searchName={searchName}
          onSearch={()=>onSavedSearch(tableFilter)}
          onDelete={ () => dispatch({type: SavedSearchActionTypes.DELETE_SEARCH, searchName}) }
        />
      );
    }
  );

  return (
    <div className="transaction-summary-search-container">
      <TransactionSummarySearch
        setTableFilters={setTableFilters}
        savedSearchState={[savedSearches, dispatch]}
        formInstance={form}
      >
        <SavedSearches>{renderSavedSearches}</SavedSearches>
      </TransactionSummarySearch>
    </div>
  );
};

const SearchesContainerMemo = React.memo(SearchesContainer);

export const TransactionSummary = () => {
  const [tableFilters, setTableFilters] = useState<TableFilters>({});
  const { userORIs, userRole, usersPermissions, userSecondaryORIs } = useUserCognito();
  const {maintenanceActive, startDate, endDate, currentDate } = GetMaintenanceSchedule();

  return (
    <>
    { currentDate < startDate && 
      <Alert 
        style={{margin: '1rem'}}
        type="warning" 
        showIcon 
        description={`Please be aware that CARES will be offline starting at ${startDate} for scheduled maintenance and will be back online after ${endDate}.`}
        />
    }
    <div className="search-container">
      <div className={`transaction-summary-container`}>
        <SearchesContainerMemo setTableFilters={setTableFilters} />
        <div className="table-background">
          <TransactionSummaryTable 
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            userORIs={userORIs}
            userRole={userRole}
            userSecondaryORIs={userSecondaryORIs}
            usersPermissions={usersPermissions} />
        </div>
      </div>
    </div>
    </>
  );
};