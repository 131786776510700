/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Popover, Table } from "antd";
import { EditView } from "components/EditView/EditView";
import usePdfURL from "hooks/usePdfURL";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAudit } from "../../../../hooks/useAudit";

const formatResults = (results: any, status: string, addedDate: string) => {
  console.log('formatResults results: ', results)
  console.log('formatResults status: ', status)
  console.log('formatResults addedDate: ', addedDate)
  return results?.map((result: any) => {
      return {
          key: result,
          decision: status ?? "N/A",
          addedBy: result.addedBy ?? "N/A",
          addedDate: addedDate ?? "N/A",
          fileNameControl: 'Adjudication.pdf',
          fileLink: result,
          deleteControl: 'delete'
      }
  });
};


const AdjudicationDocuments: React.FC<any> = ({addedDate, status, tcn }) => {
  const { registrationId } = useParams();
  const {pdfURLs} = usePdfURL(registrationId as string);
  const data = formatResults(pdfURLs, status, addedDate);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { addAuditEvent } = useAudit();
  
  const ROWSIZE = 39;
  const NUMBER_OF_ROWS_VISIBLE = 5;

  const auditDownload = () => {
    addAuditEvent("Download Adjudication", "Download letter for: " + tcn + " Document location: " + pdfURLs, new Date(), undefined, undefined)
  };

  const columns = [
    {
      title: 'Added Date',
      dataIndex: 'addedDate',
      sorter: (a: any, b: any) => {
        const aDate = new Date(a.addedDate);
        const bDate = new Date(b.addedDate);
        return aDate.getTime() - bDate.getTime();
      },
      render: (date: any) => <span>{date && dayjs(date).format('MM/DD/YYYY')}</span>
    },
    {
      title: 'Decision',
      dataIndex: 'decision'
    },
    {
      title: 'File Name',
      dataIndex: 'fileNameControl',
      render: (text: any, record: any) => (
        <a href={record.fileLink} 
        target="_blank" 
        rel="noreferrer"
        onClick={auditDownload}
        style={{textDecoration: 'underline'}}
        >{text}</a>
      )
    },
    {
      title: ' ',
      dataIndex: 'deleteControl',
      width: '60px',
      render: () => (  
        <EditView>
          <Popover content="Delete Document" >
            <span onClick={() => setConfirmationModalOpen(true)}><DeleteOutlined style={{fontSize: 20}}/></span>
          </Popover>
        </EditView>
      )
    }
  ];
    
    return (
      <div>
          <Modal
            title="Are you sure?"
            okText={'Delete'}
            centered
            open={confirmationModalOpen}
            onCancel={() => setConfirmationModalOpen(false)}
            maskClosable
            closable={false}
            okButtonProps={{className: "button"}}
            cancelButtonProps={{className: "button-outline"}}
            children="Are you sure you would like to delete this document? Select Delete to continue or Cancel to disregard." />
      <div className='table-background--alt'>
            <Table 
                columns={columns}
                dataSource={data}
                pagination={false}
                size="small"
                scroll={{y: `${ROWSIZE * NUMBER_OF_ROWS_VISIBLE}px`}}
            />
            </div>
        </div>
    );
};

export default AdjudicationDocuments;