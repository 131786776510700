/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect} from "react";
import dayjs from "dayjs";
import { urls } from "../urls";
import { useConfig } from "./useConfig";
import { TableFilters } from "pages/TransactionSummary/TransactionSummaryTable/TransactionSummaryTableData";
import { updateToken } from "./shared/helpers"
import { useCookies } from "react-cookie";
import { useAudit } from "hooks/useAudit";

 
let localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const convertDate = (date: string) => {
  return dayjs(date).format("MM/DD/YYYY");
};

const createName = (nameString: string) => {
    let split = nameString.split(/[,]+/)
    let last = split[0]
    let firstMiddle = split[1].split(" ")
    firstMiddle.shift()
    let first = firstMiddle[0]
    let middle = firstMiddle[1]
    return [last, first, middle]
} 

const transform = (data: any, i: number) => {
  let lastName, firstName, middleName
  if(data.name) {
    [lastName, firstName, middleName] = createName(data.name);
  }  

  return {
    key: data.id, 
    view: data.id,
    firstName: data.FIRST_NAME ? data.FIRST_NAME : firstName ? firstName : "", //Last,First M || Last, First M
    lastName: data.LAST_NAME ? data.LAST_NAME : lastName ? lastName : "", //Last,First M || Last, First M
    middleName: data.MIDDLE_NAME ? data.MIDDLE_NAME : middleName ? middleName : "",
    status: data.transactionStatus ? data.transactionStatus.replaceAll('"', "") : "", //transactionStatus
    transactionDate: convertDate(data.insertDate), //date convert to mm/dd/yyyy am/pm comes in year day month "2022-05-03T00:00:00Z" ask insertDate
    transactionNumber: data.TCN, //id
    transactionType: data.TOT, //transactionType
    agency: data.AGENCY, //agency
    reasonFingerPrinted: data.RFP,
  };
};

export const useQueryTransactions = (
    pageSize: number,
    currentPage: number,
    order: string,
    userORIs: any,
    userSecondaryORIs: any,
    userRole: any,
    usersPermissions: any,
    columnName: string,
    tableFiltersData: TableFilters,
    auditSearch?: boolean
) => {
    
    const url = urls.QUERY;
    const { config } = useConfig();
    const { addAuditEvent } = useAudit()
    const [loading, setLoading] = useState(false);
    let today = new Date();
    let todayTime = today.getTime();
    const { hardLimit, softLimit, extendDays } = config.transactionViewing;

    const [mappedData, setMappedData] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [orderByName, setOrderByName] = useState(columnName);
    const [error, setError] = useState<any>();
    const [, setCookie, removeCookie] = useCookies();



    useEffect(() => {
        //if table filters data contains search key(bool etc) came from search event then audit event
        console.log(`httpOnly debug refresh useTransactions useEffect 
        pageSize: ${pageSize}, 
        userRole: ${userRole}, 
        userORIs: ${JSON.stringify(userORIs)},
        pageSize: ${pageSize},
        currentPage: ${currentPage},
        softLimit: ${softLimit}`);    
        console.log(`usersPermissions: `, usersPermissions)
        const controller = new AbortController();
        const { signal } = controller;


        if(userORIs && userORIs.length && userRole) {
            const adminRoles = ["mc_admin"];
            const isAdmin = adminRoles.includes(userRole);
            const userOri = userORIs[0].ori;
            const userRfps = userORIs[0].rfp;

            let dayLimit = userRole === "mc_admin" ? "" : `&dayLimit=${softLimit}`;
            console.log(`useQueryTransactions tableFiltersData: ${JSON.stringify(tableFiltersData)}`);
            
            const { firstName, middleName, lastName, reasonFingerPrinted, agency, status, transactionNumber, transactionType, transactionDate} = tableFiltersData;
            const ORIstring = JSON.stringify(userORIs) as string;
            console.log(`useQueryTransactions ORIstring: ${ORIstring}`);
            
            console.log(`useQueryTransactions transactionDate: ${JSON.stringify(transactionDate)}`)

            const queryParams = [
                {
                    "name": "type",
                    "value": "Original"
                },
                {
                    "name": "ori_rfp_json",
                    "value": JSON.stringify(userORIs)
                },
                {
                    "name": "sori_rfp_json",
                    "value": JSON.stringify(userSecondaryORIs)
                },
                {
                    "value": usersPermissions.viewAllTransactionsNoLimit ? 'true' : 'false', // 'true' or 'false' 
                    "name": "isAdmin"
                },
                {
                    "value": usersPermissions.viewAllTransactionsNoLimit ? 'null' : '30',
                    "name": "days"
                },
                {
                    "value": columnName ,
                    "name": "orderBy"
                },
                {
                    "value": firstName ? firstName : 'null',
                    "name": "firstName"
                },
                {
                    "value": middleName ? middleName : 'null',
                    "name": "middleName"
                },
                {
                    "value": lastName ? lastName : 'null',
                    "name": "lastName"
                },
                {
                    "value": transactionNumber ? transactionNumber : 'null',
                    "name": "tcn"
                },
                {
                    "value": transactionType ? transactionType : 'null',
                    "name": "tot"
                },
                {
                    "value": reasonFingerPrinted ? reasonFingerPrinted : 'null',
                    "name": "rfp"
                },
                {
                    "value": agency ? agency : 'null',
                    "name": "ori"
                },
                {
                    "value": 'null',
                    "name": "secondaryOri"
                },
                {
                    "value": status ? status : 'null',
                    "name": "transactionStatus"
                },
                {
                    "value":  transactionDate?.length ? dayjs(transactionDate[0]).startOf('day').format('MM-DD-YYYY HH:mm:ss') : 'null',
                    "name": "lowerDate"
                },
                {
                    "value": transactionDate?.length ? dayjs(transactionDate[1]).endOf("day").format('MM-DD-YYYY HH:mm:ss') : 'null',
                    "name": "upperDate"
                }
            ]

            let transactionBodyRequest = {
                "queries": [
                    {
                        "queryParams": [
                           ...queryParams,
                            {
                                "value": order,
                                "name": "order"
                            }
                        ],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_TRANSACTION_TABLE_QUERY ?? "queryTransactionTable"
                    }
                ]
            }

            let transactionCountRequest = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_TRANSACTION_TABLE_COUNT_QUERY ?? "queryTransactionTableCount"
                    }
                ]
            }
                
            const fetchData = async (transactionBodyRequest: any, transactionCountRequest: any) => {
                try {
                    const transactionalUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;
        
                    console.log(`useQueryTransactions columnName: ${columnName}`);
                    console.log(`useQueryTransactions transactionBodyRequest: ${JSON.stringify(transactionBodyRequest)}`);

                    const data = await (await fetch(transactionalUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(transactionBodyRequest)})).json();

                    const countData = await (await fetch(transactionalUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(transactionCountRequest)})).json();
 
                    const {results} = data['queriesResults'][0];
                    const count = countData['queriesResults'][0].results[0]['count'];
                    const transformedData = results.map((obj: any, index: number) => { return transform(obj, index) });
                    setMappedData(transformedData);
                    setTotalRecords(count);
                    setLoading(false);
                    if(auditSearch){
                        addAuditEvent("Search executed", "Search params: " +  JSON.stringify(queryParams), new Date(), undefined, undefined);
                    }
        
                    
                } catch(err) {
                    setError(err);
                    setLoading(false);
                }
            }
    
            setLoading(true);
            fetchData(transactionBodyRequest, transactionCountRequest);
        }

        return () => controller.abort();
    }, [userRole, userORIs, pageSize, currentPage, softLimit, order, columnName, tableFiltersData]);

    const extendViewing = async (id: any) => {
        console.log("extendViewing id: ", id);
        const controller = new AbortController();
        const { signal } = controller;
        console.log("extend process.env.NODE_ENV", process.env.NODE_ENV);
        console.log("extend url", url);
        const reportingUrl = urls.REPORTING;
        const failed = false;
        return new Promise(async (resolve, reject) => {
          try {
            const res = await fetch(
              `${process.env.NODE_ENV === 'production' ? reportingUrl:""}/adjudication/transactions/extendViewing/${id}?extendDays=30&hardLimit=90`,
              {
                method: "POST",
                credentials: "include",
                signal,
              }
            );
            console.log('response', res);
           const status = res.status;
            if(status == 200){
                const response = await res.json();
                const message = response.message
                console.log('message: ', message)
                const numDays = parseInt(message.slice(-8, -6))
                let date = dayjs().add(numDays, "days").format("LL")
                console.log('message date ', date)
                resolve(`Transaction extended through ${date}`);
                console.log("extendViewing response: ", await res.json());
            }else{
                console.log('failed status',status);
                resolve(failed);
            }
           
          } catch (e: any) {
            console.log("error extending viewing for id: ", id);
          }
        });
    };

    return { mapped: mappedData, extendViewing, error, loading, totalRecords };
};
