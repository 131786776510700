export const getNameParts = (field: string | undefined) => {
    if (!field) return { firstName: "", middleName: "", lastName: "", suffix: "" };
      
    const listOfSuffixes = ['JR', 'SR', 'I', 'II', 'III', 'IV', 'V', 'PHD', 'MD', 'ESQ'];
    let nameParts = field.split(',');
    let lastName = nameParts[0] || '';
    let firstName: string | undefined = '', 
    middleName = '', 
    suffix: string = '';
  
  
    if (nameParts[1]) {
        let firstAndMiddleNamePart = nameParts[1]?.trim().split(" ");
  
        firstName = firstAndMiddleNamePart.shift();
        
        if(nameParts.length > 1 && listOfSuffixes.includes(firstAndMiddleNamePart[firstAndMiddleNamePart.length - 1]?.toUpperCase().replace('.', ''))) {
            suffix = firstAndMiddleNamePart.pop() as string;
        }
        middleName = firstAndMiddleNamePart.join(' '); 
    }
  
    return { firstName, middleName, lastName, suffix: suffix.toUpperCase() };
};