/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { role } from 'constants/roles';
import { useUserRole } from 'hooks/useUserRole'
import React from 'react'

interface EditViewProps {
    children?: React.ReactNode
}

export const EditView: React.FC<EditViewProps> = ({ children }) => {
    const { userRole } = useUserRole();

    return (
        <React.Fragment>
            {
                userRole === role.edit || userRole === role.admin && (userRole !== role.readonly || userRole !== role.restrictedView) && (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                )
            }
        </React.Fragment>
    )
}
