/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

export const lightTheme = {token: {
colorBgBase: '#FFFFFF',
colorPrimary: '#7536C3',
colorPrimaryBg: '#7536C3',
colorBgSpotlight: 'rgba(0, 0, 0, 0.75)',
colorBgContainer: '#FFFFFF',
colorBgElevated: '#ebeaf2',
colorTextBase: '#000',
colorBorderSecondary: "#d9d6e0",
fontFamily: `'Montserrat', sans-serif`,
borderRadius: 4
}}



export const darkTheme = {token: {
colorBgBase: '#1B1D1F',
colorPrimary: '#8566C1',
colorPrimaryBg: '#8566C1',
colorBgSpotlight: 'rgba(0, 0, 0, 0.75)',
colorBgContainer: '#1B1D1F',
colorBgElevated: '#2A2D30',
colorTextBase: '#FFFFFF',
colorBorderSecondary: "#404449",
fontFamily: `'Montserrat', sans-serif`,
borderRadius: 4
}}