import { useConfig } from '../hooks/useConfig';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const GetMaintenanceSchedule = () => {
    const { config } = useConfig();
    const startDate = config.scheduledMaintenance.startDate;
    const endDate = config.scheduledMaintenance.endDate;
    const currentDate = dayjs().format('MM-DD-YYYY HH:mm:ss');
    let maintenanceActive = currentDate >= startDate && currentDate <= endDate;

    return {maintenanceActive, startDate, endDate, currentDate }
}