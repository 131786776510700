/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

type PrintOptions = {
    printBackground?: boolean;
    title?: string;
};

const printTextOptionsDefault: PrintOptions = {
    printBackground: false,
    title: "CARES",
};

const createMarkup = (details: any) => {
  return {__html: details};
}

 const printText = (textToPrint: string, authorName: string, noteDescription: string, printOptions?: PrintOptions ) => {
    const { printBackground, title } = {...printTextOptionsDefault, ...printOptions};

    var printFrame = document.createElement('iframe');
    printFrame.name = "printFrame";
    printFrame.style.position = "absolute";
    printFrame.style.top = "-1000000px";
    document.body.appendChild(printFrame);
    var frameDoc = (printFrame.contentWindow) ? printFrame.contentWindow : ((printFrame.contentDocument as any).document) ? (printFrame.contentDocument as any).document : printFrame.contentDocument;
    // const printWindow = window.open(`${authorName}`, '_self', '');
    // if (!printWindow) return;
    frameDoc.document.write(`
    <head><title>${title}</title></head>
    <style> 
      body {
      white-space: pre-line;

      ${printBackground ? `-webkit-print-color-adjust: exact !important;
      -webkit-print-color-adjust:exact !important;
      print-color-adjust:exact !important;` : ""}

     } 
    </style>
    <body>
      ${textToPrint ?? ""}
      <br />
      <h3 style="marginLeft: -20px;">${noteDescription}</h3>
    </body>
    `);
    frameDoc.document.close();
    // printWindow.focus();
    // printWindow.print();
    // setTimeout(() => {
    //   printWindow.close();
    // }, 0);
    setTimeout(function () {
      (window.frames as any)["printFrame"].focus();
      (window.frames as any)["printFrame"].print();
      document.body.removeChild(printFrame);
    }, 500);
  };

  export default printText;