/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import he from 'he';
import { urls } from '../urls';

export type NoteType = 'redact' | 'note';

class NotesService {
  private registrationId: string;
  private type: NoteType;
  private notesUrl: string;

  constructor(registrationId: string, type: NoteType, baseUrl: string) {
    this.registrationId = registrationId;
    this.type = type;
    this.notesUrl = baseUrl;
  }

  async getNotes() {
    try {
      const endpoint = `${
        process.env.NODE_ENV === 'production' ? this.notesUrl : ''
      }/notes?limit=10`;

      const body = { id: this.registrationId, type: this.type };

      const options: RequestInit = {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body),
      };

      const data = await (await fetch(endpoint, options)).json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createNote(note: string) {
    const endpoint = `${
      process.env.NODE_ENV === 'production' ? this.notesUrl : ''
    }/note`;
    const encodeText = (text: string) => {
      const encodedText = he.encode(text);
      return encodedText as string;
    };
    const encodedNote = encodeText(note);
    console.log('create sanitizedNote debug', encodedNote);

    const body = {
      noteType: 'COMMENT',
      parentids: [{ id: this.registrationId, type: this.type }],
      body: {
        encodedNote,
      },
    };

    const options: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
    };

    try {
      const response = await (await fetch(endpoint, options)).json();
      return response;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  async updateNote(noteId: string, note: string) {
    const endpoint = `${
      process.env.NODE_ENV === 'production' ? this.notesUrl : ''
    }/note/${noteId}`;
    const encodeText = (text: string) => {
      const encodedText = he.encode(text);
      return encodedText;
    };
    const encodedNote = encodeText(note);
    console.log('update encodedNote debug', encodedNote);
    const body = {
      id: noteId,
      noteType: 'COMMENT',
      parentids: [{ id: this.registrationId, type: this.type }],
      body: {
        encodedNote,
      },
    };

    const options: RequestInit = {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify(body),
    };

    try {
      const response = await (await fetch(endpoint, options)).json();
      return response;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  async deleteNote(noteId: string) {
    const endpoint = `${
      process.env.NODE_ENV === 'production' ? this.notesUrl : ''
    }/note/${noteId}`;

    const options: RequestInit = {
      method: 'DELETE',
      credentials: 'include',
    };

    try {
      const response = await (await fetch(endpoint, options)).json();
      return response;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }
}

export default NotesService;
