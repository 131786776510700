/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { UserPermission } from "components/UserPermissions/permissionsConfig";


export const transactionSummaryScreenOptions = [
    { label: "view", value: UserPermission.viewTransactionSummary },
    // { label: "search and sort", value: UserPermission.searchAndSort },
    // { label: "save searches", value: UserPermission.saveSearches },
    ];

    export const detailsTabOptions = [
        { label: "view all data", value: UserPermission.viewAllDetailsData },
      ];

export const manualAdjudicationOptions = [
    { label: "allow manual adjudication", value: UserPermission.allowManualAdjudication }
];