/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect, useRef } from "react";
import { redirect, Navigate } from "react-router-dom";
import { Input, Button, Alert } from "antd";
import { useAuth } from "../../hooks/useAuth";
import { MFAPasswordReset } from "../../components/MFA-PasswordReset/MFAPasswordReset";
import type { InputRef } from "antd";
import { useLocalStorage } from "common";
import { GetMaintenanceSchedule } from "utils/getMaintenanceSchedule";


import "./Login.css";
export const Login = () => {
  const [{ userName, password }, setFields] = useState<any>({
    userName: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [user_name, setUserName] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [startMFAFlow, setStartMFAFlow] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [, , loggedIn] = useLocalStorage()
  const { maintenanceActive } = GetMaintenanceSchedule();


  useEffect(() => {
    inputRef.current!.focus({
      cursor: "start",
    });
  }, []);

  const updateField = (e: any) => {
    const { name, value } = e.target;
    setFields((currentValues: any) => ({ ...currentValues, [name]: value }));
    setErrorMessage(null);
  };

  const {
    login,
    mfa,
    userForgotPassword,
    forceResetPassword,
    handleResetPassword,
  } = useAuth();

  const handleSubmit = async (e: any) => {
    console.log("debug login handleSubmit ran e: ", e);
    e.preventDefault();
    setLoading(true);
    let response;

    response = await login?.({ userName, password });
    //console.log('debug login handleSubmit response: ', response)
    setLoading(false);
    if (response.username) {
      console.log("login success ", response);
      setStartMFAFlow(true);

      //setToken(response.username);

    } else {
      console.log("error: ", response);
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    if (loggedIn) redirect("/app/transaction-summary");
  }, [loggedIn]);

  useEffect(() => {
    if (!userForgotPassword && !forceResetPassword) setStartMFAFlow(false);
    if (mfa || userForgotPassword || forceResetPassword) setStartMFAFlow(true);
  }, [userForgotPassword, forceResetPassword, mfa]);

  const getUserName = () => {
    setUserName(true);
    setStartMFAFlow(true);
  };

  const resetPassword = async (
    finalPassword: string,
    code = null,
    userName: string
  ) => {
    let res = await handleResetPassword?.(finalPassword, code, userName);
    if (res) setStartMFAFlow(false);
  };

  const cancel = () => {
    setStartMFAFlow(false);
    console.log("has been cancelled");
    //setToken("");
  };

  useEffect(() => {
    console.log(
      `login errorMessage: ${errorMessage} startMFAFlow: ${startMFAFlow} token: ${token}`
    );
  }, [errorMessage, startMFAFlow, token]);

  const handleLogo = () => {
    if (window.location.href.includes('mepreprod') || window.location.href.includes('meprod')) return <>MAPNET</>
    if (window.location.href.includes('nmpreprod') || window.location.href.includes('caresnmdpsprod')) return <>CARES</>
    return <>CARES</>
  }

  return (
    <>
      {(
        !startMFAFlow ? (
          <form className="loginContainer">
            <div className="loginLogo">
              <span className="logoText">
                {handleLogo()}
              </span>
            </div>
            <div className="logoName">
              <p>Civil Adjudication and Response Solution</p>
            </div>
            <div className="loginInputs">
              {errorMessage ? (
                <span className="error-message">{errorMessage}</span>
              ) : null}
              <Input
                ref={inputRef}
                onChange={updateField}
                id="userName"
                placeholder="Username"
                name="userName"
                disabled={maintenanceActive}
              />
              <Input.Password
                type="password"
                onChange={updateField}
                id="password"
                placeholder="Password"
                name="password"
                disabled={maintenanceActive}
              />
            </div>
            <Button
              className="login-button"
              onClick={handleSubmit}
              disabled={loading || maintenanceActive}
              htmlType="submit"
              loading={loading}
            >
              Log in
            </Button>
            {maintenanceActive ? null :
              <span className="forgotPassword" onClick={getUserName}>
                Forgot Password
              </span>
            }
          </form>
        ) : (
          <div className="loginContainer">
            <div className="loginLogo">
              <span className="logoText">{handleLogo()}</span>
            </div>
            <div className="logoName">
              <p>Civil Adjudication and Response Solution</p>
            </div>
            <MFAPasswordReset
              cancel={cancel}
              resetPassword={resetPassword}
              userName={user_name}
            />
          </div>
        )
      ) /*: (
        <Navigate to="/app/transaction-summary" replace />
      )*/}
    </>
  );
};
