/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */
import { getDomainName } from "utils/getDomainName";

const domainName = getDomainName();

export const urls = {
    PERSON: `https://person.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    REPORTING: `https://reporting.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    TRANSACTION_HISTORY: `https://transactionhistory.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    TRANSACTION: `https://transaction.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    AUDIT: `https://audit.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    NOTES: `https://note.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    AGENCY: `https://agency.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    GROUP: `https://group.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    ROLE: `https://role.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    GROUPROLE: `https://grouprole.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    LOCK: `https://lock.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    RAPBACK: `https://rapback.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    QUERY: `https://query.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    AUTH: `https://auth.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    WORKFLOW:`https://workflow.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
    CONFIG:`https://config.us-west-2.${process.env.REACT_APP_DOMAIN_NAME}`,
}

    

    

