/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Permissions } from "./Permissions/Permissions"
import { Navigate } from 'react-router-dom'
import './PermissionsSettings.scss'

export const PermissionsSettings = () => {
  const items = [
    {
        label: "PERMISSIONS",
        key: 'PERMISSIONS',
        children:   
            <div className="applicantDetails-body_tab-content">
                <Permissions/>
            </div>
    }
];
    return (
        <>
            {
                process.env.NODE_ENV !== 'production'
                    ?   <div className="applicantDetails-body">
                            <Tabs 
                            defaultActiveKey="1" 
                            tabPosition='left'
                            items={items}
                            renderTabBar={(props, DefaultTabBar) => { return (
                                <DefaultTabBar {...props} className="applicantDetails-body_tab-bar" />
                            )}}
                            />
                        </div>
                    : <Navigate to="/" />
            }
        </>

    );
};
