/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import dayjs from "dayjs";
  
  const { RangePicker } = DatePicker;

 export type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

  export const parseFilterForSearch = (filter: any) => {
    const { transactionDate, ...rest } = filter;
    const [startDate, endDate] = transactionDate ?? [];
    
    const parsedDate = (date: any) => {
      return date ? dayjs(date) : null;
    }

    return {
      ...rest,
      transactionDate: [parsedDate(startDate), parsedDate(endDate)]
    };
  };
  


  const dateFormat = "MM/DD/YYYY";

export const disabledDate: RangePickerProps["disabledDate"] = (now) => {
  return now && now > dayjs().endOf("day");
};

interface DateRangePickerProps {
    value?: RangeValue;
    onChange?: (newValue: any) => void;
    onFinish?: (value: any) => void;
    onCalendarChange?: (newValue: any) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({value, onChange}) => {
    return (
      <RangePicker
        value={value}
        onChange={(newValue) => onChange?.(newValue)}
        allowEmpty={[true, true]}
        format={dateFormat}
        disabledDate={disabledDate}
        bordered={false}
      />
    )
}

export default DateRangePicker;
