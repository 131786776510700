/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React from "react";
import { FC } from "react";
import "./modal-content.scss";

interface TimeoutWarningProps {
  timeLeft: number;
}

const formatCountdownFriendly = (remainInMs: number) => {
  const remainInSecond = Math.round(remainInMs / 1000);
  const mins = Math.floor(remainInSecond / 60);
  const secs = remainInSecond % 60;

  let retSegments: string[] = [];
  if (mins) {
    retSegments = [mins.toString(), mins > 1 ? "minutes" : "minute"];
    secs && retSegments.push("and");
  }

  return [
    ...retSegments,
    secs.toString(),
    secs > 1 ? "seconds" : "second",
  ].join(" ");
};

export const TimeoutWarning: FC<TimeoutWarningProps> = ({ timeLeft }) => (
  <>
    <div className="warning-body">
      You’ve been inactive for a while. For your security, we will end your
      session in <b>{formatCountdownFriendly(timeLeft)}</b>.
    </div>
  </>
);
