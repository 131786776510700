/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {MdDeleteForever, MdModeEdit } from "react-icons/md";
import {
  Popover,
  Radio,
  AutoComplete,
  Button,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
  Table,
} from "antd";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import "./AgencyManagement.scss";
import NewFieldForm from "./components/NewFieldForm";
import { USSTATES } from "./USStates";
import AgencyManagementService from "./agencyManagementService/agencyManagementService";
import { useUserPermissions } from "components/UserPermissions/UserPermissionsContext";
import { useQueryAgencyManagement } from "hooks/useQueryAgencyManagement";
import { useAudit } from "../../hooks/useAudit";
import debounce from "debounce";
import { validate } from "email-validator";
import { v4 as uuid } from "uuid";
import { urls } from "../../urls";
import { useUserRole } from "hooks/useUserRole";
import { Navigate } from "react-router-dom";
import { useUserCognito } from "hooks/useUserCognito";

interface Agency {
  key: React.Key;
  agencyORI: string;
  reasonFingerprinted: string;
  agencyName: string;
  distributionMethod: string;
  agencyEmail: any;
  agencyAddress: string;
  agencyCity: string;
  agencyCounty: string;
  agencyState: string;
  agencyZipCode: string;
}

const mapAgencyToApi = (agency: Agency) => {
  console.log("debug rfp mapAgencyToApi 1agencyManagement agency: ", agency);
  return {
    id: agency.agencyORI,
    ori: agency.agencyORI,
    reasonFingerPrinted: agency.reasonFingerprinted,
    agency_name: agency.agencyName,
    distribution_method: agency.distributionMethod,
    agency_email: agency.agencyEmail,
    agency_address: agency.agencyAddress,
    agency_city: agency.agencyCity,
    agency_county: agency.agencyCounty,
    agency_state: agency.agencyState,
    agency_zipcode: agency.agencyZipCode,
  };
};

const openNotification = (agencyName: Agency["agencyName"], action: string) => {
  notification.success({
    message: `Successfully ${action} ${agencyName}`,
    placement: "topLeft",
    duration: 3,
  });
};

const distributionMethodOptions = [
  { label: "Applicant", value: "Applicant" },
  { label: "Agency", value: "Agency" },
  { label: "Both", value: "Both" },
  { label: "No Distribution", value: "No Distribution" },
];

const everythingAllowed = /.*/;

const inputsConfig = {
  agencyORI: {
    element: <Input bordered={false} disabled={true} />,
    required: true,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  reasonFingerprinted: {
    element: <Input bordered={false} />,
    required: true,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  agencyName: {
    element: <Input bordered={false} />,
    required: true,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  distributionMethod: {
    element: <Select options={distributionMethodOptions} />,
    required: true,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  agencyEmail: {
    element: <Input type="email" bordered={false} />,
    required: true,
    pattern: /\S+@\S+\.\S+/,
    validateTrigger: "onChange",
  },
  agencyAddress: {
    element: <Input bordered={false} />,
    required: false,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  agencyState: {
    element: (
      <AutoComplete
        backfill
        options={USSTATES}
        filterOption={(inputValue, option) =>
          option!.label.toUpperCase().startsWith(inputValue.toUpperCase())
        }
      />
    ),
    required: false,
    pattern: /^[^0-9]*$/,
    validateTrigger: "onBlur",
  },
  agencyCity: {
    element: <Input bordered={false} />,
    required: false,
    pattern: /^[^0-9]*$/,
    validateTrigger: "onChange",
  },
  agencyCounty: {
    element: <Input bordered={false} />,
    required: false,
    pattern: /^[^0-9]*$/,
    validateTrigger: "onChange",
  },
  agencyZipCode: {
    element: <Input bordered={false} />,
    required: false,
    pattern: /^\d{5}(?:[-\s]\d{4})?$/,
    validateTrigger: "onChange",
  },
} as any;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: Agency;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputsConfig[dataIndex]?.element ?? <Input />;
  //const [openEmailConfig, setOpenEmailConfig] = useState(false)
  //console.log('debug edit inputsConfig: ', inputsConfig)
  //console.log(`debug edit inputsConfig[${dataIndex}]`, inputsConfig[dataIndex])
  //console.log('debug edit editing: ', editing)
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          validateTrigger={
            inputsConfig[dataIndex].validateTrigger ?? "onChange"
          }
          rules={[
            (form) => {
              const { required = false, pattern = null } =
                inputsConfig[dataIndex] || {};
              return {
                required,
                pattern,
                message: `Please Input a Valid ${title}!`,
                validator: async (_, fieldValue) => {
                  if (
                    required &&
                    (!fieldValue || fieldValue.toString().trim() === "")
                  )
                    throw new Error(`Please Input an ${title} !`);
                  if (pattern && fieldValue && !pattern.test(fieldValue))
                    throw new Error(`Please Input a Valid ${title}!`);

                  if (dataIndex === "agencyState") {
                    const matchingUSState = USSTATES.find(
                      (state) =>
                        state.value.toUpperCase() ===
                          fieldValue.toUpperCase() ||
                        state.label.toUpperCase() === fieldValue.toUpperCase()
                    );
                    if (fieldValue && !matchingUSState)
                      throw new Error("Please Input a Valid State!");
                    if (matchingUSState)
                      form.setFieldsValue({
                        agencyState: matchingUSState.value,
                      });
                  }
                },
              };
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const stringSort = (fieldName: string) => (a: any, b: any) =>
  a[fieldName].toString().localeCompare(b[fieldName].toString());

//const agencyManagementService = new AgencyManagementService();

type ColumnName =
  | "edit"
  | "delete"
  | "ORI"
  | "RFP"
  | "Name"
  | "reasonFingerprinted"
  | "distributionMethod"
  | "Email"
  | "Address"
  | "City"
  | "County"
  | "State"
  | "ZipCode";

export const defaultColumns = [
  "Edit",
  "ORI",
  "Reason Fingerprinted",
  "Name",
  "Distribution Method",
  "E-Mail",
  "Address",
  "City",
  "State",
  "Zip Code",
];

const ALL_COLUMNS = [...defaultColumns, "Agency County"];

const dropDownOptions = ALL_COLUMNS.map((columnName) => ({
  label: columnName,
  value: columnName,
}));

export const ColumnsVisibilityButton: React.FC<{ setActiveColumns: any }> = ({
  setActiveColumns,
}) => {
  const { permissions } = useUserPermissions();
  const { usersPermissions } = useUserCognito();
  console.log('agency columnsVisibility usersPermissions: ', usersPermissions)
  
  console.log("ColumnsVisibilityButton render");
  const [open, setOpen] = useState(false);

  return (
    <Popover
      content={
        <Select
          style={{ width: "100%" }}
          optionLabelProp="Filter Mode"
          mode="multiple"
          placeholder="Select Columns"
          defaultValue={defaultColumns}
          options={dropDownOptions}
          onChange={(value: any) => setActiveColumns(value)}
        />
      }
      title="Add / Remove Columns"
      open={open}
      onOpenChange={(isOpen) => setOpen(isOpen)}
      trigger="click"
    >
      {usersPermissions.editExistingAgencies && (
        <Button className="button" type="primary" size="large">
          Edit Columns
        </Button>
      )}
    </Popover>
  );
};

export const AgencyManagement: React.FC<any> = () => {
  const [tableFilters, setTableFilters] = useState<any>({})
  const [total, setTotal] = useState(0);
  const [{currentPage, pageSize}, setPaginationState] = useState({pageSize: 25, currentPage: 1});
  const { addAuditEvent } = useAudit();
  const [sortOrder, setSortOrder] = useState<any>('default');
  const [currentSortedColumn, setCurrentSortedColumn] = useState<string>('default');
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const { permissions } = useUserPermissions();
  const { usersPermissions } = useUserCognito();
  const [allowed, setAllowed] = useState(true)
  const [clickedRow, setClickedRow] = useState<number>();
  const [updatedEmail, setUpdatedEmail] = useState<any>();
  const [emailObjs, setEmails] = useState<Array<any>>([]);
  const [activeColumns, setActiveColumns] = useState<readonly string[]>(defaultColumns);
  const url = urls.AGENCY;
  const { userRole } = useUserRole();
  const agencyManagementService = new AgencyManagementService(url);
  const ORIRef = React.useRef<any>("");
  const RFPRef = React.useRef<any>("");
  const NameRef = React.useRef<any>("");
  const reasonFingerprintedRef = React.useRef<any>("");
  const distributionMethodRef = React.useRef<any>("");
  const EmailRef = React.useRef<any>("");
  const AddressRef = React.useRef<any>("");
  const CityRef = React.useRef<any>("");
  const CountyRef = React.useRef<any>("");
  const StateRef = React.useRef<any>("");
  const ZipCodeRef = React.useRef<any>("");
  const [refs, setRefs] = useState<any>({
    ORIRef,
    RFPRef, 
    NameRef, 
    reasonFingerprintedRef,
    distributionMethodRef,
    EmailRef,
    AddressRef,
    CityRef,
    CountyRef,
    StateRef,
    ZipCodeRef
    })


  useEffect(() => {
    setRefs({
      ORIRef,
      RFPRef, 
      NameRef, 
      reasonFingerprintedRef,
      distributionMethodRef,
      EmailRef,
      AddressRef,
      CityRef,
      CountyRef,
      StateRef,
      ZipCodeRef
    })
  }, [
    ORIRef,
    RFPRef, 
    NameRef, 
    reasonFingerprintedRef,
    distributionMethodRef,
    EmailRef,
    AddressRef,
    CityRef,
    CountyRef,
    StateRef,
    ZipCodeRef
  ])
 

  useEffect(() => {
    console.log('debug agency useEffect usersPermissions: ', usersPermissions)
    setAllowed(usersPermissions.viewAgencies)
  }, [usersPermissions]) 

  const [agenciesJSON, setAgenciesJSON] = useState<any[]>([]);
 
  const handlePageChange = (currentPage: number, pageSize: number) => {
    setPaginationState({ currentPage, pageSize });
  };


  const { data: mappedData, totalRecords, loading }: any = useQueryAgencyManagement(pageSize, currentPage, sortOrder, currentSortedColumn, tableFilters);


  useEffect(() => {
    console.log(`useAgencyManagement data: ${JSON.stringify(mappedData, null, "\t")}`)
    setAgenciesJSON(mappedData);
    setTotal(totalRecords);
  },[mappedData, currentPage])


  useEffect(() => {
    let emailsPerAgency: Array<{}> = [];
    setData(
      agenciesJSON?.map(({ json_data: agency }, index: number) => {
        const matchingState =
          USSTATES.find(
            (state) =>
              state.value.toUpperCase() ===
                agency.agency_state?.toUpperCase() ||
              state.label.toUpperCase() === agency.agency_state?.toUpperCase()
          )?.value ?? "";

        let emails: Array<string> = [];
        let agencyEmails: string = "";
        let emailString = "";
        console.log("debug rfp agency: ", agency);
        //Agencies arent showing up bc some are missing emails...
        if (agency.agency_email) {
          if (
            agency.agency_email[0] &&
            agency.agency_email[0].hasOwnProperty("value")
          ) {
            console.log("debug agency email: ", agency.agency_email);

            const emailObj = {
              index,
              emails: agency.agency_email,
            };

            emailsPerAgency.push(emailObj);

            setEmails(emailsPerAgency);

            agency.agency_email.forEach((email: any) => {
              emails.push(email.value);
            });

            if (emails.length > 0) {
              emailString = emails.join(",");
              agencyEmails = "";
            }
          } else {
            console.log("debug agency email: ", agency.agency_email);
            agencyEmails = agency.agency_email.join(",");
          }
        } else {
          agencyEmails = "";
        }

        return {
          key: uuid(),
          agencyORI: agency.ori,
          reasonFingerprinted: agency.reasonFingerPrinted,
          agencyName: agency.agency_name,
          distributionMethod: agency.distribution_method,
          agencyEmail: agencyEmails.length > 0 ? agencyEmails : emailString,
          agencyAddress: agency.agency_address,
          agencyCity: agency.agency_city,
          agencyCounty: agency.agency_county,
          agencyState: matchingState,
          agencyZipCode: agency.agency_zipcode,
        };
      })
    );
  }, [agenciesJSON]);

  const [editingKey, setEditingKey] = useState<string | number | bigint>("");

  const [addForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
    addForm.resetFields();
  };

  const handleOk = async () => {
    try {
      const newAgency = (await addForm.validateFields()) as Agency;
      console.log("debug add email addAgency saving new agency: ", newAgency);
      const updatedEmails = newAgency.agencyEmail.split(",");
      console.log("debug add email addAgency updatedEmails: ", updatedEmails);

      newAgency.agencyEmail = updatedEmails;

      const mappedAgency = mapAgencyToApi(newAgency);
      console.log(
        "debug add email addAgency handleOk mappedAgency: ",
        mappedAgency
      );
      console.log("newAgency: ", newAgency);
      agencyManagementService.createUpdateAgency(
        newAgency.agencyORI,
        mapAgencyToApi(newAgency)
      );
      addAuditEvent("Add Agency", `Update data: ${JSON.stringify(newAgency)}`, new Date(), undefined)
      setData((currentAgencies) =>
        updateData(currentAgencies, newAgency.agencyORI, newAgency)
      );
      setOpen(false);
      openNotification(newAgency.agencyName, "Added");
      addForm.resetFields();
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const edit = (
    e: any,
    record: Partial<Agency> & { key: React.Key },
    index: number
  ) => {
    console.log("debug edit event:", e);
    console.log("debug edit record: ", record);
    console.log("debug edit index", index);
    setClickedRow(index);
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const deleteAgency = async (deletedAgency: Agency) => {
    console.log("debug delete agency: ", deletedAgency);
    const resp = await agencyManagementService.deleteAgency(
      deletedAgency.agencyORI
    ); //delte by id or ori? should ori be unique
    addAuditEvent("Delete Agency", "Delete data: " + deletedAgency.agencyName, new Date(), deletedAgency.key.toString(), undefined)
    console.log("debug delete resp: ", resp);
    setData((currentAgencies) =>
      currentAgencies.filter(
        (agency: Agency) => agency.key !== deletedAgency.key
      )
    );
    openNotification(deletedAgency.agencyName, "Deleted");
  };


  const cancel = () => {
    setEditingKey("");
  };

  const updateData = (
    currentAgencies: any,
    key: React.Key,
    updatedAgency: any
  ) => {
    const test = currentAgencies.map((agency: any) => {
      return agency.key === key ? { ...agency, ...updatedAgency } : agency;
    });
    return test;
  };

  const save = async (key: React.Key) => {
    try {
      let updatedAgency = (await form.validateFields()) as Agency;
      let updatedEmails = updatedAgency.agencyEmail.split(",");
      let updatedAgencyCopy = Object.assign({}, updatedAgency);
      console.log("debug add email edit/save updatedAgency", updatedAgency);
      addAuditEvent("Update Agency", "update data: " + updatedAgency.agencyName + " email: " + updatedAgency.agencyEmail, new Date(), updatedAgency.key.toString(), undefined)

      updatedAgency.agencyEmail = updatedEmails;

      setData((currentAgencies) =>
        updateData(currentAgencies, key, updatedAgency)
      );
      const update = mapAgencyToApi(updatedAgency);
      console.log("debeug add email edit/save update: ", update);
      agencyManagementService.createUpdateAgency(
        updatedAgency.agencyORI,
        update
      );
      addAuditEvent(
        "addAgency update Agency",
        `Update data: ${JSON.stringify(update)}`,
        new Date(),
        undefined
      );
      setEditingKey("");
    } catch (err) {
      console.log("Validate Failed:", err);
    }
  };

  const camelToTitleCase = (str: string) => {
    const result = str.replace(/([A-Z])/g, " $1");
    return result[0].toUpperCase() + result.slice(1);
  };

  console.log(
    "debug delete permissions.deleteExistingAgencies: ",
    usersPermissions.deleteExistingAgencies
  );

  const agencyManagementColumns = [
    {
      title: "Edit",
      dataIndex: "edit",
      hidden: false,
      render: (_: any, record: Agency, index: number) => {
        const editable = record.key === editingKey;
        return editable ? (
          <div className="edit-options">
            <span
              onClick={() => save(record.key)}
              className="edit-options__save--can-save"
            >
              <CheckOutlined /> Save
            </span>
            <span onClick={cancel}>
              <CloseOutlined /> Cancel
            </span>
          </div>
        ) : (
          <div
            key={`row${index}`}
            onClick={(e) => edit(e, record, index)}
            style={{ pointerEvents: editingKey !== "" ? "none" : "auto" }}
          >
            <MdModeEdit
              style={{
                fontSize: "28px",
                color: editingKey !== "" ? "gray" : "#8566C1",
              }}
            />
          </div>
        );
      },
      width: 60,
      align: "center" as const,
    },
    {
      title: "Delete",
      dataIndex: "delete",
      hidden: false,
      width: 40,
      render: (_: any, record: Agency) => {
        return (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteAgency(record)}
            cancelButtonProps={{ className: "button-outline" }}
            okButtonProps={{ className: "button" }}
            disabled={usersPermissions.deleteExistingAgencies ? false : true}
          >
            <MdDeleteForever
              style={{
                color: editingKey ? "gray" : "#F55542",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          </Popconfirm>
        );
      },
    },
    {
      title: "ORI",
      dataIndex: "agencyORI",
      editable: true,
      sorter: stringSort("agencyORI"),
      width: 100,
      hidden: false,
    },
    {
      title: "Reason Fingerprinted",
      dataIndex: "reasonFingerprinted",
      editable: true,
      sorter: stringSort("reasonFingerprinted"),
      width: 110,
      hidden: false,
    },
    {
      title: "Name",
      dataIndex: "agencyName",
      editable: true,
      width: 90,
      sorter: stringSort("agencyName"),
      hidden: false,
    },
    {
      title: "Distribution Method",
      dataIndex: "distributionMethod",
      editable: true,
      width: 130,
      hidden: false,
    },
    {
      title: "E-Mail",
      dataIndex: "agencyEmail",
      editable: true,
      width: 90,
      hidden: false,
    },
    {
      title: "Address",
      dataIndex: "agencyAddress",
      editable: true,
      width: 90,
      hidden: false,
    },
    {
      title: "City",
      dataIndex: "agencyCity",
      editable: true,
      width: 90,
      hidden: false,
    },
    {
      title: "County",
      dataIndex: "agencyCounty",
      editable: true,
      width: 150,
      hidden: false,
    },
    {
      title: "State",
      dataIndex: "agencyState",
      editable: true,
      width: 50,
      hidden: false,
    },
    {
      title: "Zip Code",
      dataIndex: "agencyZipCode",
      editable: true,
      width: 50,
      hidden: false,
    },
  ];

  useEffect(() => {
    console.log("debug edit editingKey updated: ", editingKey);
  }, [editingKey]);

  
  let filteredColumns = agencyManagementColumns
    .filter((selectedColumn) => {
      if (
        !usersPermissions.deleteExistingAgencies &&
        selectedColumn.title === "Delete"
      ) {
        selectedColumn.hidden = true;
      }
      if (
        !usersPermissions.editExistingAgencies &&
        selectedColumn.title === "Edit"
      ) {
        selectedColumn.hidden = true;
      }

      return !selectedColumn.hidden;
    })
    .filter((column: any) => {
      return activeColumns.includes(column.title);
    });

  useEffect(() => {
    console.log(`activeColumns: ${activeColumns}`);

    filteredColumns = agencyManagementColumns
      .filter((selectedColumn) => {
        if (
          !usersPermissions.deleteExistingAgencies &&
          selectedColumn.title === "Delete"
        ) {
          selectedColumn.hidden = true;
        }
        if (
          !usersPermissions.editExistingAgencies &&
          selectedColumn.title === "Edit"
        ) {
          selectedColumn.hidden = true;
        }

        return !selectedColumn.hidden;
      })
      .filter((column: any) => {
        return activeColumns.includes(column.title);
      });
  }, [activeColumns.length, activeColumns]);

  console.log(
    "debug columns agencyManagementColumns: ",
    agencyManagementColumns
  );

  const mergedColumns = filteredColumns.map((col) => {
    console.log("debug col merged col: ", col);

    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Agency) => ({
        record,
        // @ts-ignore
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.key === editingKey,
      }),
    };
  });

  console.log("debug columns mergedColumns: ", mergedColumns);

  const renderTableTitle = () => (
    <>
      <div className="tableControls">
        <div className="selectFilterMode">
          {usersPermissions.addNewAgencies && (
            <Button
              className="button"
              type="primary"
              size="large"
              onClick={() => {
                setOpen(true);
                setEditingKey("");
              }}
            >
              <PlusOutlined /> Add New Agency
            </Button>
          )}
        </div>
        <ColumnsVisibilityButton setActiveColumns={setActiveColumns} />
      </div>
    </>
  );

  const [openEmailConfig, setOpenEmailConfig] = useState(false);
  const [newEmail, setNewEmail] = useState<string>();

  const configEmail = debounce((e: any) => {
    console.log("config email e: ", e);
    console.log("configEmail data: ", data);
    console.log("configEmail clickedRow: ", clickedRow);
    if (e.hasOwnProperty("agencyEmail")) {
      const newEmails: Array<string> = e.agencyEmail.includes(",")
        ? e.agencyEmail.split(",")
        : [e.agencyEmail]; //FIX this new email contains both old and new when adding ssecond email
      const emails = [data[clickedRow!].agencyEmail];
      const seperated = emails[0].split(",");
      let emailValid;
      if (newEmails && emails) {
        if (newEmails.length > emails.length) {
          newEmails.forEach((email: string) => {
            console.log(
              `checking for ${email} in ${emails}: `,
              emails.includes(email)
            );
            if (!seperated.includes(email)) {
              emailValid = validate(email);
              if (emailValid) {
                setNewEmail(email);
                setOpenEmailConfig(true);
              } else {
                setOpenEmailConfig(false);
              }
            }
          });
        } else {
          if (validate(newEmails[0])) {
            setNewEmail(newEmails[0]);
            setOpenEmailConfig(true);
          } else {
            setOpenEmailConfig(false);
          }
        }
      } else {
        console.log("newEmails check else : ", newEmails);
      }
    }
  }, 2000);

  const handleSaveConfig = async (e: any) => {
    setOpenEmailConfig(false);
    console.log("handleSaveConfig email: ", newEmail);
    console.log("handleSaveConfig emailConfig: ", emailConfig);
    const data = {
      value: newEmail,
      config: emailConfig,
    };
    setUpdatedEmail(data);
  };

  const handleCancelAddEmail = () => {
    setOpenEmailConfig(false);
    //clear email list
  };

  const [emailConfig, setEmailConfig] = useState<string>();

  console.log('debug agency allowed: ', allowed);

  return (
    <>
      {allowed === true || allowed === undefined ? (
        <>
          {" "}
          <Modal
            title="Config Email"
            open={openEmailConfig}
            onOk={handleSaveConfig}
            okText={<>Save</>}
            cancelText={<>Cancel</>}
            onCancel={handleCancelAddEmail}
            width={"50vw"}
            centered
            okButtonProps={{ className: "button" }}
            cancelButtonProps={{ className: "button-outline" }}
          >
            <Radio.Group>
              <Radio
                value="recipient"
                key={1}
                onChange={(e: any) => setEmailConfig(e.target.value)}
              >
                Recipient
              </Radio>
              <Radio
                value="cc"
                key={2}
                onChange={(e: any) => setEmailConfig(e.target.value)}
              >
                CC
              </Radio>
              <Radio
                value="bcc"
                key={3}
                onChange={(e: any) => setEmailConfig(e.target.value)}
              >
                BCC
              </Radio>
            </Radio.Group>
          </Modal>
          <Modal
            title="Add New Agency"
            open={open}
            onOk={handleOk}
            okText={
              <>
                <PlusOutlined /> Add Agency
              </>
            }
            cancelText={
              <>
                <CloseOutlined /> Cancel
              </>
            }
            onCancel={handleCancel}
            width={"50vw"}
            centered
            okButtonProps={{ className: "button" }}
            cancelButtonProps={{ className: "button-outline" }}
          >
            <NewFieldForm form={addForm} agencies={data} />
          </Modal>
          <div className="agency-management">
            <div className="table-background">
              <Form
                form={form}
                component={false}
                onValuesChange={(e) => configEmail(e)}
              >
                <Table
                  title={renderTableTitle}
                  loading={loading}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data}
                  //columns={mergedColumnsConfig}
                  columns={mergedColumns}
                  scroll={{ y: "calc(100vh - 255px" }}
                  pagination={{
                    position: ["bottomLeft"],
                    current: currentPage,
                    total,
                    pageSize,
                    defaultPageSize: 25,
                    pageSizeOptions: ["10", "25", "50", "100"],
                    locale: { items_per_page: "" },
                    showTotal: (count, range) =>
                      `${range[0]}-${range[1]} of ${count} items`,
                    onChange: (currentPage, pageSize) => {
                      handlePageChange(currentPage, pageSize);
                    },
                    showSizeChanger: true,
                  }}
                  rowClassName={(record: any) =>
                    editingKey
                      ? record.key === editingKey
                        ? "agency-management__row--editable"
                        : "agency-management__row--not-editable"
                      : ""
                  }
                />
              </Form>
            </div>
          </div>
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
