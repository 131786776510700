/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { generateStatusHistories } from "utils/generatePersonData";
import { urls } from "../urls";

const convertDate = (date: string) => {
  return dayjs(date).format("MM/DD/YYYY");
};

//const createName = (nameString: string) => nameString.split(/[, ]+/);

const transform = (data: any, i: number) => {
  //const [lastName, firstName, middleName] = createName(data.name);
  console.log("debug data in transform: ", data);
  return {
    key: i, //id coould also be an index
    id: data.uuid,
    agency: data.agency,
    status: data.status, //transactionStatus
    transactionDate: convertDate(data.insert_date), //date convert to mm/dd/yyyy am/pm comes in year day month "2022-05-03T00:00:00Z" ask insertDat
  };
};

export const useTransactionHistory = (id: string) => {
  console.log("debug results useTranactionHisyory running id: ", id);
  //const [mapped, setMappedData] = useState<any>()
  const [mappedHistory, setMappedHistoryData] = useState<any>();
  const [mappedHistoryPerAgency, setMappedHistoryPerAgencyData] = useState<any>();
  const [completeHistory, setCompleteHistory] = useState<any>();
  const url = urls.TRANSACTION_HISTORY;
  console.log("transaction_history url: ", url);

  const [error, setError] = useState();
  const [fetchInterval, setFetchInterval] = useState(false);
  if (error) console.log("error: ", error); // placeholder to clear pipeline build warnings
  // just used for memoizing when finished fetching
  //const [refetchCount, setRefetchCount] = useState(0);

  console.log("transaction_history url: ", url);

  //const memodMapped = useMemo(() => mapped, [refetchCount]);
  console.log("debug results id: ", id);
  //const [getTransactionHistory]
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      //console.log(`useTransactions fetchDataUrl ${process.env.NODE_ENV === 'production' ? urls.REPORTING : ""}/adjudication/transactions?count=${count}&page=${page}&type=Original&order=${order}`)
      try {
        console.log("debug results about to fetch transactionHistory");
        const {
          token: { data },
        } = await (
          await fetch(
            `${
              process.env.NODE_ENV === "production" ? url : ""
            }/transactionHistory/${id}`,
            {
              credentials: "include",
              signal,
            }
          )
        ).json();

        console.log("update decision data[0] full history: ", data[0]);
        console.log("update decision data per agency history: ", data);

        const fullHistory = data[0];
        const fullHistoryOrdered = fullHistory;
        setCompleteHistory(fullHistory);

        fullHistoryOrdered.reverse();

        const agencies: any = [];
        const mappedHistoryPerAgency: any = [];
        fullHistoryOrdered.forEach((statusObj: any) => {
          console.log("debug results statusObj: ", statusObj);
          if (!agencies.includes(statusObj.agency)) {
            agencies.push(statusObj.agency);
            const respStatus = {
              uuid: statusObj.uuid,
              agency: statusObj.agency,
              status: statusObj.status,
              date: statusObj.insert_date,
            };
            mappedHistoryPerAgency.push(respStatus);
          }
        });

        setMappedHistoryPerAgencyData(mappedHistoryPerAgency);

        console.log("debug data agency true data: ", data);
        const mappedData = data.map((obj: any, index: number) =>
          transform(obj, index)
        );
        console.log("agency true mappedData: ", mappedData);
        //setMappedData(mappedData)

        const mappedHistoryData = fullHistory.map((obj: any, index: number) =>
          generateStatusHistories(obj, index)
        );
        setMappedHistoryData(mappedHistoryData);
      } catch (err: any) {
        if (err.name === "AbortError") setError(err);
      } /*finally {
                setRefetchCount((prev: number) => prev + 1);
            }*/
    };

    fetchData();

    return () => {
      console.log(`useTransactionHistory unmounting`);
      controller.abort();
    };
  }, [id, url]);

  console.log(
    `update decision mappedHistory: ${JSON.stringify(
      mappedHistory,
      null,
      "\t"
    )} mappedHistoryPerAgency: ${JSON.stringify(
      mappedHistoryPerAgency,
      null,
      "\t"
    )}`
  );
  return { mappedHistory, mappedHistoryPerAgency, completeHistory };
};
