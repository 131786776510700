/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react';
import {    generatePersonalInformation,  
            generateTransactionalInformation, 
            generateUserProfileInfo, 
            PersonalInfo, 
            TransactionalInfo, 
            UserProfileInfo } from 'utils/generatePersonData';
import { Storage } from 'aws-amplify';
import { urls } from '../urls'
import { useConfig } from './useConfig';
//import { ApplicantFetchStatus } from './shared/types';

export type ApplicantInfo = {
    userProfile: Partial<UserProfileInfo>,
    personalInfo: Partial<PersonalInfo>,
    transactionalInfo: Partial<TransactionalInfo>,
}

export type ApplicantFetchStatus = 'idle' | 'loading' | 'success' | 'error';

const initialApplicantInfo: ApplicantInfo = { userProfile: {}, personalInfo: {}, transactionalInfo: {}};

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json');

export const useApplicantDetails = (registrationId: string) => {
    const [applicantFetchStatus, setApplicantFetchStatus] = useState<ApplicantFetchStatus>('idle');
    const [applicantInfo, setApplicantInfo] = useState(initialApplicantInfo);
    const [applicantImageUrl, setApplicantImageUrl] = useState<any>();
    const { config } = useConfig()
    const url = urls.PERSON
    console.log('useApplicantDetails url: ', url)
    const { nistParsing } = config
    console.log('nistParsing: ', nistParsing)
    console.log(`environment: ${JSON.stringify(process.env, null, "\t")}`)
    useEffect(() => {
        setApplicantFetchStatus('loading');
        const controller = new AbortController();
        const { signal } = controller;
        const fetchApplicantData = async () => {
            try {
                const applicantData = await (await fetch(`${process.env.NODE_ENV === 'production' ? url : ""}/registration/${registrationId}`, {
                    method: 'GET',
                    credentials: "include",
                    signal
                })).json();

                console.log('update decision applicantData: ', applicantData)

                const formattedPersonInfo = {
                    userProfile: generateUserProfileInfo(applicantData, nistParsing),
                    personalInfo: generatePersonalInformation(applicantData, nistParsing),
                    transactionalInfo: generateTransactionalInformation(applicantData),
                    
                }
                setApplicantInfo(formattedPersonInfo);

                //we need to get the image access url some other way as we do not use identity pool and it is required for authentication to the aws bucket. 
                /*if(applicantData.biometricItems) {
                    const imagePath = `${applicantData.biometricItems[0].image.data.content}`;
                    console.log(`applicant2 imagePath: ${JSON.stringify(imagePath)}`);
                    const imageAccessURL = await Storage.get(imagePath);
                    console.log(`applicant2 imageAccessURL: ${JSON.stringify(imageAccessURL)}`);

                    setApplicantImageUrl(imageAccessURL);
                }*/
                

                setApplicantFetchStatus('success');
            } catch (err) {
                console.error(err);
                setApplicantFetchStatus('error');
            }
        }

        fetchApplicantData();
        
        return () => {
            ///clearInterval(fetchInterval)
            controller.abort()
        };
    }, [registrationId, url, config]);

    return { applicantInfo, applicantImageUrl, applicantFetchStatus };
}
