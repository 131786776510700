/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useState } from "react";

export type Config = {
  cognito: any, 
  nistParsing: any,
  transactionViewing: any,
  environment: any,
  scheduledMaintenance: any
}

export const useConfig = () => {
    const [config, setConfig] = useState<Config>({cognito: {}, nistParsing: {}, transactionViewing: {}, environment: {}, scheduledMaintenance: {}});

    useEffect(() => {
        const controller = new AbortController();
        //const { signal } = controller;
        
        const getConfig = async () => {
            try {
                fetch('../../config.json')
                    .then(response => {
                        console.log('useConfig response: ', response)
                      return response.json();
                    }).then(data => {
                        console.log('useConfig data: ', data)
                      setConfig(data);
                    }).catch((e: Error) => {
                      console.log(e.message);
                    });
                  
                } catch(err: any) {
                return {errorMessage: err.message} 
            }
        }

        getConfig();
        return () => controller.abort();
    }, [])

    return { config };
};