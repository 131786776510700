
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect } from "react";
import { urls } from '../urls'
import { useConfig } from './useConfig'


type PdfUrls = readonly string[];

const usePdfURL = (registrationId: string): { pdfURLs: PdfUrls } => {
    const [pdfURLs, setPdfURLs] = React.useState<PdfUrls>([]);
    const { config } = useConfig()
    const url = urls.REPORTING
    console.log('usePdf url: ', url)

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let fetchUrl = `${process.env.NODE_ENV === 'production' ? url : ""}/adjudication/pdf/${registrationId}`
        console.log(`debug url usePdf fetchUrl: ${fetchUrl} `)
        const fetchUrls = async () => {
            try {
                const { urls } = await (await fetch(fetchUrl, {
                    method: "GET",
                    credentials: "include",
                    signal
                })).json();

                setPdfURLs(urls);

            } catch (err: any) {
                console.log('ERROR => ', err);
            }
        }
        
        fetchUrls();
        
        //Refresh pre-signed URLs every minute.
        const refreshUrlInterval = setInterval(() => {
            fetchUrls();
        },60000);

        

        return () => {
            clearInterval(refreshUrlInterval);
            controller.abort();
        }
    }, [registrationId, url])

    return { pdfURLs };
};
export default usePdfURL;
