/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Navigate, useOutlet } from "react-router-dom";
//import { useAuth } from "../../hooks/useAuth";
import { useCookies } from "react-cookie";
import { FooterLayout } from './Footer'
import { useEffect, useState } from "react";
import { useLocalStorage } from "common";
import { Alert } from 'antd';
import { useConfig } from '../../hooks/useConfig';
import dayjs from 'dayjs';
import { GetMaintenanceSchedule } from '../../utils/getMaintenanceSchedule';

export const AppLayout = () => {
    //const { token } = useAuth()
    const [, , loggedIn] = useLocalStorage()
    const outlet = useOutlet();
    const {maintenanceActive } = GetMaintenanceSchedule();
 
    if (loggedIn) {
        return <Navigate to="/app/transaction-summary" />;
    } 

    return (
        <div style={{
            backgroundColor: '#1B1D1F',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
            {
                maintenanceActive ? 
                <Alert style={{margin: '1rem'}}
                type="warning" 
                showIcon 
                description="This website is under maintenance, please check back later."/>
                : null
              }
            {outlet}
            <FooterLayout />
        </div>
    )
};