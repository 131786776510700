/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Mark, mergeAttributes } from "@tiptap/react";

export const Redact = Mark.create({
    name: 'redact',
  /*   addOptions() {
      return {
        multicolor: false,
        HTMLAttributes: {},
      }
    }, */
    excludes: 'highlight',
    
  
    addAttributes() {
      // if (!this.options.multicolor) {
      //   return {}
      // }
  
      return {
        color: {
          default: null,
          parseHTML: element => element.getAttribute('data-color') || element.style.backgroundColor,
          renderHTML: attributes => {
            if (!attributes.color) {
              return {}
            }
  
            return {
              'data-color': attributes.color,
              style: `background-color: ${attributes.color}; color: ${attributes.color};`,
            }
          },
        },
      }
    },
  
    parseHTML() {
      return [
        {
          tag: 'mark',
        },
      ]
    },
  
    renderHTML({ HTMLAttributes }) {
      return ['mark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },
  
    // @ts-ignore
    addCommands() {
      return {
        setRedaction: (attributes: any) => ({ commands }: any) => {
          return commands.setMark(this.name, attributes)
        },
        toggleRedaction: (attributes: any) => ({ commands }: any) => {
          return commands.toggleMark(this.name, attributes)
        },
        unsetRedaction: () => ({ commands }: any) => {
          return commands.unsetMark(this.name)
        },
      }
    }
  
  /*   addKeyboardShortcuts() {
      return {
        'Mod-Shift-h': () => this.editor.commands.toggleHighlight(),
      }
    }, */
  
  /*   addInputRules() {
      return [
        markInputRule({
          find: inputRegex,
          type: this.type,
        }),
      ]
    },
  
    addPasteRules() {
      return [
        markPasteRule({
          find: pasteRegex,
          type: this.type,
        }),
      ]
    }, */
  });