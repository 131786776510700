/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react'
import { urls } from '../urls'
import { updateToken } from "./shared/helpers"
import { useCookies } from "react-cookie";


export const useRapBackCount = () => {
    const [count, setCount] = useState<any>(0);
    const [countError, setError] = useState();
    const [, setCookie, removeCookie] = useCookies();

    const url = urls.RAPBACK

    console.log('rapBackSummary useRapBackCount node env: ', process.env.NODE_ENV)
    console.log('rapBackSummary useRapBackCount url: ', url)
     
    useEffect(() => {
        const controller = new AbortController()
        const { signal } = controller


        const fetchCount = async () => {
            //console.log(`useCount fetchDataUrl ${process.env.NODE_ENV === 'production' ? urls.REPORTING : ""}/adjudication/association/count`)
            console.log('rapBackSummary fetchCount getUrl url: ', url)
            let token = await updateToken()

            try {
                const resp = await (await fetch(`${process.env.NODE_ENV === 'production' ? url :""}/rapback/count`, {
                    credentials: "include",
                    signal
                })).json();
                console.log('rapBackSummary debug rapback count: ', resp.count)
                setCount(resp.count)

            } catch (err: any) {
                if (err.name === "AbortError") {
                    setError(err)
                }
            }
        }

        fetchCount()

        const fetchCountInterval = setInterval(() => {
            fetchCount();
        }, 300000);

        return () => {
            clearInterval(fetchCountInterval);
            controller.abort();
        }
    }, [url])

    return { count, countError }
}