/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { SearchOutlined } from "@ant-design/icons";
import { Button, Modal, Input } from "antd";
import React, { useEffect, useReducer } from "react";
import './SavedSearches.scss';

interface SavedSearchItemProps {
    searchName: string;
    onSearch: any/* React.Dispatch<React.SetStateAction<TableFilters>> */,
    onDelete: any
  }

interface SavedSearchPopUpProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    searchFilter: any,
    savedSearchState: any
  }

  enum ModalStatuses {
    ALREADY_EXISTS = "ALREADY_EXISTS",
    OK = "OK"
  }

  enum ModalActionTypes {
    CHANGE_LABEL_NAME = "CHANGE_LABEL_NAME",
    LABEL_EXISTS = "LABEL_EXISTS",
    SET_STATUS_OK = "SET_STATUS_OK",
    CLEAR_LABEL_NAME = "CLEAR_LABEL_NAME"
  }

  const initialModalState = {
    labelName: "",
    okText: "Save",
    status: ModalStatuses.OK
  }

  const modalReducer = (currentState: any, {type, payload}: any) => {
    switch (type) {
      case ModalActionTypes.CHANGE_LABEL_NAME:
        const { labelName } = payload;
        return {
          ...currentState,
          labelName,
          status: ModalStatuses.OK,
          okText: "Save"
        };
      case ModalActionTypes.LABEL_EXISTS:
        return {
          ...currentState,
          okText: "Update Search",
          status: ModalStatuses.ALREADY_EXISTS,
        };
      case ModalActionTypes.SET_STATUS_OK:
        return {
          ...currentState,
          status: ModalStatuses.OK,
        };
      case ModalActionTypes.CLEAR_LABEL_NAME:
        return {
          ...currentState,
          labelName: ""
        };
      default: throw new Error('Invalid action type');
    }
  };

 export const SavedSearchPopUp: React.FC<SavedSearchPopUpProps> = ({openState, searchFilter, savedSearchState}) => {
    const [modalState, dispatch] = useReducer(modalReducer, initialModalState);
    const [savedSearchPopUpOpen, setSavedSearchPopUpOpen] = openState;
    const [savedSearches, dispatchFn] = savedSearchState;
    const { labelName, okText, status } = modalState;

    const closeModal = () => setSavedSearchPopUpOpen(false);

    const handleConfirmation = async () => {
      dispatch({type: ModalActionTypes.SET_STATUS_OK});
      dispatchFn({type: "ADD_SAVED_SEARCH", savedSearch: {[labelName]:  searchFilter}})
      closeModal();
    };

    const onOk = () => {
        status === ModalStatuses.ALREADY_EXISTS ? 
        Modal.confirm({
            title: 'Name already exists',
            content: 'A search with this name already exists. Please cancel and enter a new name OR select the "Confirm" button to update the parameters of your previous save.',
            onOk: handleConfirmation,
            okText: "Confirm",
            okButtonProps: {className: "button"},
            cancelButtonProps: {className: "outline-button"}
          }) : 
          handleConfirmation();
    };

    const onCancel = () => {
        closeModal();
        dispatch({type: ModalActionTypes.CLEAR_LABEL_NAME});
    };

    const onLabelNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
       dispatch({type: ModalActionTypes.CHANGE_LABEL_NAME, payload: {labelName: e.target.value}});
    };

    const numKeySavedSearches = Object.keys(savedSearches).length

    useEffect(() => {
       (labelName in savedSearches) && dispatch({type: ModalActionTypes.LABEL_EXISTS}) 
    }, [labelName, numKeySavedSearches, savedSearches]);

    return (
    <Modal
        title="Save as"
        okText={okText}
        centered
        open={savedSearchPopUpOpen}
        onOk={onOk}
        onCancel={onCancel}
        maskClosable
        closable={false}
        okButtonProps={{disabled: !labelName.length, className: "button"}}
        cancelButtonProps={{className: "outline-button"}}
      >
        Please label your saved search:
        <Input 
        placeholder="Name your search"
        value={labelName}
        onChange={onLabelNameChange}
        maxLength={25}
        showCount
        allowClear
        />
      </Modal>
    );
  };

  
export const SavedSearchItem: React.FC<SavedSearchItemProps> = ({ searchName, onSearch, onDelete }) => {
  const onSearchClick = (e: any) => {
      onSearch?.();
  }
  return (
    <div className="saved-searches">
      {searchName}
      <span onClick={() => onDelete()} className="delete-search">Delete</span>
      <Button className="button" type="primary" onClick={onSearchClick}>
        Search  <SearchOutlined />
      </Button>
    </div>
  );
};


const SavedSearches: React.FC<any> = ({children}) => {
return (
  <div>
  <div className="container-header">SAVED SEARCHES</div>
    <div className="saved-searches-container"> 
        <div className="saved-searches-items-container">
        {children}
        </div>
    </div>
    </div>
)
};

export default SavedSearches;
