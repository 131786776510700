/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { role } from "constants/roles";
import ApiService from "utils/apiService";
import { formatPermissionsAfterFetch } from "components/UserPermissions/permissionsConfig";
import { urls } from "urls";
import { useLocalStorage } from "common";

export const useUserCognito = () => {
  const [userRole, setUserRole] = useState<string | string[]>();
  const [userORIs, setUserORIs] = useState<any[]>([]);
  const [userSecondaryORIs, setuserSecondaryORIs] = useState<any[]>([]);
  const [usersPermissions, setUsersPermissions] = useState<any>({});
  const [groups, setGroups] = useState([]);
  const [, , loggedIn] = useLocalStorage<boolean>()
  const groupUrl = urls.GROUP;
  const roleUrl = urls.ROLE;
  const groupRoleUrl = urls.GROUPROLE;
  console.log("ori_rfp_filter groupUrl: ", groupUrl);
  console.log("ori_rfp_filter roleUrl: ", roleUrl);
  console.log("ori_rfp_filter groupRoleUrl: ", groupRoleUrl);
  const groupRoles: any = [];

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const groupsService = new ApiService(
      `${process.env.NODE_ENV === "production" ? groupUrl : ""}/group`
    );
    const rolesService = new ApiService(
      `${process.env.NODE_ENV === "production" ? roleUrl : ""}/role`
    );
    const groupRoleService = new ApiService(
      `${process.env.NODE_ENV === "production" ? groupRoleUrl : ""}/groupRole`
    );

    const getUserInfoFromCognito = async () => {
      const user = await Auth.currentAuthenticatedUser();
      console.log('httpOnly user: ', user)
      console.log(
        `httpOnly useUserCognito user ${JSON.stringify(
          user.signInUserSession.idToken.payload,
          null,
          "\t"
        )}`
      );

      const idTokenPayload = user.signInUserSession.idToken.payload;

      const userGroups = idTokenPayload["cognito:groups"];

      console.log('httpOnly userGroups: ', userGroups)

      const userOris = idTokenPayload["custom:ori-long"] || idTokenPayload["custom:ori"];

      let userSecondaryORIs:any = []; 
      if(idTokenPayload["custom:secondary-ori"] !== undefined){
        userSecondaryORIs = idTokenPayload["custom:secondary-ori"]
      }

      console.log('httpOnly userOris: ', userOris)
      if (userOris.length) {
        console.log(`httpOnly debug userORI's setting userOris: `, userOris);
        
        setUserORIs(JSON.parse(userOris));
      }

      console.log(
        `debug userORI's useUserCognito userOris: ${JSON.stringify(
          userOris,
          null,
          "\t"
        )}`
      );
      
      console.log('httpOnly userSecondaryORIs: ', userSecondaryORIs)
      if (userSecondaryORIs.length) {
        console.log(`httpOnly debug userSecondaryORIs's setting userSecondaryORIs: `, userSecondaryORIs);

        setuserSecondaryORIs(JSON.parse(userSecondaryORIs));
      }

      console.log(
        `debug userSecondaryORIs's useUserCognito userOris: ${JSON.stringify(
          userSecondaryORIs,
          null,
          "\t"
        )}`
      );

      try {
        console.log('httpOnly 1 useUserCognito loggedIn: ', loggedIn)
        const { data, status } = await groupsService.get();
       
        const allGroups = data
        console.log('httpOnly 2 allGroups: ', allGroups)
        setGroups(allGroups);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        const resp = await groupRoleService.get(userGroups.join())
        console.log('httpOnly role: ', resp)
        groupRoles.push(resp.data)
        console.log("httpOnly 3 debugging groupRoles", groupRoles);
        const formattedPermissions = await formatPermissionsAfterFetch(
          groupRoles
        );

        console.log('httpOnly 4 formattedPermissions: ', formattedPermissions)

        setUsersPermissions(formattedPermissions);
        console.log("httpOnly debugging 5 formattedPermissions", formattedPermissions);

        if (userGroups.length) {
          const userRole = userGroups[0];

          console.log("httpOnly 6 userRole:", userRole);

          if (userRole) setUserRole(userRole);
        } else {
          console.log(`httpOnly 7 ${user.userusername} doesn't belong to any groups`);
        }
        
      } catch (err: any) {
        return { errorMessage: err.message };
      }
    };

    if (loggedIn) {
      console.log(`httpOnly loggedIn: ${loggedIn}`)
      getUserInfoFromCognito();
    }
    
    return () => controller.abort();
  }, [groupUrl, roleUrl, groupRoleUrl, groupRoles.length, loggedIn]);

  return { userORIs, userSecondaryORIs, userRole, usersPermissions, groups };
};
