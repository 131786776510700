/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useState } from "react";
import { Auth } from 'aws-amplify';
import { role } from "constants/roles";
import { useConfig } from '../../src/hooks/useConfig'
import { useLocalStorage } from "common";
import { useAuth } from 'hooks'

export const useUserRole = () => {
    const [userRole, setUserRole] = useState(role.edit);
    const { config } = useConfig()
    const [, , loggedIn] = useLocalStorage()

    useEffect(() => {
        const controller = new AbortController();
        //const { signal } = controller;
        console.log('config: ', config)
        
        const getUserRole = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                console.log('user: ', user)
                const idTokenPayload = user.signInUserSession.accessToken.payload;
                const userGroup = idTokenPayload["cognito:groups"];
                console.log('user group: ', userGroup)
                if(userGroup.length) {
                    const userRole = userGroup[0];
                    console.log(`debug in hook userRole: ${userRole}`)
                    if(userRole) setUserRole(userRole);
                } else {
                    console.log(`${user.userusername} doesn't belong to any groups`)
                }

                } catch(err: any) {
                return {errorMessage: err.message} 
            }
        }

        if(loggedIn) getUserRole();
        return () => controller.abort();
    }, [loggedIn])

    return { userRole };
};