/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { role } from "constants/roles";

export const hideFieldsForRestrictedView = (userRole: string) => {
  if (userRole === role.restrictedView) {
    return true;
  } else {
    return false;
  }
};

export const disableInputForNonEdit = (userRole: string) => {
  if (
    userRole === role.edit ||
    (userRole === role.admin &&
      (userRole !== role.readonly || userRole !== role.restrictedView))
  ) {
    return false;
  } else {
    return true;
  }
};

export const disableInputForNonEditFinalDecision = (
  userRole: string,
  waitingForManualAdjudication: boolean
) => {
  if (waitingForManualAdjudication) {
    if (
      userRole === role.edit ||
      (userRole === role.admin &&
        (userRole !== role.readonly || userRole !== role.restrictedView))
    ) {
      return false;
    }
  } else {
    return true;
  }
};

export const disableInputForNonAdmin = (userRole: string) => {
  if (
    userRole !== role.edit ||
    userRole !== role.readonly ||
    userRole !== role.restrictedView ||
    userRole !== role.admin
  ) {
    return true;
  } else {
    return false;
  }
};
