
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect, useState } from "react";
import { urls } from '../urls'


export const useRapSheets = (registrationId: string) => {
    const [rapSheets, setRapSheets] = React.useState<any>();
    const reportingUrl = urls.REPORTING
    const personUrl = urls.PERSON;

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let fetchReportingsUrl = `${process.env.NODE_ENV === 'production' ? reportingUrl : ""}/adjudication/association/${registrationId}`;
        
        const fetchUrls = async () => {
            try {
                const rapSheetArray: any[] = [];
                const { associations } = await (await fetch(fetchReportingsUrl, {
                    credentials: "include",
                    signal
                })).json();

                if(associations.length) {
                    associations.filter((association: any) => association['type'] === 'Response').map(async (repAssociation: any) => {
                        let fetchingPersonUrl = `${process.env.NODE_ENV === 'production' ? personUrl : ""}/registration/${repAssociation.id}`;
                        const data = await (await fetch(fetchingPersonUrl, {
                            credentials: "include",
                            signal
                        })).json()
                        .then(data => {
                            const { descriptors } = data;
                            const type2Field75 =  descriptors.data.type2.field75;                            
                            type2Field75 && rapSheetArray.push(type2Field75);
                            if (!rapSheetArray.length) {
                                let notApplicableMessage = 'No Report Have Not Been Found'
                                rapSheetArray.push(notApplicableMessage)
                                setRapSheets(rapSheetArray)
                            } 
                        });
                        if (rapSheetArray.length > 0) {
                            sortData(rapSheetArray)
                        } 
                    });
                }
            } catch (err: any) {
                console.log('ERROR => ', err);
            }
        }

        const sortData = async (data: any) => {
            const rapSheetSorted = data.sort((a:any, b: any) => {
                if (a.toLowerCase().includes('state')) return -1;
                if (b.toLowerCase().includes('state')) return 1;
                return 0
            })
            setRapSheets(rapSheetSorted)
        }
        
        fetchUrls();
        
        console.log('rapSheet', rapSheets )

        // Will figure another way to fetch data to refresh data if there's more report coming in. Maybe consider fetching based on count? 
        // const refreshUrlInterval = setInterval(() => {
        //     fetchUrls();
        // }, 60000);


        // return () => {
        //     clearInterval(refreshUrlInterval);
        //     controller.abort();
        // }

    }, [registrationId, reportingUrl, personUrl])
                        
    return { rapSheets };
};
