/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../hooks/useAuth";
import { QRCodeSVG } from "qrcode.react";
import { Input, Button, Form } from "antd";
import type { InputRef } from "antd";


interface MFAProps {
  userName: boolean;
  resetPassword: Function;
  cancel: Function;
}

export const MFAPasswordReset: React.FC<MFAProps> = ({
  userName,
  resetPassword,
  cancel,
}) => {
  const {
    handleMFASubmit,
    user,
    mfa,
    authCode,
    userForgotPassword,
    handleResetPassword,
    authFlowError,
    sendForgotPassCode,
    forceResetPassword,
    resetMFA,
    resetForceResetPassword,
    resetForgotPassword,
    logout,
  } = useAuth();

  const [verifyCode, setVerifyCode] = useState<string>("");
  const [mfaVerify, setMFAVerify] = useState(false);
  const [setupMFA, setSetupMFA] = useState(false);
  const [userResetPass, setUserResetPass] = useState(false);
  const [forceResetPass, setForceResetPass] = useState(false);
  const [getUserName, setGetUserName] = useState(false);
  const [userNameValue, setUserNameValue] = useState<string>("");
  const [currentPass, setCurrentPass] = useState<string>("");
  const [newPass, setNewPass] = useState<string>("");
  const [finalPassword, setPassword] = useState<string>("");
  const [labelText, setLabelText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const btnRef = useRef<any>(null);

  useEffect(() => {
    if (mfaVerify)
      inputRef.current!.focus({
        cursor: "start",
      });
  }, [mfaVerify]);

  useEffect(() => {
    if (
      setupMFA ||
      mfaVerify ||
      forceResetPass ||
      userForgotPassword ||
      getUserName
    ) {
      //btnRef.current!.focus()
    }
  }, [setupMFA, mfaVerify, forceResetPass, userForgotPassword, getUserName]);

  useEffect(() => {
    if (mfa && handleMFASubmit && !authCode) {
      setMFAVerify(true);
      setLabelText("Two-Factor Authenticator");
    } else if (mfa && handleMFASubmit && authCode) {
      setSetupMFA(true);
      setLabelText("Enable Two-Factor Authentication");
    } else if (userForgotPassword && handleResetPassword) {
      setLabelText("Reset Password");
      setGetUserName(false);
      setUserResetPass(true);
    } else if (forceResetPassword && handleResetPassword) {
      setLabelText("Reset Password");
      setForceResetPass(true);
    } else if (userName && !userForgotPassword) {
      setLabelText("Enter User Name");
      setGetUserName(true);
    } else if (!forceResetPassword) {
      setForceResetPass(false);
    } else if (!userForgotPassword) {
      setUserResetPass(false);
    }
  }, [
    mfa,
    authCode,
    user,
    userName,
    userForgotPassword,
    forceResetPassword,
    getUserName,
    handleMFASubmit,
    handleResetPassword,
  ]);

  const updateCode = (e: any) => {
    let code = e.target.value;
    setVerifyCode(code);
  };

  const updateUserName = (e: any) => {
    setUserNameValue(e.target.value);
  };

  const handleSendVerification = async () => {
    if (userNameValue) sendForgotPassCode?.(userNameValue);
    else setErrorMessage("User name empty!");
    setGetUserName(false);
  };

  const updateCurrentPass = (value: string) => {
    setCurrentPass(value);
  };

  const updateNewPass = (value: string) => {
    setNewPass(value);
  };

  useEffect(() => {
    if (authFlowError) setErrorMessage(authFlowError);
  }, [authFlowError]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("")
      }, 5000);
    }
  })

  useEffect(() => {
    if (currentPass && newPass) {
      if (currentPass === newPass) {
        setPassword(newPass);
        setErrorMessage("");
      } else {
        setErrorMessage("Passwords do not match.");
      }
    }
  }, [currentPass, newPass]);

  const handleKeypress = (e: any) => {
    console.log("enter pressed");
    if (e.keyCode === 13) {
      if (setupMFA || mfaVerify) {
        handleMFASubmit?.(verifyCode);
      } else if (forceResetPass || userForgotPassword) {
        resetPassword?.(
          finalPassword,
          userResetPass ? verifyCode : null,
          userNameValue
        );
      } else if (getUserName) {
        handleSendVerification();
      }
    }
  };

  const handleMFAclick = (verifyCode: string) => {
    setLoading(true);
    handleMFASubmit?.(verifyCode)
    setLoading(false);
  };

  useEffect(() => {
    // setVerifyCode("");
    if (mfaVerify === true && authCode) setMFAVerify(false);
    if (setupMFA === true) setSetupMFA(false);
    if (userResetPass === true && errorMessage) setUserResetPass(false);
    if (forceResetPass === true && errorMessage) setForceResetPass(false);
    // setGetUserName(false);
    // setUserNameValue("");
    // setCurrentPass("");
    // setNewPass("");
    // setPassword("");
    // setLabelText("");
    // setErrorMessage("");
    // if (resetMFA && setupMFA) resetMFA();
    // if (resetForceResetPassword && forceResetPass) resetForceResetPassword();
    // if (resetForgotPassword && userResetPass) resetForgotPassword();
  }, [cancel]);

  return (
    <div className={setupMFA ? "mfaContainer" : "sfwToken_mfaContainer"}>
      <Form
        onFinish={
          setupMFA || mfaVerify
            ? (e) => handleMFAclick(verifyCode)
            : forceResetPass || userForgotPassword
            ? (e) =>
                resetPassword?.(
                  finalPassword,
                  userResetPass ? verifyCode : null,
                  userNameValue
                )
            : getUserName
            ? handleSendVerification
            : (e) => () => {}
        }
      >
        <div className="mfaLabel">
          <span className="mfaLabelText">{labelText}</span>
        </div>

        <div
          className={
            setupMFA
              ? "mfaContent"
              : mfaVerify || getUserName || forceResetPass || userResetPass
              ? "sfwToken_mfaContent"
              : undefined
          }
        >
          {errorMessage ? (
            <span className="mfa-error-message">{errorMessage}</span>
          ) : null}
          {setupMFA ? (
            <>
              <div className="mfaContentLeft">
                <div className="qrContainer">
                  <QRCodeSVG
                    size={250}
                    value={
                      authCode.auth
                        ? authCode.auth
                        : `otpauth://totp/AWSCognito:" + user.username + "?secret=" + res + "&issuer=Cognito`
                    }
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: '1em'
                  }}
                >
                  <span
                    style={{
                      marginTop: "20px",
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    Text Code
                  </span>
                  <span style={{ marginTop: "15px", color: "white" }}>
                    {authCode.text}
                  </span>
                </div>
              </div>

              <div className="mfaContentRight">
                <span className="mfaText">
                  Your organization requires you to setup two-factor
                  authentication. Scan the image on the left with a two-factor
                  authentication app on your mobile device and enter the
                  verification code.
                </span>
                <Input
                  ref={inputRef}
                  onChange={updateCode}
                  placeholder="Verification Code"
                ></Input>
              </div>
            </>
          ) : null}
          {mfaVerify ? (
            <>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.5em",
                  color: "white",
                }}
              >
                Enter Code
              </span>
              <span className="swfToken_mfaText">
                Please type in the code displayed from your mobile device
                authenticator app.
              </span>
              <Input
                onKeyPress={handleKeypress}
                ref={inputRef}
                onChange={updateCode}
                placeholder="Verification Code"
              ></Input>
            </>
          ) : null}
          {getUserName ? (
            <>
              <span className="swfToken_mfaText">Enter User Name</span>
              <Input
                onKeyPress={handleKeypress}
                ref={inputRef}
                onChange={updateUserName}
                placeholder="User name"
              ></Input>
            </>
          ) : null}
          {forceResetPass ? (
            <>
              <div className="swfToken_mfaText_pass_b">
                Your administrator requries you to reset your password.
              </div>
              <div>
                <span className="swfToken_mfaText_pass">
                  Enter new password.
                </span>
                <Input.Password
                  ref={inputRef}
                  type="password"
                  onChange={(e) => updateCurrentPass(e.target.value)}
                  id="password"
                  placeholder="Password"
                  name="password"
                />
              </div>
              <div>
                <span className="swfToken_mfaText_pass">
                  Confirm new password.
                </span>
                <Input.Password
                  type="password"
                  onChange={(e) => updateNewPass(e.target.value)}
                  id="password"
                  placeholder="Password"
                  name="password"
                />
              </div>
            </>
          ) : null}
          {userResetPass ? (
            <>
              <div>
                <span className="swfToken_mfaText_pass">
                  Enter the confirmation code sent to your email address below.
                </span>
                <Input
                  ref={inputRef}
                  onChange={updateCode}
                  id="code"
                  placeholder="Confirmation Code"
                />
              </div>
              <div>
                <span className="swfToken_mfaText_pass">
                  Enter new password.
                </span>
                <Input.Password
                  type="password"
                  onChange={(e) => updateCurrentPass(e.target.value)}
                  id="password"
                  placeholder="Password"
                  name="password"
                />
              </div>
              <div>
                <span className="swfToken_mfaText_pass">
                  Confirm new password.
                </span>
                <Input.Password
                  type="password"
                  onChange={(e) => updateNewPass(e.target.value)}
                  id="password"
                  placeholder="Password"
                  name="password"
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="mfaFooter">
          <div className="footerBtns">
            <Button onClick={(e) => cancel()} className="cancel-button">
              CANCEL
            </Button>
            {setupMFA || mfaVerify ? (
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                loading={loading}
                ref={btnRef}
                /*onClick={e => handleMFASubmit?.(verifyCode)}*/ className="login-button"
              >
                {setupMFA ? "CONTINUE" : "VERIFY"}
              </Button>
            ) : null}
            {forceResetPass || userForgotPassword ? (
              <Button
                type="primary"
                htmlType="submit"
                ref={btnRef}
                /*onClick={e => resetPassword?.(finalPassword, userResetPass ? verifyCode : null, userNameValue)}*/ className="login-button"
              >
                SUBMIT
              </Button>
            ) : null}
            {getUserName ? (
              <Button
                type="primary"
                htmlType="submit"
                ref={btnRef}
                /*onClick={handleSendVerification}*/ className="login-button"
              >
                SUBMIT
              </Button>
            ) : null}
          </div>
        </div>
      </Form>
    </div>
  );
};
