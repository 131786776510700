/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import {
  ArrowsAltOutlined,
  CheckOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
import { Button, Select, Slider, Spin, Switch, Table } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import "./Results.scss";
import dayjs from "dayjs";
import {
  ApplicantDetailsActionTypes,
  useApplicantDetailsContext,
} from "pages/ApplicationDetails/components/ApplicantDetailsContext/ApplicantDetailsProvider";
import CustomEditor from "./CustomEditor/CustomEditor";
import { mockrap } from "./CustomEditor/mockrap";
import useNotes from "./CustomEditor/useNotes";
import { disableInputForNonEditFinalDecision } from "utils/restrictionAccess";
import { useUserPermissions } from "components/UserPermissions/UserPermissionsContext";
import { useUserCognito } from "hooks/useUserCognito";

import { useAudit } from "../../../hooks/useAudit";
import { urls } from "urls";

const fingerprintQualityOptions = [
  { label: "", value: "" },
  { label: "No record found", value: "No record found" },
  { label: "Record found", value: "Record found" },
];

const text = mockrap;

const HitsSwitch: React.FC<any> = ({ rowData, setter }) => {
  const { controls } = rowData;
  const noHits = controls === "No Hits";
  const isClear = controls === "Clear";

  const handleChange = (checked: boolean) => {
    setter((currentTableData: any) => {
      return currentTableData.map((row: any) => {
        if (row.key === rowData.key)
          return { ...rowData, controls: checked ? "Clear" : "Not Clear" };
        else return row;
      });
    });
  };

  return noHits ? (
    <div className="no-hits-indicator" />
  ) : (
    <>
      {isClear ? <CheckOutlined style={{ fontSize: 11 }} /> : "! "}{" "}
      <Switch
        size="small"
        checked={isClear}
        onChange={handleChange}
        style={{ backgroundColor: isClear ? "#23BEB0" : "#F892A7" }}
      />
    </>
  );
};

const DUMMY_DATA: any = [
  {
    key: 1,
    agency: "STATE RAP",
    status: "Decision Complete",
    controls: "No Hits",
    time: Date.now(),
  },
  {
    key: 2,
    agency: "FBI AFIS",
    status: "Timed Out",
    controls: "Not Clear",
    time: Date.now(),
  },
  {
    key: 3,
    agency: "KSP AFIS",
    status: "Decision Complete",
    controls: "Clear",
    time: Date.now(),
  },
];

const ExpandedRow = ({ record }: any) => {
  return (
    <div className="expanded-row">
      <div className="container-header">{`MORE (${record.agency})`}</div>
      <div className="container-body">**more content**</div>
    </div>
  );
};

const marks = {
  0: " ",
  50: " ",
  100: " ",
};

const sliderLabelsMap = {
  0: "Original",
  50: "Both",
  100: "Redacted",
};

const formatter = (value: 0 | 50 | 100) => <>{sliderLabelsMap[value]}</>;

interface ResultsProps {
  userRole: string;
  results: any;
  aggregatedResponses: Array<string>;
  rapSheets: string[];
  transactionalInfo: any;
  resultsResponses: Object[];
}

const Results: React.FC<ResultsProps> = ({
  userRole,
  results,
  aggregatedResponses,
  rapSheets,
  transactionalInfo,
  resultsResponses
}) => {
  const { permissions } = useUserPermissions();
  const { usersPermissions } = useUserCognito();
  console.log("debugging permissions", permissions);
  console.log("debugging usersPermissions: ", usersPermissions);
  
  useEffect(() => {
    console.log(
      "debugging useEffect usersPermissions updated: ",
      usersPermissions
    );
  }, [usersPermissions]);
  //const uuid = results[0].uuid;

  console.log("update decision results: ", results);

  let originalText: string = "";
  // aggregatedResponses.forEach((str, index) =>
  //   index === 0 ? (originalText = str) : (originalText = originalText + str)
  // );
  if(rapSheets && rapSheets.length > 0) {
    rapSheets.forEach((str: string, index: number) =>
      index === 0
        ? (originalText = str)
        : (originalText =
            `
      ${
        originalText +
        `\n \n <strong>End of sheet ${index}</strong> \n ----------------------------------------------------------------------------------- \n `
      }` + `${str}`)
    );
  }
  

  const { notesJSON, fetchStatus } = useNotes("redact");
  const redactedText = notesJSON?.[0]?.body?.note;

  const [activeEditor, setActiveEditor] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const enterFullScreen = () => {
    setIsFullScreen(true);
    document.documentElement.requestFullscreen();
  };
  const exitFullScreen = () => {
    setIsFullScreen(false);
    document.exitFullscreen();
  };
  const toggleFullScreen = () =>
    isFullScreen ? exitFullScreen() : enterFullScreen();
  const [tableData, setTableData] = useState<Array<Object>>(results);
  const [manualAdjudication, setManualAdjudication] = useState<boolean>(false);

  const [resultRespData, setResultRespData] = useState<Array<Object>>(resultsResponses)

  useEffect(() => {
    if(resultsResponses.length !== 0) {
      const sortResultRespData = resultsResponses.sort((a:any, b: any) => {
        if (a.type1Field7.toLowerCase() === 'state') return -1;
        if (b.type1Field7.toLowerCase() === 'state') return 1;
        return 0
      })
      console.log('sortResulst', sortResultRespData)
      setResultRespData(sortResultRespData)
    }
  }, [resultsResponses])

  useEffect(() => {
    const sortTableData = results?.sort((a:any, b: any) => {
      if (a.agency.toLowerCase() === 'state') return -1;
      if (b.agency.toLowerCase() === 'state') return 1;
      return 0
    })
    setTableData(sortTableData);
    if (
      results?.some(
        (statuses: { status: string }) =>
          statuses.status === "Manual Adjudication"
      )
    )
      setManualAdjudication(true);
  }, [results, manualAdjudication, userRole]);

  const { applicantDetailsDispatch } = useApplicantDetailsContext();
  const { addAuditEvent } = useAudit();

  const [hasRedaction, setHasRedaction] = useState(false);
  useLayoutEffect(() => {
    setHasRedaction(!!notesJSON.length);
    notesJSON.length && setActiveEditor(100);
  }, [notesJSON.length]);

  console.log("tableData", tableData);

  const columns = [
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text: any) => {
        console.log("debug results: text: ", text);
        return dayjs(text).format("MM/DD/YYYY HH:mm:ss");
      },
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    /*       {
        title: 'Controls',
        dataIndex: 'controls',
        render: (_text: any, record: any) => {
         return <HitsSwitch rowData={record} setter={setTableData}/>;
        }
      },
      Table.EXPAND_COLUMN */
  ];
  const handleResponseType =(type:string)=>{
    switch(type){
      case 'y':
        return 'Hit';
      case 'n':
        return 'No hit';
      case 'errt':
        return 'ERRT-Error';
    }
  }

  const resultsColumns = [
    {
      title: "AFIS",
      dataIndex: "type1Field7",
      key: "type1Field7",
    },
    {
      title: "Response",
      dataIndex: "type2Field59",
      render: (text: any) => {
        return handleResponseType(text.toLowerCase())
      },
      key: "type2Field59",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text: any) => {
        console.log("debug results: text: ", text);
        return dayjs(text).format("MM/DD/YYYY");
      },
      key: "date",
    },
    
    /*       {
        title: 'Controls',
        dataIndex: 'controls',
        render: (_text: any, record: any) => {
         return <HitsSwitch rowData={record} setter={setTableData}/>;
        }
      },
      Table.EXPAND_COLUMN */
  ];

  const isRedacted =
    hasRedaction && (activeEditor === 100 || activeEditor === 50);
  const isOriginal = activeEditor === 0 || activeEditor === 50;

  const isLoading = fetchStatus === "loading" || fetchStatus === "idle";

  if (isLoading) {
    return (
      <div className="result-temp-container">
        <div className="loading-container">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  const updateDecision = async (e: any) => {
    console.log("update decision ran or e: ", e);
    applicantDetailsDispatch({
      type: ApplicantDetailsActionTypes.UPDATE_DECISION,
      payload: { decision: e },
    });
    await addAuditEvent(
      `${ApplicantDetailsActionTypes.UPDATE_DECISION}`,
      `Decision: ${e}`,
      new Date(),
      undefined
    );
  };

  return (
    <>
      {console.log("results: ", results)}
      {isFullScreen && <div className="backdrop" />}
      <div className={`results-container ${isFullScreen ? "fullscreen" : ""}`}>
        <div>
          <div className="container-header">OPTIONS</div>
          <div className="results-controls">
            <div className="column">
              {usersPermissions.allowManualAdjudication && (
                <>
                  <label>Final Decision</label>
                  <Select
                    onChange={(e) => updateDecision(e)}
                    options={fingerprintQualityOptions}
                    defaultValue={fingerprintQualityOptions[0].value}
                    size="small"
                    disabled={disableInputForNonEditFinalDecision(
                      userRole,
                      manualAdjudication
                    )}
                  />
                </>
              )}
            </div>

            <Table
              columns={columns}
              dataSource={tableData}
              pagination={false}
              size="small"
              scroll={{ y: isFullScreen ? "60vh" : "40vh" }}
              // expandable={{
              //   expandedRowRender: record => <ExpandedRow record={record} />,
              //   columnTitle: 'More',
              //   expandIcon: ({ expanded, onExpand, record }) => expanded ?
              //   <UpOutlined onClick={e => onExpand(record, e)} style={{fontSize: 20}} className="expanded-icon"/> :
              //   <DownOutlined onClick={e => onExpand(record, e)} style={{fontSize: 20}} />,
              //   columnWidth: 60,
              //   expandedRowClassName: () => 'results-table-expanded-row',
              //   expandedRowKeys: expandedRowKeys,
              //   onExpand: onTableRowExpand
              // }}
            />
            <Table
              columns={resultsColumns}
              dataSource={resultRespData}
              pagination={false}
              size="small"
              scroll={{ y: isFullScreen ? "60vh" : "40vh" }}
              // expandable={{
              //   expandedRowRender: record => <ExpandedRow record={record} />,
              //   columnTitle: 'More',
              //   expandIcon: ({ expanded, onExpand, record }) => expanded ?
              //   <UpOutlined onClick={e => onExpand(record, e)} style={{fontSize: 20}} className="expanded-icon"/> :
              //   <DownOutlined onClick={e => onExpand(record, e)} style={{fontSize: 20}} />,
              //   columnWidth: 60,
              //   expandedRowClassName: () => 'results-table-expanded-row',
              //   expandedRowKeys: expandedRowKeys,
              //   onExpand: onTableRowExpand
              // }}
            />

            <div className="slider">
              {hasRedaction ? (
                <div className="slider-container">
                  {/* <EyeOutlined className="slider-container__eye-icon" /> */}
                  <Slider
                    marks={marks}
                    step={null}
                    defaultValue={2}
                    // @ts-ignore
                    tooltip={{ formatter }}
                    style={{ width: "100%", margin: 0, height: 10 }}
                    onChange={(value) => {
                      setActiveEditor(value);
                    }}
                    value={activeEditor}
                  />
                  {/* <EyeInvisibleOutlined className="slider-container__eye-icon" /> */}
                </div>
              ) : (
                <Button
                  className="button"
                  type="primary"
                  onClick={() => {
                    setHasRedaction(true);
                    setActiveEditor(100);
                  }}
                  disabled={true}
                >
                  Create Redaction
                </Button>  
              )}
            </div>
          </div>
        </div>

        <div className="editors-container">
          <div className="fullscreen-button" onClick={toggleFullScreen}>
            {isFullScreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
          </div>

          {/* REDACTED */}
          <div
            className="editors-container__editor"
            style={{ display: isRedacted ? "block" : "none" }}
          >
            <div className="container-header">
              <EyeInvisibleOutlined style={{ fontSize: 20 }} /> REDACTED
            </div>
            <CustomEditor
              isEditable={usersPermissions.editRedactedResults}
              originalText={redactedText || originalText}
              redactedJSON={notesJSON}
              tcn={transactionalInfo.transactionNumber}
              isVisible={usersPermissions.viewRedactedResults}
            />
          </div>
          {/* ORIGINAL */}
          <div
            className="editors-container__editor"
            style={{ display: isOriginal ? "block" : "none" }}
          >
            <div
              className="container-header"
              style={{ backgroundColor: "gray" }}
            >
              <EyeOutlined style={{ fontSize: 20 }} /> ORIGINAL
            </div>
            <CustomEditor
              canPrint={usersPermissions.printOriginalResults}
              isEditable={false}
              originalText={originalText}
              tcn={transactionalInfo.transactionNumber}
              isVisible={usersPermissions.viewOriginalResults}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Results;
