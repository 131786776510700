/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Tag } from "antd";
import { SkeletonLoader, TextField } from "common";
import "./ApplicantDetailsForm.scss";
import { ApplicantsDetailsStatusHistory } from "../ApplicantsDetailsStatusHistory/ApplicantsDetailsStatusHistory";
import AdjudicationDocuments from "pages/ApplicationDetails/components/AdjudicationDocuments/AdjudicationDocuments";
import { ApplicantFetchStatus } from "hooks/shared/types/";
import {
  disableInputForNonAdmin,
  hideFieldsForRestrictedView,
} from "utils/restrictionAccess";
import {
  getEyeColor,
  getGender,
  getHairColor,
  getPOB,
  getRace,
} from "utils/getPersonAttributes";
import { formatDate } from "utils/formatDate";
import { addSpaceForComas } from "utils/addSpaceForText";
import { urls } from "urls";
import { useParams } from "react-router-dom";
import { useConfig } from "hooks/useConfig";
// import Input from "rc-input";
import dayjs from "dayjs";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { FormattedSSN } from 'utils/formatSSN';

		

interface ApplicantDetailsFormProps {
  userRole: string;
  personalInfo: any;
  transactionalInfo: any;
  applicantFetchStatus: ApplicantFetchStatus;
  statusHistories: Array<Object>;
}

const LabeledContainer = ({ children, title, className }: any) => {
  return (
    <div>
      <div className="applicantsDetailsForm-title container-header">
        <p style={{ margin: 0 }}>{title}</p>
      </div>
      <div className={className}>{children}</div>
    </div>
  );
};

export const ApplicantDetailsForm: React.FC<ApplicantDetailsFormProps> = ({
  userRole,
  personalInfo,
  transactionalInfo,
  applicantFetchStatus,
  statusHistories,
}) => {
  //const [isFormSaving, setIsFormSaving] = useState(false);
  //const [isEditMode, setIsEditMode] = useState(false);
  const isLoadingApplicant = applicantFetchStatus === "loading";
  const [openModal, setOpenModal] = useState(false);
  const { registrationId } = useParams();
  const [ssnValues, setSsnValues] = useState({
    SSN: personalInfo.ssn,
    showSSN: false
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleResume = () => {
    setOpenModal(false);
  };

  const [emails, setEmails] = useState<string[]>([]);
  const TagsInput = () => {
    const handleAddEmail = (event: any) => {
      if (event.key === "Enter" && event.target.value !== "") {
        event.preventDefault();
        setEmails([...emails, event.target.value]);
        event.target.value = "";
      }
    };

    const handleRemoveEmail = (i: any) => {
      setEmails([...emails.filter((email) => emails.indexOf(email) !== i)]);
    };

    const handleEnter = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleAddEmail(event);
      } else return null;
    };

    return (
      <div className="tags-input">
        <ul id="tags">
          {emails.map((email, index) => (
            <Tag
              closable
              onClose={(e) => {
                e.preventDefault();
                handleRemoveEmail(index);
              }}
            >
              {email}
            </Tag>
          ))}
        </ul>
        <TextField
          type="text"
          onKeyPress={handleEnter}
          placeholder="Press enter to add emails"
        />
      </div>
    );
  };

  useEffect(() => {
    console.log("emails", emails);
  }, [emails]);

  const { config } = useConfig();

  const handleResendEmail = async (event: any) => {
    // const reporting_url = urls[env].REPORTING;
    const endpoint = `${
      process.env.NODE_ENV === "production" ? urls.REPORTING : ""
    }/adjudication/resend-email/${registrationId}`;

    const body = { emails: emails };

    const options: RequestInit = {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(body),
    };

    console.log("options", JSON.stringify(options));

    await fetch(endpoint, options)
      .then((response: Response) => {
        console.log("resend email decision success", response);
      })
      .catch((error: Error) => {
        console.error("resend email decision error", error);
      });

    alert(
      "Documents have been sent. If you have not received an email, please check the address provided"
    );
  };

  //console.log('height: ', personalInfo.height.replace('"', "'"))

  /*const startEditMode = () => {
    setIsEditMode(true);
  };
  const handleUpdateFields = () => {
    setIsFormSaving(true);
    // Saving fields
    setIsFormSaving(false);
    setIsEditMode(false);
  };*/
  /*const handleChangeBirthDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    console.log(date, dateString);
  };*/
  const [form] = Form.useForm();
  console.log("debug transactionalInfo: ", transactionalInfo);

  const handleClickShowSSN = () => {
    setSsnValues({...ssnValues, showSSN: !ssnValues.showSSN})
  }
  
  return (
    <div className={"applicantsDetailsForm"}>
      <div className="applicantsDetailsForm-container">
        <Form form={form} layout="vertical" className="form-contianer">
          <LabeledContainer
            className={"applicantsDetailsForm_personal-info labeled-column"}
            title="PERSONAL INFORMATION"
          >
            <SkeletonLoader loading={isLoadingApplicant}>
              <TextField
                label="First Name"
                placeholder=""
                value={personalInfo.firstName}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Middle Name"
                placeholder=""
                value={personalInfo.middleName ?? ""}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Last Name"
                placeholder=""
                value={personalInfo.lastName}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Suffix"
                placeholder=""
                value={personalInfo.suffix ?? "N/A"}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Residence Address"
                placeholder=""
                className="col-span-2"
                value={
                  hideFieldsForRestrictedView(userRole)
                    ? "*******************"
                    : addSpaceForComas(personalInfo.residenceAddress)
                }
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="SSN"
                placeholder=""
                value={
                  hideFieldsForRestrictedView(userRole)
                    ? "***-**-****"
                    : !ssnValues.showSSN 
                      ? !personalInfo.ssn ? 'N/A' : '***-**-****' 
                      : FormattedSSN(personalInfo.ssn)
                }
                disabled={disableInputForNonAdmin(userRole)}
                suffix={
                  ! personalInfo.ssn ? undefined :
                  !ssnValues.showSSN ? 
                  <EyeInvisibleOutlined onClick={handleClickShowSSN}/> 
                  : 
                  <EyeOutlined  onClick={handleClickShowSSN}/>}
                
              />
              <TextField
                label="Date of Birth"
                value={
                  hideFieldsForRestrictedView(userRole)
                    ? "****-**-**"
                    : personalInfo.birthDate
                }
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Place Of Birth"
                placeholder=""
                value={getPOB(personalInfo.placeOfBirth)}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Country of Citizenship"
                placeholder=""
                value={personalInfo.countryOfCitizenship}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Race"
                placeholder=""
                value={getRace(personalInfo.race)}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Gender"
                placeholder=""
                value={getGender(personalInfo.gender)}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Height"
                placeholder=""
                value={personalInfo.height}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Weight"
                placeholder=""
                value={personalInfo.weight}
                suffix="Lbs"
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Hair Color"
                placeholder=""
                value={getHairColor(personalInfo.hairColor)}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Eye Color"
                placeholder=""
                value={getEyeColor(personalInfo.eyeColor)}
                disabled={disableInputForNonAdmin(userRole)}
              />
            </SkeletonLoader>
          </LabeledContainer>
          <LabeledContainer
            className={
              "applicantsDetailsForm_transactional-information labeled-column"
            }
            title="TRANSACTIONAL INFORMATION"
          >
            <SkeletonLoader loading={isLoadingApplicant}>
              <TextField
                label="UEID"
                placeholder=""
                value={transactionalInfo.UEID}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Current Status"
                placeholder=""
                value={transactionalInfo.currentStatus}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="ORI#"
                placeholder=""
                value={transactionalInfo.ORI}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Incoming TCN"
                placeholder=""
                value={transactionalInfo.incomingTCN}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Transaction Number"
                placeholder=""
                value={transactionalInfo.transactionNumber}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Transaction Type"
                placeholder=""
                value={transactionalInfo.transactionType}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Assigned Agent"
                placeholder=""
                value={transactionalInfo.assignedAgent}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Last Modified By"
                placeholder=""
                value={dayjs(transactionalInfo?.lastModifiedBy).format(
                  "MM/DD/YYYY"
                )} //modifiedBy or modifiedDate?
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Status Timeout"
                placeholder=""
                value={transactionalInfo.statusTimeout}
                disabled={disableInputForNonAdmin(userRole)}
              />
              <TextField
                label="Transaction Time Out"
                placeholder=""
                value={transactionalInfo.transactionTimeout}
                disabled={disableInputForNonAdmin(userRole)}
              />
               <TextField
                label="Reason Fingerprinted "
                placeholder=""
                value={transactionalInfo.reasonFingerprinted}
                disabled={disableInputForNonAdmin(userRole)}
              />
            </SkeletonLoader>
          </LabeledContainer>
        </Form>
        <LabeledContainer
          className={"applicantsDetailsForm_status-history labeled-column"}
          title="STATUS HISTORY"
        >
          <ApplicantsDetailsStatusHistory
            dataSource={statusHistories}
            pagination={false}
            loading={isLoadingApplicant}
          />
        </LabeledContainer>
        <LabeledContainer
          className={
            "applicantsDetailsForm_adjudication-documents labeled-column"
          }
          title="ADJUDICATION DOCUMENTS"
        >
          <AdjudicationDocuments
            status={transactionalInfo?.currentStatus}
            addedDate={transactionalInfo?.lastModifiedBy}
            tcn={transactionalInfo.transactionNumber}
          />
          {/* <Button onClick={handleOpenModal}>Send Email</Button> */}
          <div className="modal-content">
            <Modal
              title={"Send Adjudication Documents"}
              open={openModal}
              onOk={handleResume}
              okText={"Ok"}
              cancelText={"Cancel"}
              onCancel={handleResume}
              width={"50vw"}
              centered
              closable={false}
              okButtonProps={{ className: "button" }}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              <div className="warning-body">
                Send adjudication documents to the following email address:
                <Form onFinish={handleResendEmail}>
                  <Form.Item>
                    <TagsInput />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit">Submit</Button>
                  </Form.Item>
                </Form>
              </div>
            </Modal>
          </div>
        </LabeledContainer>
      </div>
    </div>
  );
};