/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Navigate, useOutlet } from 'react-router-dom'
import { Navbar } from 'components/Navbar/Navbar'
import { useCookies } from "react-cookie";
import { useAuth } from "../../hooks/useAuth";



export const ProtectedLayout = () => {
    const outlet = useOutlet();
    let loggedIn = localStorage.getItem('loggedIn')

    if (!loggedIn) {
        return <Navigate to="/" />
    }

    return (
        <>
            <Navbar />
            {outlet}
        </>

    )
}