/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { createContext, useContext, useMemo, useReducer, useEffect} from "react";
import { useAudit } from '../../../../hooks/useAudit'

interface ApplicantDetailsData {
    hasChanges: boolean;
    decision: string | null;
    currentTab: string;
}

interface ApplicantDetailsContextProps {
    applicantDetailsData: ApplicantDetailsData;
    applicantDetailsDispatch: (data: any) => void;
}

export enum ApplicantDetailsActionTypes {
    UPDATE_DECISION = "UPDATE_DECISION",
    UPDATE_TAB = "UPDATE_TAB",
    REMOVE_CHANGES = "REMOVE_CHANGES"
}

const initialState = {
  hasChanges: false,
  decision: null,
  currentTab: "DETAILS"
};

const ApplicantDetailsContext = createContext<ApplicantDetailsContextProps>({
    applicantDetailsData: initialState,
    applicantDetailsDispatch: () => {}
});

export const useApplicantDetailsContext = () => {
if (!ApplicantDetailsContext) {
    throw new Error("useApplicantDetailsContext must be used within ApplicantDetailsProvider");
}
return useContext(ApplicantDetailsContext);
}


const applicantDetailsReducer = (currentState: any, {type, payload}: any) => {
    switch (type) {
        case ApplicantDetailsActionTypes.UPDATE_DECISION:
          const { decision } = payload;
          return {
            ...currentState,
            hasChanges: true,
            decision,
            action: ApplicantDetailsActionTypes.UPDATE_DECISION
          };
        case ApplicantDetailsActionTypes.UPDATE_TAB:
          const { currentTab } = payload;
          return {
            ...currentState,
            currentTab,
            action: ApplicantDetailsActionTypes.UPDATE_TAB
          };
        case ApplicantDetailsActionTypes.REMOVE_CHANGES:
          return {
            ...currentState,
            hasChanges: false,
            action: ApplicantDetailsActionTypes.UPDATE_TAB
          };
        default: throw new Error(`Invalid action type ${type}`);
      }
}


export const ApplicantDetailsProvider: React.FC<any> = ({ children }) => {

    const [applicantDetails, applicantDetailsDispatch] = useReducer(applicantDetailsReducer, initialState);
    const { addAuditEvent } = useAudit()
    const { hasChanges, decision, currentTab, action } = applicantDetails;

    /*useEffect(() => {
      if(action === ApplicantDetailsActionTypes.UPDATE_DECISION) addAuditEvent(action, decision, new Date(), undefined)
    }, [action, addAuditEvent, decision])*/

    const memodApplicantDetails = useMemo(() => ({hasChanges, decision, currentTab}), [hasChanges, decision, currentTab]);
    
     const value = useMemo(() => ({ applicantDetailsData: memodApplicantDetails, applicantDetailsDispatch }), [memodApplicantDetails, applicantDetailsDispatch]);

    return (
        <ApplicantDetailsContext.Provider value={value}>
            {children}
        </ApplicantDetailsContext.Provider>
    )
}