/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Button, List, Modal } from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import "./NotesList.scss";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import printText from "utils/printText";
import NotesService from "utils/notesService";
import { useParams } from "react-router-dom";
import { disableInputForNonEdit } from "utils/restrictionAccess";
import { EditView } from "components/EditView/EditView";
import { useAudit } from '../../../hooks/useAudit'
import { Auth } from 'aws-amplify';
import { useConfig } from '../../../hooks/useConfig'
import { urls } from "urls";


interface NotesListProps {
  personalInfo: any
  userRole: string
  notesState: any;
  selectedNoteIdState: any;
  blankNoteState: any;
  createTempNote: any;
}

const addElipsis = (text: string, limit: number) => {
  if (text.length > limit) return text + "...";
  return text;
};

const getTitle = (untrimmedText: string) => {
  if (!untrimmedText) return "Blank Note";
  const text = untrimmedText.trim();
  const LIMIT = 30;

  const periodIndex = Math.min((text.indexOf("\n") === -1 ? Infinity : text.indexOf("\n")), (text.indexOf(".") === -1 ? Infinity : text.indexOf(".")));
  const index = periodIndex > -1 && periodIndex < LIMIT ? periodIndex : LIMIT;
  const firstSentence = text.substring(0, index);
  const finalText = firstSentence.length > 0 ? firstSentence : text;
  return addElipsis(finalText, LIMIT - 1);
};

const convertName = (name: string) => {
    if (!name) return "";
  const parts = name.split(".");
  if (parts.length !== 2) return name;
  return `${parts[1].charAt(0).toUpperCase()}${parts[1].slice(1)}, ${parts[0]
    .charAt(0)
    .toUpperCase()}${parts[0].slice(1)}`;
};

export const NotesList: React.FC<NotesListProps> = ({
  personalInfo,
  userRole,
  notesState,
  selectedNoteIdState,
  blankNoteState,
  createTempNote,
}) => {
  const noteAuthorName = `${personalInfo.firstName} ${personalInfo.middleName} ${personalInfo.lastName}`
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const { registrationId = "" } = useParams<{ registrationId: string }>();
  const { config } = useConfig()
  const url = urls.NOTES
  console.log('notesService url: ', url)
  let notesService: any;

  const { notes, setNotes } = notesState;
    const { hasBlankNote, setHasBlankNote } = blankNoteState;

  const { selectedNoteId, handleSelectedNoteChange } = selectedNoteIdState;

  const selectedNote = notes.find((note: any) =>
    note.temporaryId ? note.temporaryId === selectedNoteId : note.id === selectedNoteId
  );

  const { addAuditEvent } = useAudit()

  useEffect(() => {
    notesService = new NotesService(registrationId, 'note', url);
  }, [url])


  const confirmDeleteNote = async (id: string) => {
    const { username } = await Auth.currentAuthenticatedUser()
    setNotes((currentNotes: any) =>
      currentNotes.filter((note: any) =>
        note.temporaryId ? note.temporaryId !== selectedNoteId : note.id !== selectedNoteId
      )
    );
    setConfirmationModalOpen(false);
    handleSelectedNoteChange(null);
    if (id === selectedNote.temporaryId) {
        setHasBlankNote(false);
        return;
    };
    notesService.deleteNote(id).then(() => {
      addAuditEvent("Note Deleted", `${username} created note for registration id: ${registrationId} at ${new Date()}`, new Date(), undefined,)

    });
  };

  const printNote = (note: any) => {
    printText(
      selectedNote?.body.note, 
      noteAuthorName,
      `${convertName(note.creator)} - ${dayjs(note.datelastmodified).format(
        "MM/DD/YYYY hh:mm A"
      )}`)
    addAuditEvent("Note Printed", `Printed note for registration id: ${registrationId} at ${new Date()}`, new Date(), undefined,)
  };

  console.log('debug notes notesList userRole: ', userRole)
  console.log(`debug notes notesList hasblankNote: ${hasBlankNote} || disableInputForNonEdit(${userRole}): `, hasBlankNote || disableInputForNonEdit(userRole))

  return (
    <>
      <Modal
        title="Are you sure?"
        okText={"Delete"}
        onOk={() => confirmDeleteNote(selectedNoteId)}
        centered
        open={confirmationModalOpen}
        onCancel={() => setConfirmationModalOpen(false)}
        maskClosable
        closable={false}
        okButtonProps={{ className: "button" }}
        cancelButtonProps={{ className: "button-outline" }}
        children="Are you sure you would like to delete this note? Select Delete to continue or Cancel to disregard."
      />
      <div className="notes-list">
        <List
          rowKey={(note: any) => note.temporaryId || note.id}
          header={
            <div className="note-list-header">
              <span className="notesHeader">NOTES</span>
              <span className={hasBlankNote ? 'disabled-wrapper' : ''}>
                <div
                  className={`new-note-button ${hasBlankNote || disableInputForNonEdit(userRole) ? "new-note-button--disabled" : ""}`}
                  onClick={createTempNote}
                >
                  <PlusOutlined style={{ fontSize: "1.5em", color: "white" }} />
                  <span className="headerText">NEW NOTE</span>
                </div>
              </span>
            </div>
          }
          itemLayout="horizontal"
          dataSource={notes}
          renderItem={(note: any) => (
            <List.Item
              className={`note ${
                (note.temporaryId ? note.temporaryId === selectedNoteId : note.id === selectedNoteId)
                  ? "note--selected"
                  : ""
              }`}
              onClick={() => handleSelectedNoteChange(note.temporaryId || note.id)}
            >
              <List.Item.Meta
                title={getTitle(note.body.note)}
                description={`${convertName(note.creator)} - ${dayjs(note.datelastmodified).format(
                  "MM/DD/YYYY hh:mm A"
                )}`}
              />
              <div className="note__button-container">
                {selectedNote?.body.note && <Button
                  title="Print Note"
                  type="primary"
                  className="editor-overlay__button"
                  onClick={() => printNote(note) }
                >
                  <EditView>
                    <PrinterOutlined style={{ fontSize: 20 }} />
                  </EditView>
                  Print
                </Button>}
{/*                 <Button
                  title="Delete Note"
                  type="primary"
                  className="editor-overlay__button"
                  onClick={() => setConfirmationModalOpen(true)}
                >
                  <EditView>
                    <DeleteOutlined style={{ fontSize: 20 }} />
                  </EditView>
                </Button> */}
              </div>
            </List.Item>
          )}
        />

      </div>
    </>
  );
};
