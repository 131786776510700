/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { FileSearchOutlined } from "@ant-design/icons";
import { FaRegBuilding } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useUserRole } from "../../../hooks/useUserRole";
import "../Navbar.scss";
import { useUserPermissions } from "components/UserPermissions/UserPermissionsContext";
import { useUserCognito } from "hooks/useUserCognito";

const NavLinks = () => {
    const {pathname} = useLocation();
    const [, , pageName] = pathname.split('/');
    const {userRole} = useUserRole()
    const { usersPermissions } = useUserCognito()
    console.log('httpOnly usersPermissions userRole: ', userRole)
    console.log('httpOnly NavLinks userRole === "mc_admin": ', userRole === "mc_admin")
    console.log('httpOnly usersPermissions: ', usersPermissions)
    return (
        <>
          <span>
            <Link className={`nav-item ${pageName === "transaction-summary" ? "nav-item--active" : '' }`} to="transaction-summary"><FileSearchOutlined /> Transaction Summary</Link>
          </span>
          <span>
            {
              usersPermissions.viewAgencies ? ( <Link className={`nav-item ${pageName === "agency-management" ? "nav-item--active" : '' }`} to="agency-management"><FaRegBuilding/> Agency Management</Link>
              ) : null
            }
          </span>
          <span>
            {
              usersPermissions.viewRapBackDetails
                ? ( <Link className={`nav-item ${pageName === "rapback-summary" ? "nav-item--active" : '' }`} to="rapback-summary"><FileSearchOutlined /> RapBack Summary</Link>) 
                : null 
            }
          </span>

        </>
    );
};

export default NavLinks;
