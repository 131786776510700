/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { FC, useCallback, useEffect, useState } from "react";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import "./IdleTracker.scss";
import { useKeyPressEvent } from "react-use";
import { TimeoutWarning } from "./timeout-warning";
import { ExpiredNotice } from "./expired-notice";
import React from "react";
import { Modal } from "antd";
import { useCookies } from "react-cookie";
import { useAudit } from "../../hooks/useAudit";
import { Auth } from "aws-amplify";
import { useAuth } from "../../hooks/useAuth";
import { getDomainName } from "../../utils/getDomainName";
import { useLocalStorage } from "common";
import { urls } from '../../urls'



interface IdleTrackerProps {
  idleTimeout: number;
  promptTimeout: number;
}

export const  IdleTracker: FC<IdleTrackerProps> = ({
  idleTimeout,
  promptTimeout,
}) => {
  const [timeoutWarningVisible, setTimeoutWarningVisible] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [open, setOpen] = useState(false);
  const [cookies, , removeCookie] = useCookies(["token"]);
  const { addAuditEvent } = useAudit();
  const { logout } = useAuth()
  const [, , loggedIn] = useLocalStorage()
  const authUrl = urls.AUTH;

  const clearStorageRefresh = () => {
    if(process.env.NODE_ENV != 'production') {
      removeCookie("token", { path: "/", domain: getDomainName() });
    } 
    localStorage.clear()
    //navigate("/", { replace: true });
    window.location.reload()
  }

  const logoutTokenHandler = async () => {
    let body = {
      action: 'CLEAR_TOKEN',
    }
    const resp = await fetch(`${authUrl}/auth/token-handler`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: "include"
    })
    console.log('httpOnly resp: ', resp) 
    if(resp.ok) {
      clearStorageRefresh()
    } else {
        console.log('Error logging out')
    }
  }

  const handleLogout = useCallback(async () => {
    setOpen(false);
    try {
      const { username } = await Auth.currentAuthenticatedUser();
   
      await addAuditEvent(
        "Logout",
        `${username} logged out of CARES dashboard at ${new Date()}`,
        new Date(),
        undefined
      );

    } catch (e: any) {
        console.log('Error logging out: ', e)
        clearStorageRefresh()
    }
    
    if(process.env.NODE_ENV != 'production') {
      clearStorageRefresh()
    } else {
      logoutTokenHandler()
    }
  }, [logout]);

  useEffect(() => {
    console.log("cookies", cookies);
  }, [cookies]);

  const handlePrompt = () => {
    console.log('show idle timer')
    if (loggedIn) {
      setTimeoutWarningVisible(true);
      setRemaining(promptTimeout);
      setOpen(true);
    }
  };
  const handleIdle = () => {
    if (loggedIn) {
      setIsExpired(true);
      setOpen(true);
      setRemaining(0);
      //handleLogout();
    }
  };
  const handleActive = () => {
    setTimeoutWarningVisible(false);
    setRemaining(0);
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { activate, getRemainingTime, isPrompted } = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout,
    onPrompt: handlePrompt,
    onIdle: handleIdle,
    onActive: handleActive,
    timers: workerTimers,
    crossTab: true,
    stopOnIdle: true,
    leaderElection: true, 
    syncTimers: 200
  });

  const handleResume = () => {
    setTimeoutWarningVisible(false);
    activate();
    setOpen(false);
  };

  useKeyPressEvent(
    "Escape",
    () => timeoutWarningVisible && !isExpired && handleResume()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime()));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  useEffect(() => {
    if (loggedIn) activate();
  }, [activate, loggedIn]);

  if (!timeoutWarningVisible) {
    return null;
  }

  if (!loggedIn) return null;
  return (
    //@ts-ignore

    <div className="modal-content">
      {isExpired ? (
        <Modal
          title={"Session Expired"}
          open={open}
          onOk={handleLogout}
          okText={"Close"}
          cancelText={"cancel"}
          onCancel={handleLogout}
          width={"50vw"}
          centered
          closable={false}
          okButtonProps={{ className: "button" }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <ExpiredNotice />
        </Modal>
      ) : (
        <Modal
          title={"Timeout Warning"}
          open={open}
          onOk={handleResume}
          okText={"Ok"}
          cancelText={"Cancel"}
          onCancel={handleResume}
          width={"50vw"}
          centered
          closable={false}
          okButtonProps={{ className: "button" }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <TimeoutWarning timeLeft={remaining} />
        </Modal>
      )}
    </div>
  );
};

IdleTracker.defaultProps = {
  idleTimeout: 5 * 60_000, // 5 mins
  promptTimeout: 5 * 60_000, // 5 mins
};
