import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { Button, Input, Select, Table, DatePicker, Modal } from "antd";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { ColumnsVisibilityButton } from './components';
import { ALL_COLUMNS, ColumnName, FilterMode, TableFilters, TableScroll,stateSpecficDefaultColumns, smallerColumns } from './TransactionSummaryTableData';
import { getDropdownOptions, camelToTitleCase} from './functions';
import { useQueryTransactions, useAuth } from "hooks";
import { useNavigate, Link } from "react-router-dom";
import { SortOrder } from "antd/lib/table/interface";
import { debounce } from "debounce";
import { disabledDate } from "components/DateRangePicker/DateRangePicker";
import { useUserCognito } from "hooks/useUserCognito";
import { useAudit } from "hooks/useAudit";


interface TransactionSummaryTableProps {
  tableFilters: any
  setTableFilters: any
  userORIs: any
  userRole: any,
  userSecondaryORIs: any,
  usersPermissions: any
}

 const TransactionSummaryTable = ({ tableFilters, setTableFilters, userORIs, userRole,userSecondaryORIs, usersPermissions}: TransactionSummaryTableProps) => { 
  const { RangePicker } = DatePicker;
  const handleFilteringRef = useRef<any>(null);
  const viewRef = useRef<any>("")
  const firstNameRef = useRef<any>("")
  const middleNameRef = useRef<any>("")
  const lastNameRef = useRef<any>("")
  const statusRef = useRef<any>("")
  const transactionDateRef = useRef<any>("")
  const transactionNumberRef = useRef<any>("") 
  const transactionTypeRef = useRef<any>("")
  const agencyRef = useRef<any>("")
  const reasonFingerPrintedRef = useRef<any>("")
  const lockedRef = useRef<any>("")
  const userRef = useRef<any>("")
  const agencyIdentifierRef = useRef<any>("")
  const [refs, setRefs] = useState<any>({firstNameRef,
    middleNameRef, 
    lastNameRef, 
    statusRef,
    transactionDateRef,
    transactionNumberRef, 
    transactionTypeRef,
    agencyRef,
    reasonFingerPrintedRef,
    lockedRef,
    userRef,
    agencyIdentifierRef
  });
    
   const { addAuditEvent } = useAudit();
   const [tableData, setTableData] = useState([]);
   const [tableFiltersData, setTableFiltersData] = useState({
    firstName: 'null',
    lastName: 'null',
    middleName: 'null',
    tcn: 'null',
    rfp: 'null',
    ori: 'null',
    transactionStatus: 'null',
    lowerDate: 'null',
    upperDate: 'null'
   })
  const [{ currentPage, pageSize }, setPaginationState] = useState({
    pageSize: 100,
    currentPage: 1,
  });
  const [total, setTotal] = useState(0);
  const [dropDownOptions] = useState(getDropdownOptions(ALL_COLUMNS));

  const [openModalNotification, setOpenNotification] = useState(false);
  const [extendMessage, setExtendMesage] = useState<any>('');
  const [extendHeader, setExtendHeader] = useState<any>('');
  const [extendDays, setExtendDays] = useState('30');
  const [TODAY] = useState(dayjs());
  const [EARLIEST_DATE] = useState(dayjs("1900-01-01T00:00:00").format("MM/DD/YYYY"));

  //const [tableFilters, setTableFilters] = useState(tableFiltersState);
  const [filterMode, setFilterMode] = useState(FilterMode.AND);
  const [filterModeOptions] = useState(   [
    { value: FilterMode.AND, label: "And" },
    { value: FilterMode.OR, label: "Or" },
  ]);

  const [sortOrder, setSortOrder] = useState<any>('desc');
  const [sortColumn, setSortColumn] = useState('');
  const [columnName, setColumnName] = useState('default');
  const [currentSortedColumn, setCurrentSortedColumn] = useState<string>('transactionDate');
  const [clickLoading, setclickLoading] = useState(false);


  /* COLUMNS */
  const [columnsConfig, setColumnsConfig] = useState<any>();
  //When we add more states and have state specfic config files this will not bottle the neck logic 
  const [activeColumns, setActiveColumns] =
  useState<readonly string[]>(stateSpecficDefaultColumns(`${process.env.REACT_APP_STAGE}`)!);
  const [scrollBreakPoint, setScrollBreakPoint] = useState(
    window.innerWidth > 1859 ? TableScroll.GT1859 : TableScroll.LT1859
  );

const {
  mapped: allData,
  extendViewing,
  error,
  loading,
  totalRecords

 } = useQueryTransactions(pageSize, currentPage, sortOrder, userORIs,userSecondaryORIs, userRole, usersPermissions, currentSortedColumn, tableFilters, true);
 const navigate = useNavigate();

  const goToRecord = useCallback(
    (text: string) => {
      if (text) navigate(`/app/applicant-details/${text}`);
    },
    [navigate]
  );

  const handleExtendViewing = async (id: string) => {
    console.log("debug extendView handleExtendViewing... id: ", id);
    setclickLoading(true);
    const res: any = await extendViewing(id);
    console.log("debug extendView handleExtend res: ", res);
    if(res !== false) {
      setExtendHeader('Transaction extended');
      setExtendMesage(res);
    }else{
      setExtendHeader('Error');
      setExtendMesage('Transaction cannot be extended, please contact support.');
    }
    setOpenNotification(true);
    setclickLoading(false);

  };

  
  /* ===== TABLE ===== */
  const renderTableTop = () => (
    <div className="tableControls">
      {/*<div className="selectFilterMode">
        <span className="filterModeText">Filter Mode: </span>
        <Select
          defaultValue={FilterMode.AND}
          size="large"
          style={{ width: 120 }}
          options={filterModeOptions}
          onChange={(newMode: FilterMode) => setFilterMode(newMode)}
        />
  </div>*/}
      <ColumnsVisibilityButton 
        setActiveColumns={setActiveColumns} 
        defaultColumns={activeColumns}
        dropDownOptions={dropDownOptions} />
      <Button className="button" type="primary" size="large" onClick={() => clearFilters()}>Clear Filters</Button>
    </div>
  );

  /* ===== FILTERS ===== */
  const handleFiltering = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    handleFilteringRef.current = handleFiltering;

    const columnName = e.target.id as ColumnName;
    const filterValue: string = e.target.value;
    console.log(`debug filters columnName: ${columnName} filterVal: ${filterValue}`);

    filterValue
      ? setTableFilters((currentTableFilters: TableFilters) => {
          console.log(
            "currentTableFilters in setTableFilters: ",
            currentTableFilters
          );
          const tableFilters = {
            ...currentTableFilters,
            [columnName]: filterValue,
          };
          console.log("debug filters tableFilters: ", tableFilters);
          return tableFilters;
        })
      : setTableFilters(
          ({
            [columnName]: removedColumnFilter,
            ...filtersWithoutRemovedColumnFilter
          }) => filtersWithoutRemovedColumnFilter
        );
  }, 1000);

  const dateRangeFilter = useCallback(
    (rowDate: any) => {
      console.log("tableFilters: ", tableFilters);
      const rowDateAsDate = Date.parse(dayjs(rowDate).format("MM/DD/YYYY"));

      const startDateAsDate = tableFilters.transactionDate[0]
        ? Date.parse(
            dayjs(tableFilters.transactionDate[0]).format("MM/DD/YYYY")
          )
        : Date.parse(dayjs(EARLIEST_DATE).format("MM/DD/YYYY"));

      const endDateAsDate = tableFilters.transactionDate[1]
        ? Date.parse(
            dayjs(tableFilters.transactionDate[1]).format("MM/DD/YYYY")
          )
        : Date.parse(dayjs(TODAY).format("MM/DD/YYYY"));

      return rowDateAsDate >= startDateAsDate && rowDateAsDate <= endDateAsDate;
    },
    [tableFilters]
  );
  
  const filterElementConfig: any = useMemo(() => {
    return {
      transactionDate: {
        element: (
          <RangePicker
            disabledDate={disabledDate}
            bordered={false}
            format="MM/DD/YYYY"
            allowEmpty={[true, true]}
            onChange={(value) => {
              if(!value || value?.[0] == null || value?.[1] == null) {
                setTableFilters((currentTableFilters: TableFilters) => ({
                  ...currentTableFilters,
                  transactionDate: [],
                }));
              } else {
                console.log("dateRangeFilter value: ", value);
                setTableFilters((currentTableFilters: TableFilters) => ({
                  ...currentTableFilters,
                  transactionDate: [
                    value?.[0] && dayjs(value?.[0]).format("MM/DD/YYYY"),
                    value?.[1] && dayjs(value?.[1]).format("MM/DD/YYYY"),
                  ],
                }));
              }
            }}
          />
        ),
        filterFunction: dateRangeFilter,
      },
    };
  }, [dateRangeFilter, setTableFilters]);

  const changeSortOrder = (columnName: string, chosenSortOrder: 'asc' | 'desc') => {
    console.log(`changeSortOrder before changing newSortOrder to ${chosenSortOrder}`);

    let newSortedColumn = columnName;

    console.log(`changeSortOrder after changing newSortOrder to ${chosenSortOrder}`);
    
    setCurrentSortedColumn(newSortedColumn);
    setSortOrder(chosenSortOrder);
  }

  useEffect(() => {
    console.log(`changeSortOrder sortOrder: ${JSON.stringify(sortOrder)}`);
  }, [sortOrder])

  const handleSorting = (columnName: any, sortOrder: any) => {
    if(sortOrder === 'ascend') {
      sortOrder = 'asc'
    }
    else if (sortOrder === 'descend') {
      sortOrder = 'desc'
    }
    console.log(`changeSortOrder columnName: ${columnName} sortOrder: ${sortOrder}`)
    console.log(`changeSortOrder currentSortedColumn: ${currentSortedColumn}`)
    console.log(`changeSortOrder check: ${currentSortedColumn === columnName}`)

    changeSortOrder(columnName, sortOrder)
  }

  const renderColumnFilter = useCallback(
    (columnName: ColumnName, ref: any = {}, overrides: Record<string, any> = {}) => {
      console.log("changeSortOrder renderColumnFilter running for: ", columnName);
      return {
        ...{
          title: camelToTitleCase(columnName),
          dataIndex: columnName,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter: (a: any, b: any, sortOrder: any) => handleSorting(columnName, sortOrder),
          children: [
            {
              width: smallerColumns.includes(columnName) ? 150 : 190,
              dataIndex: columnName,
              title:
                columnName in filterElementConfig ? (
                  filterElementConfig[columnName].element
                ) : (
                  <Input
                    ref={ref}
                    id={columnName}
                    placeholder={`Filter ${camelToTitleCase(columnName)}`}
                    onChange={handleFiltering}
                    onFocus={() => handleFilteringRef.current?.flush()}
                    bordered={false}
                  />
                ),
            },
          ],
        },
        ...overrides,
      };
    },
    [filterElementConfig, handleFiltering]
  );

  const clearFilters = () => {
    console.log('refs: ', refs)
    Object.keys(refs).forEach((ref: any) => {
      console.log('ref: ', ref)
      if(refs[ref].current) {
        refs[ref].current.input.value = ""
      }
    })
    setTableFilters({});
  };
  
  /* ===== COLUMNS ===== */
  const defaultColumnsConfig: any = useMemo(() => {
    console.log("defaultColumnsConfig memo running");

    return [
      renderColumnFilter("view", viewRef, {
        children: [
          {
            dataIndex: "view",
            title: () => {},
            width: 80,
            render: (text: string) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link to={`/app/applicant-details/${text}`} onClick={() => addAuditEvent("View Applicant Details", "Applicant data viewed: " + text, new Date(), undefined, undefined) } target="_blank">
                  <BsFillPersonLinesFill
                      style={{
                        fontSize: "28px",
                        color: "#8566C1",
                        cursor: "pointer",
                      }}
                    />
                </Link>
                  
                {usersPermissions.extendTransactionRecord ? (
                  <Button
                    onClick={() => handleExtendViewing(text)}
                    disabled={clickLoading}
                    loading={clickLoading}
                    size="small"
                    style={{
                      borderRadius: "15px",
                      border: "1px solid white",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#7536c3",
                      color: "white",
                      marginTop: "5px",
                    }}
                  >
                    Extend
                  </Button>
                ) : null}
              </div>
            ),
          },
        ],
        sorter: null,
      }),
      renderColumnFilter("firstName", firstNameRef),
      renderColumnFilter("middleName", middleNameRef),
      renderColumnFilter("lastName", lastNameRef),
      renderColumnFilter("status", statusRef),
      renderColumnFilter("transactionDate", transactionDateRef, {
        defaultSortOrder: "descend" as SortOrder,
        // sorter: (a: any, b: any) => {
        //   const aDate = new Date(a.transactionDate);
        //   const bDate = new Date(b.transactionDate);
        //   return aDate.getTime() - bDate.getTime();
        // },
      }),
      renderColumnFilter("transactionNumber", transactionNumberRef),
      renderColumnFilter("transactionType", transactionTypeRef),
      renderColumnFilter("agency", agencyRef),
      renderColumnFilter("reasonFingerPrinted", reasonFingerPrintedRef),
      renderColumnFilter("locked", lockedRef),
      renderColumnFilter("user", userRef),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  /* ===== USEEFFECTS */
  useEffect(() => {
    setRefs({firstNameRef,
      middleNameRef, 
      lastNameRef, 
      statusRef,
      transactionDateRef,
      transactionNumberRef, 
      transactionTypeRef,
      agencyRef,
      reasonFingerPrintedRef,
      lockedRef,
      userRef})
  }, [firstNameRef,
    middleNameRef, 
    lastNameRef, 
    statusRef,
    transactionDateRef,
    transactionNumberRef, 
    transactionTypeRef,
    agencyRef,
    reasonFingerPrintedRef,
    lockedRef,
    userRef
  ]);

  useEffect(() => {
    if(allData) {
      setTableData(allData);
      setTotal(totalRecords);
    }
    console.log(defaultColumnsConfig)
    console.log(`activeColumns length: ${activeColumns.length}`);
    setColumnsConfig( defaultColumnsConfig.filter((column: any) =>
    activeColumns.includes(column.title)
  ));
  }, [allData, loading, sortOrder, columnName,activeColumns.length, defaultColumnsConfig, activeColumns]);

  /* ===== PAGINATION ===== */
  const handlePageChange = (currentPage: number, pageSize: number) => {
    setPaginationState({ currentPage, pageSize });
  };

  const handleOkModal = () => {
    setOpenNotification(false);
  };

  return (
    <>
      <Modal
        title={extendHeader}
        open={openModalNotification}
        onOk={handleOkModal}
        onCancel={() => handleOkModal()}
        okText={<>Ok</>}
        width={"50vw"}
        centered
        okButtonProps={{ className: "button" }}
        footer={[
          <Button className="ant-btn-primary" key="ok" onClick={handleOkModal}>
            Ok
          </Button>,
        ]}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span style={{ fontSize: "1.5em", color: "black" }}>
            {extendMessage}
          </span>
        </div>
      </Modal>
      <Table
        title={renderTableTop}
        dataSource={tableData}
        columns={columnsConfig}
        scroll={{ y: scrollBreakPoint }}
        loading={loading}
        pagination={
          {
          position: ["bottomLeft"],
          current: currentPage,
          pageSize,
          defaultPageSize: pageSize,
          total,
          pageSizeOptions: ["25", "50", "100"],
          locale: { items_per_page: "" },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          onChange: (currentPage, pageSize) => {
            handlePageChange(currentPage, pageSize);
          },
          showSizeChanger: true,
        }
      } />
    </>
  )
}

export default TransactionSummaryTable;
