/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { router } from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "components/Theme";
import { IdleTracker } from "../src/components/Idle-Tracker/index";
import { UserProvider } from "components/UserPermissions/UserPermissionsContext";

/*if (process.env.NODE_ENV !== "development") console.log = () => {};*/

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <CookiesProvider>
    <ThemeProvider>
      <UserProvider>
        <IdleTracker idleTimeout={5 * 60_000} promptTimeout={5 * 60_000} />
        <RouterProvider router={router} />
      </UserProvider>
    </ThemeProvider>
  </CookiesProvider>
);

reportWebVitals();
