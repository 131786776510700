/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, notification,Modal } from "antd";
import { EditView } from "components/EditView/EditView";
import { useCallback, useEffect,useState } from "react";
import { ApplicantDetailsActionTypes, useApplicantDetailsContext } from "../ApplicantDetailsContext/ApplicantDetailsProvider";
import { getEnvS3Name } from "./ApplicantDetailsFooterData";
import "./ApplicantDetailsFooter.scss";
import { Auth } from 'aws-amplify';
import { urls } from '../../../../urls'


const openNotification = () => {
  notification.success({
    message: 'Saved All Tabs Successfully',
    placement: 'bottomRight',
    // bottom: 75,
    duration: 1
  });
};
interface ApplicantDetailsFooterProps {
  registrationId: string
  applicantData: any
  usersPermissions:any
  transactionalInfo:any
}

export const ApplicantDetailsFooter: React.FC<ApplicantDetailsFooterProps> = ({registrationId,applicantData,usersPermissions,transactionalInfo}) => {
  let s3bucket =getEnvS3Name(`${process.env.REACT_APP_STAGE}`)!;
  const { applicantDetailsData, applicantDetailsDispatch } = useApplicantDetailsContext();
  const { hasChanges, decision, currentTab } = applicantDetailsData;
  const [openModalNotification, setOpenNotification] = useState(false);
  const url = urls.TRANSACTION
  const workflowUrl = urls.WORKFLOW
  const [loading, setloading] = useState(false);

  
  const updateDecision = useCallback( async (decision: string | null) => {
    if (!decision) return;
    const { username } = await Auth.currentAuthenticatedUser()
    const endpoint = `${process.env.NODE_ENV === 'production' ? url : ""}/transaction/taskResponse/${registrationId}`;
  
    const body: { user: string; decision: string } = {
      user: username,
      decision
    };
   
  
    const options: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    console.log('updateDecision: body: ', body)
    
    fetch(endpoint, options).then((response: Response) => {
      console.log('update decision success', response);
    }).catch((error: Error) => {
      console.error('update decision error', error);
    });
  }, [registrationId, url])
  
  const onSave = useCallback(() => {
    console.log('save ran: ')
    const saveFunction = () => {
      switch (currentTab) {
        case 'DETAILS': (()=>{})();
        break;
        case 'RESULTS':
          updateDecision(decision);
          break;
        default: break;
      }
    }
    openNotification();
    saveFunction();
    applicantDetailsDispatch({type: ApplicantDetailsActionTypes.REMOVE_CHANGES});
  }, [applicantDetailsDispatch, currentTab, decision, updateDecision])

  useEffect(() => {
    const onSaveShortCut = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        onSave();
      }
    }

    window.addEventListener('keydown', onSaveShortCut);

    return () => {
      window.removeEventListener('keydown', onSaveShortCut);
    };
  }, [onSave]); 
  const handleResubmission = async () => {
    setloading(true);
    const res: any = await resubmission();
    console.log("debug handleResubmission handleExtend res: ", res);
    setOpenNotification(false);
    setloading(false)

  };
  
  const resubmission = async () => {

    console.log('debug applicant data',applicantData)
        const endpoint = `${process.env.NODE_ENV === 'production' ? workflowUrl: ""}/resubmitTransaction`;

        const body = {
          "Records":[
            {
              "person_id": `${applicantData.parentid}`,
              "isResubmission": "true",
              "generateTCN": false,
              "s3_bucket_nist": `${s3bucket}`,
              "s3_key_nist": `${applicantData.attachments[0].displayid}`,
              "registration_id": `${registrationId}`,
              "s3": {
                  "bucket": {
                      "name": `${s3bucket}`,
                      "arn": `arn:aws:s3:::${s3bucket}`
                  },
                  "object": {
                      "key":  `${applicantData.attachments[0].displayid}`
                  }
              }            
          }
                 
        ]
      };

        const options: RequestInit = {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(body),
        };
        try {
            const response = await (await fetch(endpoint, options)).json();
            return response;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
};
const handleOkModal = () => {
  setOpenNotification(false);
};
  return (
    <div className="applicant-details-footer">
        <Modal
        title={'Re-submission'}
        open={openModalNotification}
        onOk={handleOkModal}
        onCancel={() => handleOkModal()}
        okText={<>Ok</>}
        width={"50vw"}
        centered
        okButtonProps={{ className: "button" }}
        footer={[
          <Button
          children="Yes"
          disabled={loading}
          loading={loading}
          onClick={() => handleResubmission()}
          size="large"
          className="button-outline"
        />,
        <Button
          children="No"
          icon={<CloseOutlined />}
          onClick={() => handleOkModal()}
          size="large"
          className="button-outline"
        />
        ]}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span style={{ fontSize: "1.5em", color: "black" }}>
          Are you sure you would like to resubmit this transaction?
          </span>
        </div>
      </Modal>
      {((usersPermissions.canResubmitTransaction )&&(transactionalInfo.currentStatus === 'Error' || transactionalInfo.currentStatus === 'Waiting for responses')) &&(
      <Button
          children="Re-submission"
          onClick={() => setOpenNotification(true)}
          size="large"
          className="button-outline-resub"
        />
      )}
      <Button
          children="Close"
          icon={<CloseOutlined />}
          onClick={() => window.close()}
          size="large"
          className="button-outline"
        />
      <EditView>
        <Button
          onClick={onSave}
          size="large"
          className="save-button"
          disabled={!hasChanges}>
            {hasChanges ? 
            "Save" :
            (<>Saved <CheckOutlined /></>)
            }
        </Button>
      </EditView>
    </div>
  );
};
