/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

export const hairColors = {
    bln: 'Blonde',
    blk: 'Black',
    brn: 'Brown',
    red: 'Red'
}

export const eyeColors = {
    blu: 'Blue',
    blk: 'Black',
    brn: 'Brown'
}

export const races = {
    W: 'White',
    A: 'Asian',
    N: 'American Indian or Alaska Native',
    H: 'Hispanic or Latino',
    B: 'Black or African American',
    O: 'Native Hawaiian or Other Pacific Islander'
}

export const placesOfBirth = {
    AL: 'ALABAMA',
    AK: 'ALASKA',
    AZ: 'ARIZONA',
    AR: 'ARKANSAS',
    CA: 'CALIFORNIA',
    CO: 'COLORADO',
    CT: 'CONNECTICUT',
    DE: 'DELAWARE',
    FL: 'FLORIDA',
    GA: 'GEORGIA',
    GUM: 'GUAM',
    HI: 'HAWAII',
    ID: 'IDAHO',
    IL: 'ILLINOIS',
    IN: 'INDIANA',
    IA: 'IOWA',
    KS: 'KANSAS',
    KY: 'KENTUCKY',
    LA: 'LOUISIANA',
    ME: 'MAINE',
    MD: 'MARYLAND',
    MA: 'MASSACHUSETTS',
    MI: 'MICHIGAN',
    MN: 'MINNESOTA',
    MS: 'MISSISSIPPI',
    MO: 'MISSOURI',
    MT: 'MONTANA',
    NE: 'NEBRASKA',
    NV: 'NEVADA',
    NH: 'NEW HAMPSHIRE',
    NJ: 'NEW JERSEY',
    NM: 'NEW MEXICO',
    NY: 'NEW YORK',
    MNP: 'NORTHERN MARIANA ISLANDS',
    NC: 'NORTH CAROLINA',
    ND: 'NORTH DAKOTA',
    OH: 'OHIO',
    OK: 'OKLAHOMA',
    OR: 'OREGON',
    PA: 'PENNSYLVANIA',
    PRI: 'PUERTO RICO',
    RI: 'RHODE ISLAND',
    SC: 'SOUTH CAROLINA',
    SD: 'SOUTH DAKOTA',
    TN: 'TENNESSEE',
    TX: 'TEXAS',
    VIR: 'U.S. VIRGIN ISLANDS',
    UT: 'UTAH',
    VT: 'VERMONT',
    VA: 'VIRGINIA',
    DC: 'WASHINGTON, DC',
    WA: 'WASHINGTON',
    WV: 'WEST VIRGINIA',
    WI: 'WISCONSIN',
    WY: 'WYOMING',
    NA: 'N/A'
}

export const countries = {
    US: 'UNITED STATES'
}