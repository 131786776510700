/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React from 'react';
import { Tabs } from 'antd';
import { ApplicantDetailsForm } from './ApplicantDetailsForm/ApplicantDetailsForm';
import './ApplicantDetailsBody.scss';
import { ApplicantFetchStatus } from 'hooks/shared/types';
import dayjs from "dayjs";
import { Notes } from '../Notes/Notes';
import Results from './Results/Results';
import { ApplicantDetailsActionTypes, useApplicantDetailsContext } from 'pages/ApplicationDetails/components/ApplicantDetailsContext/ApplicantDetailsProvider';
import { useConfig } from '../../hooks/useConfig';
import { useTransactionHistory } from "hooks/useTransactionHistory";

let env = process.env.REACT_APP_STAGE
const convertDate = (date: string) => {
    return dayjs(date).format("MM/DD/YYYY");
};

interface ApplicantDetailsBodyProps {
    userRole: string
    personalInfo: any
    transactionalInfo: any
    applicantFetchStatus: ApplicantFetchStatus
    statusHistories: Array<Object>
    registrationId: string,
    results: Array<Object>,
    aggregatedResponses: Array<string>,
    rapSheets: Array<string>,
    resultsResponses: Array<Object>
}

export const ApplicantDetailsBody: React.FC<ApplicantDetailsBodyProps> = ({
    userRole,
    personalInfo,
    transactionalInfo,
    applicantFetchStatus,
    statusHistories,
    registrationId,
    results,
    aggregatedResponses,
    rapSheets,
    resultsResponses
    
}) => {

    console.log('debug results results: ', results)
    console.log('Aggregated responses: ', aggregatedResponses)
    const { completeHistory } = useTransactionHistory(registrationId);
    const { config } = useConfig();
    const resultsLimit = config.transactionViewing.resultsLimit;
    let today = new Date();
    let todayTime = today.getTime();
    const dayConversion = 86400000;

    const { applicantDetailsDispatch } = useApplicantDetailsContext();

    let items = [
        {
            label: "DETAILS",
            key: 'DETAILS',
            children:
                <div className="applicantDetails-body_tab-content">
                    <ApplicantDetailsForm
                        userRole={userRole}
                        personalInfo={personalInfo}
                        transactionalInfo={transactionalInfo}
                        applicantFetchStatus={applicantFetchStatus}
                        statusHistories={statusHistories} />
                </div>
        },
        {
            label: 'RESULTS',
            key: 'RESULTS',
            children:
                <>
                    <div className="applicantDetails-body_tab-content">
                        <Results 
                            userRole={userRole} 
                            results={results} 
                            aggregatedResponses={aggregatedResponses} 
                            rapSheets={rapSheets} 
                            transactionalInfo={transactionalInfo}
                            resultsResponses={resultsResponses} />
                    </div>
                </>
        },
        {
            label: 'NOTES',
            key: 'NOTES',
            children:
                <>
                    <div className="applicantDetails-body_tab-content">
                        <Notes
                            personalInfo={personalInfo} 
                            userRole={userRole} />
                    </div>
                </>
        }
    ];

    // Check if the current record is older than 5 years, and remove the results tab from view if so
    if (env === 'meprod') {
        if (completeHistory) {
            const lastResponseTime = new Date(convertDate(completeHistory[completeHistory.length-1].insert_date)).getTime();
            const lifeTime = (todayTime / dayConversion) - (lastResponseTime / dayConversion);
            if (resultsLimit - lifeTime < 0 ) {
                items.splice(1, 1);
            }
        }
    }

    return (
        <div className="applicantDetails-body">
            <Tabs 
            defaultActiveKey="1" 
            tabPosition='left'
            items={items}
            onChange={(key) => applicantDetailsDispatch({type: ApplicantDetailsActionTypes.UPDATE_TAB, payload: { currentTab: key}})}
            renderTabBar={(props, DefaultTabBar) => { return (
                <DefaultTabBar {...props} className="applicantDetails-body_tab-bar" />
            )}}
            />
        </div>
    );
};
