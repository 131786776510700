/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React from "react";
import { useParams } from "react-router-dom";
import { useApplicantDetails } from "hooks/useApplicantDetails";
import "./ApplicantDetails.scss";
import { ApplicantDetailsBody } from "components/ApplicantDetailsBody/ApplicantDetailsBody";
import { ApplicantDetailsHeader } from "components/ApplicantDetailsHeader/ApplicantDetailsHeader";
import { ApplicantDetailsFooter } from "./components/ApplicantDetailsFooter/ApplicantDetailsFooter";
import { ApplicantDetailsProvider } from "./components/ApplicantDetailsContext/ApplicantDetailsProvider";
import { useTransactionHistory } from "hooks/useTransactionHistory";
import { useAggregatedResponses } from "hooks/useAggregatedResponses";
import { useResultsResponse } from "hooks/useResultsResponses";
import { useApplicantData } from "hooks/useApplicantData";

import { useUserRole } from "hooks/useUserRole";
import { useRapSheets } from "hooks/useRapSheets";
import { useUserCognito } from "hooks/useUserCognito";


export const ApplicantDetails: React.FC = () => {
  type MyParams = {
    registrationId: string;
  };
  const { registrationId } = useParams<keyof MyParams>() as MyParams;
  const { mappedHistory, mappedHistoryPerAgency } =
    useTransactionHistory(registrationId);
  const { applicantInfo, applicantImageUrl, applicantFetchStatus } =
    useApplicantDetails(registrationId as string);
  const { userRole } = useUserRole();
  const { rapSheets } = useRapSheets(registrationId as string);
  const { resultsResponse } = useResultsResponse(registrationId as string);
  const { userProfile, personalInfo, transactionalInfo } = applicantInfo;
  const {applicantData } =  useApplicantData(registrationId as string);
  const { usersPermissions } = useUserCognito();

  const ids: any = [];
  if (mappedHistoryPerAgency && mappedHistoryPerAgency.length > 0)
    mappedHistoryPerAgency.map((obj: any) => ids.push(obj.uuid));
  const { aggregatedResponses } = useAggregatedResponses(ids);
  console.log(
    "debug applicantData: mappedHistoryPerAgency: ",
    mappedHistoryPerAgency
  );
  console.log(
    `debug applicantData applicantInfo ${JSON.stringify(
      applicantInfo,
      null,
      "\t"
    )}`
  );

  return (
    <ApplicantDetailsProvider>
      <div className="applicantDetails">
        {userRole && (
          <>
            <ApplicantDetailsHeader
              userRole={userRole}
              userProfile={userProfile}
              applicantImageUrl={applicantImageUrl}
              applicantFetchStatus={applicantFetchStatus}
            />
            <div>
              <ApplicantDetailsBody
                userRole={userRole}
                personalInfo={personalInfo}
                transactionalInfo={transactionalInfo}
                applicantFetchStatus={applicantFetchStatus}
                statusHistories={mappedHistory}
                registrationId={registrationId}
                results={mappedHistoryPerAgency}
                aggregatedResponses={aggregatedResponses}
                rapSheets={rapSheets}
                resultsResponses={resultsResponse}
              />
            </div>
            <ApplicantDetailsFooter 
            registrationId={registrationId}
            applicantData={applicantData}
            usersPermissions={usersPermissions}
            transactionalInfo={transactionalInfo}
            />
          </>
        )}
      </div>
    </ApplicantDetailsProvider>
  );
};
