/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Divider, Form, Select } from "antd";
import {
  detailsTabOptions,
  manualAdjudicationOptions,
  transactionSummaryScreenOptions,
} from "../adminOptions";
import "./Permissions.scss";
import { useUserPermissions } from "components/UserPermissions/UserPermissionsContext";
import {
  formatPermissions,
  formatPermissionsAfterFetch,
  formatPermissionsForForm,
  UserPermission,
} from "components/UserPermissions/permissionsConfig";
import ApiService from "utils/apiService";
import { BiLock } from "react-icons/bi";
import { urls } from "../../../urls";
import { useUserCognito } from "hooks/useUserCognito";

const CheckboxGroup = Checkbox.Group;

export const Permissions = () => {
  const [adminForm] = Form.useForm();

  const { setPermissions, roles } = useUserPermissions();
  const { userRole, groups } = useUserCognito();
  console.log(
    `permissions userRole: ${userRole} roles: ${JSON.stringify(userRole)}`
  );

  const [selectedGroup, setSelectedGroup] = useState("");

  const [allConfigs, setAllConfigs] = useState<any>({});

  const [openKeys, setOpenKeys] = useState<any>({
    resultsOrginal: ["1"],
    resultsRedacted: ["1"],
    othersNotes: ["1"],
    agencyManagement: ["1"],
  });

  const url = urls.GROUPROLE;
  console.log("ori_rfp_filter permissions url: ", url);

  const groupRoleService = new ApiService(
    `${process.env.NODE_ENV === "production" ? url : ""}/groupRole`
  );

  const onOpenChange = (event: any, name: string) => {
    const checked = event.target.checked;
    setOpenKeys({ ...openKeys, [name]: checked ? ["1"] : [] });
  };

  const handleSubmit = async (values: any) => {
    console.log("permissions handleSubmit values: ", values);
    //console.log(`permissions selectedGroup: ${selectedGroup} setPermissions(formatPermissions(values)): ${setPermissions(formatPermissions(values))}`)
    let userRole =
      selectedGroup; /*&& setPermissions(formatPermissions(values));*/ // ask about this tomorrow
    console.log("permissions handleSubmit userRole: ", userRole);

    const originalSelectedPermissions: any = [];
    Object.entries(allConfigs[selectedGroup]).forEach(([key, value]) => {
      if (value) originalSelectedPermissions.push(key);
    });

    console.log(
      "permissions originalSelectedPermissions: ",
      originalSelectedPermissions
    );

    const newlySelected = Object.values(values)
      .flat()
      .filter(
        (permission: any) => !originalSelectedPermissions.includes(permission)
      );

    console.log("permissions newlySelected: ", newlySelected);

    const newlyUnselected = originalSelectedPermissions.filter(
      (permission: any) => !Object.values(values).flat().includes(permission)
    );

    Promise.all(
      newlyUnselected.map(async (permission: string) => {
        await groupRoleService.delete(selectedGroup, {
          group_id: selectedGroup,
          role_id: permission,
        });
      })
    );

    Promise.all(
      newlySelected.map(async (permission) => {
        console.log("permissions post permission: ", permission);
        await groupRoleService.post(
          { group_id: selectedGroup, role_id: permission },
          selectedGroup
        );
      })
    );

    setAllConfigs({
      ...allConfigs,
      [selectedGroup]: formatPermissions(values),
    });
  };

  useEffect(() => {
    if (!selectedGroup || !allConfigs[selectedGroup]) return;
    adminForm.setFieldsValue(
      formatPermissionsForForm(allConfigs[selectedGroup])
    );
    console.log("allConfigs[selectedGroup]", allConfigs[selectedGroup]);
    setOpenKeys({
      resultsOrginal: allConfigs[selectedGroup].viewOriginalResults
        ? ["1"]
        : [],
      resultsRedacted: allConfigs[selectedGroup].viewRedactedResults
        ? ["1"]
        : [],
      othersNotes: allConfigs[selectedGroup].viewOthersNotes ? ["1"] : [],
      agencyManagement: allConfigs[selectedGroup].viewAgencies ? ["1"] : [],
    });
  }, [selectedGroup, JSON.stringify(allConfigs)]);

  useEffect(() => {
    setSelectedGroup(groups[0]);
    (async () => {
      const allGroups = groups.map((role: any) => role.id);
      if (allGroups) {
        const configs = await Promise.all(
          allGroups?.map(async (groupName: string) => {
            const { data } = await groupRoleService.get(groupName);
            return [groupName, formatPermissionsAfterFetch(data)];
          })
        );

        setAllConfigs(Object.fromEntries(configs));
      }
    })();
  }, [userRole]);

  const uncheckDependedPermissions = (
    { target }: any,
    parent: string,
    keysToRemove: string[]
  ) => {
    if (target.checked) return;
    adminForm.setFieldValue(
      parent,
      adminForm.getFieldValue(parent).filter((currentValue: any) => {
        return !keysToRemove.includes(currentValue);
      })
    );
  };

  const onGroupChange = async (value: any) => {
    setSelectedGroup(value);
    adminForm.setFieldsValue(formatPermissionsForForm(allConfigs[value]));
  };

  const allGroups =
    groups &&
    groups.map((role: any) => {
      const roleId = role.id;
      return { label: roleId, value: roleId };
    });

  return (
    <div className="admin">
      <Form form={adminForm} onFinish={handleSubmit} className="admin__content">
        <div style={{ gridArea: "save" }} className="admin__header">
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <span className="title">
              {" "}
              <BiLock /> USER PERMISSIONS
            </span>
            <div className="group-select">
              <span>Groups: </span>
              <Select
                value={selectedGroup}
                onChange={onGroupChange}
                style={{ width: 200 }}
                options={allGroups}
              />
            </div>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="save-button"
              style={{ width: 100 }}
            >
              Save
            </Button>
          </Form.Item>
        </div>

        <div className="anchor">
          <div className="admin__permissions">
            <div
              style={{ gridArea: "transaction" }}
              className="permission-group"
            >
              <div className="permission-group__title">
                Transaction Summary Screen
              </div>
              <div style={{ padding: 10 }}>
                <Form.Item name="transactionSummary">
                  <CheckboxGroup options={transactionSummaryScreenOptions} />
                </Form.Item>
              </div>
            </div>

            <div style={{ gridArea: "agency" }} className="permission-group">
              <div className="permission-group__title">Agency Management</div>
              <div style={{ padding: 10 }}>
                <Form.Item name="agencyManagement">
                  <CheckboxGroup style={{ display: "grid" }}>
                    <Collapse
                      defaultActiveKey={["1"]}
                      activeKey={openKeys.agencyManagement}
                      collapsible="icon"
                    >
                      <div>
                        <Checkbox
                          value={UserPermission.viewAgencies}
                          style={{ padding: 10 }}
                          onChange={(e) => onOpenChange(e, "agencyManagement")}
                        >
                          View Agencies
                        </Checkbox>
                      </div>
                      <Collapse.Panel header="" key="1" showArrow={false}>
                        <Checkbox value={UserPermission.addNewAgencies}>
                          add new agencies
                        </Checkbox>
                        <Checkbox value={UserPermission.editExistingAgencies}>
                          edit existing agencies
                        </Checkbox>
                        <Checkbox value={UserPermission.deleteExistingAgencies}>
                          delete existing agencies
                        </Checkbox>
                      </Collapse.Panel>
                    </Collapse>
                  </CheckboxGroup>
                </Form.Item>
              </div>
            </div>

            <div style={{ gridArea: "details" }} className="permission-group">
              <div className="permission-group__title">Details Tab</div>
              <div style={{ padding: 10 }}>
                <Form.Item name="detailsTab">
                  <CheckboxGroup options={detailsTabOptions} />
                </Form.Item>
              </div>
            </div>

            <div style={{ gridArea: "results" }} className="permission-group">
              <div className="permission-group__title">Results Tab</div>
              <div style={{ padding: 10 }}>
                <Form.Item name="manualAdjudication">
                  <CheckboxGroup options={manualAdjudicationOptions} />
                </Form.Item>

                <Form.Item name="resultsTabOriginal">
                  <CheckboxGroup>
                    <Divider orientation="left" orientationMargin="0">
                      Results Original
                    </Divider>
                    <Collapse
                      defaultActiveKey={["1"]}
                      activeKey={openKeys.resultsOrginal}
                      collapsible="icon"
                    >
                      <div>
                        <Checkbox
                          value={UserPermission.viewOriginalResults}
                          style={{ padding: 10 }}
                          onChange={(e) => onOpenChange(e, "resultsOriginal")}
                        >
                          View original
                        </Checkbox>
                      </div>
                      <Collapse.Panel header="" key="1" showArrow={false}>
                        <Checkbox value={UserPermission.printOriginalResults}>
                          print original
                        </Checkbox>
                      </Collapse.Panel>
                    </Collapse>
                  </CheckboxGroup>
                </Form.Item>

                <Form.Item name="resultsTabRedaction">
                  <CheckboxGroup>
                    <Divider orientation="left" orientationMargin="0">
                      Results Redacted
                    </Divider>
                    <Collapse
                      defaultActiveKey={["1"]}
                      activeKey={openKeys.resultsRedacted}
                      collapsible="icon"
                    >
                      <Checkbox
                        value={UserPermission.viewRedactedResults}
                        style={{ padding: 10 }}
                        onChange={(e) => onOpenChange(e, "resultsRedacted")}
                      >
                        View Redacted
                      </Checkbox>
                      <Collapse.Panel header="" key="1" showArrow={false}>
                        <Checkbox value={UserPermission.printRedactedResults}>
                          print redacted
                        </Checkbox>
                        <Checkbox value={UserPermission.editRedactedResults}>
                          edit redacted
                        </Checkbox>
                        <Checkbox
                          value={UserPermission.createNewRedactedResults}
                        >
                          create new redacted
                        </Checkbox>
                      </Collapse.Panel>
                    </Collapse>
                  </CheckboxGroup>
                </Form.Item>
              </div>
            </div>

            <div style={{ gridArea: "notes" }} className="permission-group">
              <div className="permission-group__title">Notes Tab</div>
              <div style={{ padding: 10 }}>
                <Form.Item name="notesTab">
                  <CheckboxGroup>
                    <Divider orientation="left" orientationMargin="0">
                      Own Notes
                    </Divider>
                    <Checkbox value={UserPermission.createNewNotes}>
                      create new notes
                    </Checkbox>
                    <Checkbox value={UserPermission.editOwnNotes}>
                      edit own notes
                    </Checkbox>
                    <Checkbox value={UserPermission.printNotes}>print</Checkbox>
                    <Divider orientation="left" orientationMargin="0">
                      Others Notes
                    </Divider>
                    <Collapse
                      defaultActiveKey={["1"]}
                      activeKey={openKeys.othersNotes}
                      collapsible="icon"
                    >
                      <Checkbox
                        value={UserPermission.viewOthersNotes}
                        style={{ padding: 10 }}
                        onChange={(e) => onOpenChange(e, "othersNotes")}
                      >
                        view others notes
                      </Checkbox>
                      <Collapse.Panel header="" key="1" showArrow={false}>
                        <Checkbox value={UserPermission.editOthersNotes}>
                          edit other notes
                        </Checkbox>
                      </Collapse.Panel>
                    </Collapse>
                  </CheckboxGroup>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
