/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect, useRef } from 'react'
import { useConfig } from './useConfig';
import { useUserRole } from './useUserRole';
import { useUserCognito } from './useUserCognito';
import { urls } from '../urls'
import dayjs from "dayjs";
import { updateToken } from "./shared/helpers"
import { useCookies } from "react-cookie";
// import { getFirstSSN } from '../utils/formatSSN';


const convertDate = (date: any, type: any) => {
    switch(type){
        case 'dob':
            console.log('date for date of birth: ', date)
            const flippedDate = `${date.substring(4,8)}${date.substring(0,2)}${date.substring(2,4)}`;
            return dayjs(flippedDate).format('MM/DD/YYYY');
        case 'insertdate':
            console.log('date for insert date: ', date)
            return dayjs(date).format('MM/DD/YYYY h:mm:ss A');

        default: 
            console.log('date for rapback: ', date)
            return dayjs(date).format('MM/DD/YYYY');
    }
}

const createName = (nameString: string) => nameString.split(/[, ]+/);

const getFirstSSN = (ssn: string | undefined) => {
    if (ssn?.includes(',')) {
      ssn = ssn.split(',')[0];
      return ssn
    } else {
      return ssn;
    }
  }

const transform = (data: any, i: number) => {
    console.log('rapBackSummary oriFilter transform data: ', data)
    //const [lastName, firstName, middleName] = createName(data.name);

    let showSSN = false
    return {
        key: data.id, //id coould also be an index
        view: data.id,
        firstName: data.FIRST_NAME, //Last,First M || Last, First M
        lastName: data.LAST_NAME, //Last,First M || Last, First M
        middleName: data.MIDDLE_NAME != "null" ? data.MIDDLE_NAME : "",
        agency: data.agency,
        oris: data.oris,
        sid: data.sid, 
        ssn: getFirstSSN(data.ssn), 
        dob: convertDate(data.dob, 'dob'),
        reason: data.reason,
        isHit: data.isHit.toUpperCase(),
        rapbackdate: convertDate(data.rapbackdate, 'rapbackdate'),
        fileData: data.filedata,
        insertdate: convertDate(data.insertdate,'insertdate')
    }
}


export const useQueryRapBack = (pageSize: number,
    currentPage: number,
    order: string,
    columnName: string,
    tableFiltersData: any) => {

    const [mapped, setMappedData] = useState<any>()
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false)
    const [countError, setError] = useState();
    const { userORIs } = useUserCognito();
    const {userRole} = useUserRole()
    const { config } = useConfig()
    const [, setCookie, removeCookie] = useCookies();

    let today = new Date()
    let todayTime = today.getTime()

    const { hardLimit, softLimit, extendDays } = config.transactionViewing;

    const url = urls.QUERY

    console.log('rapBackSummary useRapBackCount node env: ', process.env.NODE_ENV)
    console.log('rapBackSummary useRapBackCount url: ', url)
    console.log('userORIs: ', JSON.stringify(userORIs))

    useEffect(() => {
        const controller = new AbortController()
        const { signal } = controller

        let dayLimit = userRole === 'mc_admin' ? "" : `&dayLimit=${softLimit}` 

        const allData: any = []

        if(userORIs.length > 0) {
            console.log('run queryRapback tableFilters: ', tableFiltersData)
            const adminRoles = ["mc_admin"];
            const isAdmin = adminRoles.includes(userRole);
            const { firstName, middleName, lastName, oris, ssn, sid, dob, rapbackdate, reasonFingerPrinted, isHit, agency,insertdate } = tableFiltersData;
            const queryParams = [
                {
                    "value": isAdmin.toString(), // 'true' or 'false' 
                    "name": "isAdmin"
                },
                {
                    "value":  JSON.stringify(userORIs),
                    "name": "userOris"
                },
                {
                    "value": '90',
                    "name": "days"
                },
                {
                    "value": columnName ? columnName : "default" ,
                    "name": "orderBy"
                },
                {
                    "value": firstName ? firstName : 'null',
                    "name": "firstName"
                },
                {
                    "value": middleName ? middleName : 'null',
                    "name": "middleName"
                },
                {
                    "value": lastName ? lastName : 'null',
                    "name": "lastName"
                },
                {
                    "value": sid ? sid : 'null',
                    "name": "sid"
                },
                {
                    "value": ssn ? ssn : 'null',
                    "name": "ssn"
                },
                {
                    "value": reasonFingerPrinted ? reasonFingerPrinted : 'null',
                    "name": "reason"
                },
                {
                    "value": agency ? agency : 'null',
                    "name": "agency"
                },
                {
                    "value": isHit ? isHit : 'null',
                    "name": "isHit"
                },
                {
                    "value": oris ? oris: 'null',
                    "name": "ori"
                },
                {
                    "value": dob?.length ? dayjs(dob[0]).format("MMDDYYYY") : 'null',
                    "name": "lowerDobDate"
                },
                {
                    "value": dob?.length ? dayjs(dob[1]).format("MMDDYYYY") : 'null',
                    "name": "upperDobDate"
                },
                {
                    "value": rapbackdate?.length ? dayjs(rapbackdate[0]).format("YYYYMMDD") : 'null',
                    "name": "lowerRapbackDate"
                },
                {
                    "value": rapbackdate?.length ? dayjs(rapbackdate[1]).format("YYYYMMDD") : 'null',
                    "name": "upperRapbackDate"
                }
                ,
                {
                    "value": insertdate?.length ? dayjs(insertdate[0]).format("YYYY-MM-DD") : 'null',
                    "name": "lowerInsertDate"
                },
                {
                    "value": insertdate?.length ? dayjs(insertdate[1]).format("YYYY-MM-DD") : 'null',
                    "name": "upperInsertDate"
                }
            ]

            let rapBackBodyRequest = {
                "queries": [
                    {
                        "queryParams": [
                           ...queryParams,
                            {
                                "value": order,
                                "name": "order"
                            }
                        ],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackTable" //RapBack Table? 
                    }
                ]
            }

            let rapBackCountRequest = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackTableCount"
                    }
                ]
            }

            const fetchRapBacks = async (rapBackBodyRequest: any, rapBackCountRequest: any) => {


                try {
                    const rapBackUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;

                    let i: number = 1;
                    setLoading(true)
    
                    const data = await (await fetch(rapBackUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(rapBackBodyRequest)})).json();

                    const countData = await (await fetch(rapBackUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(rapBackCountRequest)})).json();
 
                    const {results} = data['queriesResults'][0];
                    const count = countData['queriesResults'][0].results[0]['count'];
                    const transformedData = results.map((obj: any, index: number) => transform(obj, index));
                    console.log('transformedData rapback: ', transformedData)
                    setMappedData(transformedData);
                    setTotalRecords(count);
                    setLoading(false);
    
                } catch (err: any) {
                    setError(err);
                    setLoading(false);
                }
            }

    
            setLoading(true);
            fetchRapBacks(rapBackBodyRequest, rapBackCountRequest)
        }

        


        return () => {
            controller.abort();
        }
    }, [url, order, currentPage, pageSize, userRole, userORIs, softLimit, tableFiltersData])

    return { data: mapped, totalRecords, countError, loading }
}