/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect } from 'react'
import { urls } from '../urls'

export function useConfigService(fileName: String, searchParams: any, isGet: Boolean, isPost: Boolean) {
    const [data, setData] = React.useState<any>({});
    const [loadingConfig, setLoadingConfig] = React.useState(false);
    const [errorConfig, setErrorConfig] = React.useState(false);
    const configUrl = urls.CONFIG;
    const controller = new AbortController()
    const { signal } = controller
    const fetchUrl: string = `${process.env.NODE_ENV === 'production' ? configUrl : ""}/config/file/${fileName}`;
    useEffect(() => {
        console.log(`debug saved search: useConfigService useEffect  isGet: ${isGet} isPost: ${isPost} configUrl: ${configUrl}`)
        if (isGet && !isPost) {
            const fetchConfigFile = async () => {
                try {
                    setLoadingConfig(true);
                    setErrorConfig(true)
                    const resp = await (await fetch(fetchUrl, {
                        method: "GET",
                        credentials: "include",
                        signal
                    })).json();
                    const parsedResp = JSON.parse(resp.value)
                    setData(parsedResp)
                    console.log(`debug saved search: Successfully fetched ${fileName} from S3 and set saved searches`)
                    setLoadingConfig(false);
                    setErrorConfig(false)
                } catch (err: any) {
                    console.log(`Error getting ${fileName} from S3: `, err)
                }
            }
            fetchConfigFile();
            const refreshUrlInterval = setInterval(() => {
                fetchConfigFile();
            },60000);

            return () => {
                clearInterval(refreshUrlInterval);
                controller.abort();
            }
        } else if (isPost) {
            const updateConfigFile = async ()  => {
                try {
                    const signedUrlResp = await (await fetch(fetchUrl, {
                        method: "POST",
                        credentials: "include",
                        signal,
                        body: JSON.stringify(searchParams)
                    })).json();
                    if (signedUrlResp.url) {
                        console.log(`debug saved search: gotSigneUrlResp url ${signedUrlResp.url}`)
                        const resp = await fetch(signedUrlResp.url, {
                            method: "PUT",
                            headers: {
                            "Content-Type": "application/json",
                            },
                            body: JSON.stringify(searchParams)
                        });
                        console.log("debug saved search: Successfully uploaded saved searches to S3 with response:", await resp)
                    }
                } catch (err: any) {
                    console.log(`Error posting ${fileName}: `, err)
                }
            }
            updateConfigFile();
        }

    }, [isGet, isPost, configUrl, searchParams])

    return { data, loadingConfig, errorConfig };
}