/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import idemiaLogo from '../../assets/logo/idemiaLogoWhite.png'
import { Layout } from 'antd'

const {Footer} = Layout

export const FooterLayout = () => {
    return (
    <>
        <div style={{
            alignItems: 'center',
            justifyContent: 'center', 
            display: 'flex',
        }}>
            <img src={idemiaLogo} style={{width: '150px'}}/>
        </div>
        <Footer style={{
            alignItems: 'center',
            justifyContent: 'center', 
            display: 'flex',
            backgroundColor: '#1B1D1F',
            color: 'white',
            fontSize: '0.75em',
        }}>   
            Copyright @ 2023 IDEMIA Identity & Security USA LLC. All rights reserved.
        </Footer>
    </>
    )
};