/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC"s license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useState } from "react";
import { Image, Typography, Skeleton } from "antd";
import { SkeletonLoader } from "common";
import "./ApplicantDetailsHeader.scss";
import { ApplicantFetchStatus } from "hooks/shared/types";
import { role } from "constants/roles";
import {
  getEyeColor,
  getGender,
  getHairColor,
  getPOB,
  getRace,
} from "utils/getPersonAttributes";
import { formatDate } from "utils/formatDate";
import { UserOutlined } from "@ant-design/icons";
import { FormattedSSN } from "utils/formatSSN";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";



	


const { Title } = Typography;

interface ApplicantDetailsHeaderProps {
  userRole: string;
  userProfile: any;
  applicantImageUrl: string;
  applicantFetchStatus: ApplicantFetchStatus;
}

export const ApplicantDetailsHeader: React.FC<ApplicantDetailsHeaderProps> = ({
  userRole,
  userProfile,
  applicantImageUrl,
  applicantFetchStatus,
}) => {
  const {
    name,
    status,
    agency,
    tcn,
    ssn,
    birthDate,
    placeOfBirth,
    race,
    gender,
    height,
    weight,
    hairColor,
    eyeColor,
  } = userProfile;
  const isLoadingApplicant = applicantFetchStatus === "loading";
  console.log("debug applicantDetails header userProfile: ", userProfile);

  const formattedSSN = FormattedSSN(ssn)
  
  const displayAtrributes = () => {
    let SSN = formattedSSN;
    let DOB = birthDate;

    if (userRole === role.restrictedView) {
      SSN = "***-**-****";
      DOB = "** ** ****";
    }

    const attributes = {
      Status: status,
      DOB: DOB,
      Height: height,
      Agency: agency,
      POB: getPOB(placeOfBirth),
      Weight: `${weight} lbs`,
      TCN: tcn,
      Race: getRace(race),
      "Hair Color": getHairColor(hairColor),
      SSN: SSN || "N/A",
      Gender: getGender(gender),
      "Eye Color": getEyeColor(eyeColor),
    };

    return attributes;
  };

  console.log('attributes', displayAtrributes())

  console.log("update decision applicantImageUrl: ", applicantImageUrl);

  const [ssnValues, setSsnValues] = useState({
    SSN: displayAtrributes().SSN,
    showSSN: false
  });

  const handleClickShowSSN = () => {
    setSsnValues({...ssnValues, showSSN: !ssnValues.showSSN})
  }
  return (
    <div className="applicantDetailsHeader_container">
      <div className="person-container">
        {!isLoadingApplicant ? (
          <>
            {applicantImageUrl ? (
              <Image
                className="applicantDetailsHeader-image"
                src={applicantImageUrl}
              />
            ) : (
              <div
                style={{
                  width: "144px",
                  height: "153px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UserOutlined style={{ fontSize: "12em", color: "#7536C3" }} />
              </div>
            )}

            <Title className="applicantDetailsHeader-name" level={5}>
              {name}
            </Title>
          </>
        ) : (
          <Skeleton.Image active />
        )}
      </div>
      <div
        className={
          !isLoadingApplicant ? `applicantDetailsHeader-attributes` : ""
        }
      >
        <SkeletonLoader loading={isLoadingApplicant}>
          {Object.entries(displayAtrributes()).map(
            ([attributeName, attributeValue]) => (
              <div
                className="applicantDetailsHeader-attribute"
                key={attributeName}
              >
                <span className="attribute-label">{attributeName}</span>
                <span>
                  {attributeName === 'SSN' ? 
                    !ssnValues.showSSN ? 
                      displayAtrributes().SSN === 'N//A' ? 
                        'N/A' : 
                       displayAtrributes().SSN === 'N/A' ? 
                          'N/A' : 
                          "***-**-****" : 
                           displayAtrributes().SSN : 
                  attributeValue }  
                  {attributeName === 'SSN' ? 
                    displayAtrributes().SSN === 'N/A' ? 
                      undefined : !ssnValues.showSSN ? 
                        <EyeInvisibleOutlined onClick={handleClickShowSSN} style={{marginLeft: '2em'}}/> : 
                        <EyeOutlined  onClick={handleClickShowSSN} style={{marginLeft: '0.5em'}}/> : 
                  null}
                </span>
              </div>
            )
          )}
        </SkeletonLoader>
      </div>
    </div>
  );
};
