/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Input } from 'antd';
import debounce from 'debounce';
import { forwardRef, useEffect, useRef } from 'react';
import './NoteEditor.scss';
import NotesService from 'utils/notesService';
import { useParams } from 'react-router-dom';
import { disableInputForNonEdit } from 'utils/restrictionAccess';
import { useAudit } from '../../../hooks/useAudit';
import { Auth } from 'aws-amplify';
import { urls } from '../../../urls';
import he from 'he';

const { TextArea } = Input;

export const NoteEditor: React.FC<any> = forwardRef(
  ({ noteState, selectedNoteId, setHasBlankNote, userRole }, ref) => {
    const { notes, setNotes } = noteState;
    const { registrationId = '' } = useParams<{ registrationId: string }>();
    const url = urls.NOTES;
    let notesService = new NotesService(registrationId, 'note', url);

    const { addAuditEvent } = useAudit();

    console.log('debug notes userRole: ', userRole);
    const hasMatchingId = (note: any) => {
      return note.temporaryId
        ? note.temporaryId === selectedNoteId
        : note.id === selectedNoteId;
    };
    const selectedNote = notes.find(hasMatchingId);

    const currentlyDebouncedFunction = useRef(() => {});

    useEffect(() => {
      notesService = new NotesService(registrationId, 'note', url);
    }, [url]);

    const encodeText = (text: string) => {
      const sanitizedInput = text.replace(/[&<>"']/g, '');
      const encodedText = he.encode(sanitizedInput);
      console.log('encodedText', encodedText);
      return encodedText;
    };

    const updateSelectedNote = async (newNoteText: any) => {
      if (selectedNoteId === notes[0].temporaryId) {
        setHasBlankNote(false);
      }
      // @ts-ignore
      currentlyDebouncedFunction.current.clear?.();

      setNotes((currentNotes: any) => {
        return currentNotes.map((note: any) => {
          if (hasMatchingId(note)) {
            return {
              ...note,
              body: {
                note: encodeText(newNoteText),
              },
            };
          }

          return note;
        });
      });

      const { username } = await Auth.currentAuthenticatedUser();

      if (selectedNote.id) {
        const updateNoteService = debounce(() => {
          notesService
            .updateNote(selectedNote.id, encodeText(newNoteText))
            .then(() => {
              addAuditEvent(
                'Note Updated',
                `${username} udated note for registration ${registrationId} at ${new Date()}`,
                new Date(),
                undefined
              );
            });
        }, 3000);
        currentlyDebouncedFunction.current = updateNoteService;
        currentlyDebouncedFunction.current();
      } else {
        const addFetchedNoteData = debounce(() => {
          notesService
            .createNote(encodeText(newNoteText))
            .then((newNote: any) => {
              addAuditEvent(
                'Note Created',
                `${username} created note for registration id: ${registrationId} at ${new Date()}`,
                new Date(),
                undefined
              );

              const { body, ...newNoteWithoutBody } = newNote;

              setNotes((currentNotes: any) => {
                return currentNotes.map((note: any) => {
                  if (note.temporaryId === selectedNoteId) {
                    return {
                      temporaryId: note.temporaryId,
                      ...newNoteWithoutBody,
                      body: note.body,
                    };
                  }

                  return note;
                });
              });
            });
        }, 3000);
        currentlyDebouncedFunction.current = addFetchedNoteData;
        currentlyDebouncedFunction.current();
      }
    };
    useEffect(() => {
      console.log('notes', notes);
    }, [notes]);

    useEffect(() => {
      // @ts-ignore
      currentlyDebouncedFunction.current.flush?.();
      return () => {
        // @ts-ignore
        currentlyDebouncedFunction.current.flush?.();
      };
    }, [selectedNoteId]);

    return (
      <div className="note-editor">
        {selectedNoteId ? (
          <TextArea
            ref={ref as any}
            showCount
            maxLength={10000}
            value={selectedNote?.body?.note}
            onChange={(e) => updateSelectedNote(e.target.value)}
            style={{
              height: '100%',
              resize: 'none',
            }}
            disabled={disableInputForNonEdit(userRole)}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
);
