import { Button, Popover, Select } from "antd";
import { useState } from "react";

interface ColumnsVisibilityButtonProps {
  setActiveColumns: any
  defaultColumns: any
  dropDownOptions: any
}

export const ColumnsVisibilityButton = ({
    setActiveColumns,
    defaultColumns,
    dropDownOptions
  }: ColumnsVisibilityButtonProps) => {
    const [open, setOpen] = useState(false);
  
    return (
      <Popover
        content={
          <Select
            style={{ width: "100%" }}
            optionLabelProp="Filter Mode"
            mode="multiple"
            placeholder="Select Columns"
            defaultValue={defaultColumns}
            options={dropDownOptions}
            onChange={(value: any) => setActiveColumns(value)}
          />
        }
        title="Add / Remove Columns"
        open={open}
        onOpenChange={(isOpen) => setOpen(isOpen)}
        trigger="click"
      >
        <Button className="button" type="primary" size="large">
          Edit Columns
        </Button>
      </Popover>
    );
  };