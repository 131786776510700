/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React from 'react';
import { Table, TablePaginationConfig } from 'antd';

interface ApplicantsDetailsStatusHistoryProps {
  dataSource?: any
  loading: boolean
  pagination: false | TablePaginationConfig | undefined
}

export const ApplicantsDetailsStatusHistory: React.FC<ApplicantsDetailsStatusHistoryProps> = ({ dataSource, loading, pagination }) => {
    const statusHistoryColumns = [
        {
          title: 'Status',
          dataIndex: 'status'
        },
        {
          title: 'Status Date',
          dataIndex: 'statusDate'
        }
    ];

    return (
      <div className='table-background--alt'>
      <Table 
        dataSource={dataSource}
        columns={statusHistoryColumns}
        pagination={pagination}
        loading={loading}
        className="applicantsDetailsForm_form-table"
        rowClassName="applicantsDetailsForm_form-table-row"
        size='small'
         />
         </div>
    )
}
