import { DatePicker } from "antd";

export type ColumnName =
| "view"
| "firstName"
| "lastName"
| "middleName"
| "status"
| "transactionDate"
| "transactionType"
| "transactionNumber"
| "agency"
| "reasonFingerPrinted"
| "agencyIdentifier"
| "locked"
| "user";
  
  export enum FilterMode {
      AND = "and",
      OR = "or",
  }
  
  export enum TableScroll {
      GT1859 = "calc(100vh - 356px)",
      LT1859 = "calc(100vh - 378px)",
  }

  export type TableFilters = Partial<Readonly<Record<ColumnName, string>>>;
  const { RangePicker } = DatePicker;

  export const stateSpecficDefaultColumns = ( program:string ) => {
    switch(program){
      case 'caresnmdpsprod':
        case 'nmpreprod':
         return nmDefaultColumns;
      default:
        return ALL_COLUMNS;
    }
  }

  export const nmDefaultColumns = [
    "View",
    "First Name",
    "Last Name",
    "Status",
    "Transaction Date",
    "Transaction Number",
    "Transaction Type",
    "Agency",
    "Reason Finger Printed",
    ];
  
  
  export const ALL_COLUMNS = [ "View",
  "First Name",
  "Middle Name",
  "Last Name",
  "Status",
  "Transaction Date",
  "Transaction Number",
  "Transaction Type",
  "Agency",
  "Reason Finger Printed",
   "Locked",
   "User"];
  
  export const filterModeOptions = [
  { value: FilterMode.AND, label: "And" },
  { value: FilterMode.OR, label: "Or" },
  ];
  
  export const smallerColumns = ["firstName", "lastName", "status", "agency", "transactionType", "transactionDate"];