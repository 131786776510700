/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Button, Form, Input } from "antd";
import "./TransactionSummarySearch.scss";
import DateRangePicker, { RangeValue } from "components/DateRangePicker/DateRangePicker";
import { useState } from "react";
import { SavedSearchPopUp } from "../SavedSearches/SavedSearches";
import { SearchOutlined } from "@ant-design/icons";

interface FormValues {
  agency?: string;
  firstName?: string;
  lastName?: string;
  transactionDate?: RangeValue;
  status?: string;
  transactionNumber?: string;
  transactionType?: string;
};

const getFormattedValues = (formValues: FormValues) => {
  const formValuesMap = new Map();
  const formValueEntries = Object.entries(formValues);

  formValueEntries.forEach(([key, value]) => {
    if (!value) return;
    if (typeof value === "string") {
      formValuesMap.set(key, value.trim());
    }
    if (key === "transactionDate") {
      formValuesMap.set(key, value);
    }
  });

  return Object.fromEntries(formValuesMap);
};

const TransactionSummarySearch: React.FC<any> = ({setTableFilters, savedSearchState, formInstance, children}) => {
    const [currentFormValues, setCurrentFormValues] = useState<FormValues>({});
    const [values, setValues] = useState<any>({})
    const savedSearchPopUpOpenState = useState(false);
    const [, setSavedSearchPopUpOpen] = savedSearchPopUpOpenState;

    const isSaveDisabled = !Object.keys(getFormattedValues(currentFormValues)).length;

    const onSubmit = (formValues: FormValues) => {
        console.log('debug search_criteria onSubmit formValues: ', formValues)
        setValues(formValues)
        const formattedValues = getFormattedValues(formValues);
        //Pass a search key in values to signify search params.
        setTableFilters(formattedValues);
    };

    const onSave = () => setSavedSearchPopUpOpen(true);

    const handleChange = (e: any) => {
      console.log('debug search_criteria handleChange e: ', e.target.id)
      if(!e.target.value) {
        //setTableFilters({})
        let field = e.target.id.substring(e.target.id.indexOf("_") + 1)
        console.log('debug search_criteria field: ', field)
        console.log('debug search_criteria values: ', values)

        //const currentValues: any = Object.assign(values, {})
        values[field] = undefined
        console.log('debug search_criteria currentValues: ', values)
        onSubmit(values)
      }
    }

  return (
    <>
      <SavedSearchPopUp openState={savedSearchPopUpOpenState} searchFilter={currentFormValues} savedSearchState={savedSearchState}/>
      <div>
      <div className="container-header">SEARCH CRITERIA</div>
      <div className="summary-search-section-container">
        {/* <h2>Search Criteria</h2> */}
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onValuesChange={(_currentValue, allCurrentValues) => setCurrentFormValues(getFormattedValues(allCurrentValues))}
          autoComplete="off"
          form={formInstance}
          className="search-and-button-container"
        >
          <div className="search-anchor">
          <div className="search-criteria-container">
            <Form.Item label="First Name" name="firstName" ><Input onChange={(handleChange)} bordered={false}/></Form.Item>
            <Form.Item label="Last Name" name="lastName" ><Input onChange={(handleChange)} bordered={false}/></Form.Item>
            <Form.Item label="Status" name="status" ><Input onChange={(handleChange)} bordered={false}/></Form.Item>
            <Form.Item label="Transaction Date" name="transactionDate" ><DateRangePicker /></Form.Item>
            <Form.Item label="Transaction Number" name="transactionNumber" ><Input onChange={(handleChange)} bordered={false}/></Form.Item>
            <Form.Item label="Transaction Type" name="transactionType" ><Input onChange={(handleChange)} bordered={false}/></Form.Item>
            <Form.Item label="Agency" name="agency" ><Input onChange={(handleChange)} bordered={false}/></Form.Item>
          </div>
          </div>
          <div className="button-container">
            <Form.Item>
              <Button className="button" type="primary" htmlType="button" onClick={onSave} disabled={isSaveDisabled}>Save</Button>
            </Form.Item>
            <Form.Item>
              <Button className="button" type="primary" htmlType="reset" onClick={() => setTableFilters({})} >Clear</Button>
            </Form.Item>
            <Form.Item>
              <Button className="button" type="primary" htmlType="submit">Search <SearchOutlined /></Button>
            </Form.Item>
          </div>
          

        </Form>
      </div>
      </div>
      {children}
    </>
  );
};
export default TransactionSummarySearch;