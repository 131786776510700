import { getNameParts } from './getNameParts';
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import dayjs from "dayjs";

const fieldValue = (field: string | number | undefined) => field ?? "";

const displayHeightInFeet = (string: string | undefined) => {
  console.log("height string: ", string);
  let height = string ? `${string[0]}'${string.slice(1)}"` : "";
  console.log("height test: ", height);
  return height;
};

export type UserProfileInfo = ReturnType<typeof generateUserProfileInfo>;
export type PersonalInfo = ReturnType<typeof generatePersonalInformation>;
export type TransactionalInfo = ReturnType<
  typeof generateTransactionalInformation
>;
export type StatusHistories = ReturnType<typeof generateStatusHistories>;


export const generateUserProfileInfo = (applicantData: any, nistParsing: any) => {
    console.log('debug applicantData generateUserProfileInfo: ', applicantData)
    console.log('debug applicantData generateUserProfileInfo config nistParsing: ', nistParsing)
    const { active } = nistParsing
    console.log('debug applicantData generateUserProfileInfo config active: ', active)
    const { field9 } = applicantData.descriptors.data.type1;
    const { field16, field18, field20, field22, field24, field25, field27, field29, field31, field32, field118, field708} = applicantData.descriptors.data.type2;
    let name: any = []

    if(active) {
        const field = `field${nistParsing.field.key}` 
        console.log('debug applicantData generateUserProfileInfo config field: ', field)
        const fieldArray = applicantData.descriptors.data.type2[field]
        console.log('debug applicantData generateUserProfileInfo config fieldArray: ', fieldArray)
        const nameObj = fieldArray[0]
        console.log('debug applicantData generateUserProfileInfo config nameObj: ', nameObj)
        //Keep loop if keys can change otherwise use config 
        Object.keys(nameObj).forEach((key: string, index: number) => {
            if(index < 3) name.push(nameObj[key])
        })
    }

  let dataName;
if (process.env.REACT_APP_STAGE === 'mepreprod' || process.env.REACT_APP_STAGE === 'meprod') {
  dataName = {
    lastName:field708[0]?.V1_field708?.trim(),
    firstName:field708[0]?.V2_field708?.trim(),
    middleName:field708[0]?.V3_field708?.trim(),
    suffix:field708[0]?.V4_field708?.trim(),
  }
} 
else {
  dataName = generateDataName(field118, field18, field708);
}
    const { firstName, middleName, lastName, suffix } = dataName;

    let fullName = `${capitalizeFirstLetter(active ? name[1]! : firstName)} ${capitalizeFirstLetter(active ? name[2]! : middleName)} ${capitalizeFirstLetter(active ? name[0]! : lastName)}`;
    console.log('debug applicantData generateUserProfileInfo config fullName: ', fullName)
    console.log('debug applicantData generateUserProfileInfo header birthdate: ', dayjs(fieldValue(field22)).format('MM/DD/YYYY'))
    return {
        name: fieldValue(fullName),
        suffix,
        status: fieldValue(applicantData.attributes.finalDecision || applicantData.attributes.transactionStatus),
        agency: fieldValue(applicantData.attributes['ORIG-AGENCY']),
        tcn: fieldValue(field9),
        ssn: Array.isArray(field16) ? fieldValue(field16[0].V1_field16) : fieldValue(field16),
        birthDate: Array.isArray(field22) ? dayjs(fieldValue(field22[0].V1_field22)).format("MM/DD/YYYY") : dayjs(fieldValue(field22)).format("MM/DD/YYYY"),
        placeOfBirth: fieldValue(field20),
        race: fieldValue(field25),
        gender: fieldValue(field24),
        height: displayHeightInFeet(fieldValue(field27) as string),
        weight: fieldValue(field29),
        hairColor: fieldValue(field32),
        eyeColor: fieldValue(field31)
    }
}

export const generatePersonalInformation = (applicantData: any, nistParsing: any) => {
    console.log(`debug applicantData generatePersonalInfo applicantData ${JSON.stringify(applicantData, null, "\t")}`)
    const { field16, field18, field20, field21, field22, field24, field25, field27, field29, field31, field32, field41, field118, field708, field723} = applicantData.descriptors.data.type2;
    const { active } = nistParsing
    let name: any = []
    if(active) {
        const field = `field${nistParsing.field.key}` 
        const fieldArray = applicantData.descriptors.data.type2[field]
        const nameObj = fieldArray[0]
        Object.keys(nameObj).forEach((key: string, index: number) => {
            if(index < 3) name.push(nameObj[key])
        })
    }

    let dataName = generateDataName(field118, field18, field708);
    let residenceAddress = fieldValue(field41);

  if (process.env.REACT_APP_STAGE === 'mepreprod'|| process.env.REACT_APP_STAGE === 'meprod') {
    dataName = {
      lastName:field708[0]?.V1_field708?.trim(),
      firstName:field708[0]?.V2_field708?.trim(),
      middleName:field708[0]?.V3_field708?.trim(),
      suffix:field708[0]?.V4_field708?.trim(),
    }

    const adressValues = Object.values(field723[0] as string[])

    residenceAddress = adressValues.length ? adressValues.reduce<string>((acc, value)=>{
      if (! value.trim()) return acc
      return acc + value.trim() + ", "
    },"").slice(0,-2) : ""
  } 
  const { firstName, middleName, lastName, suffix } = dataName;
  

  return {
    firstName: active ? name[1] : firstName,
    middleName: active ? name[2] : middleName,
    lastName: active ? name[0] : lastName,
    suffix,
    residenceAddress,
    ssn: Array.isArray(field16) ? fieldValue(field16[0].V1_field16) : fieldValue(field16),
    birthDate: Array.isArray(field22) ? dayjs(fieldValue(field22[0].V1_field22)).format("MM/DD/YYYY") : dayjs(fieldValue(field22)).format("MM/DD/YYYY"),
    placeOfBirth: fieldValue(field20),
    countryOfCitizenship: fieldValue(field21),
    race: fieldValue(field25),
    gender: fieldValue(field24),
    height: displayHeightInFeet(fieldValue(field27) as string),
    weight: fieldValue(field29),
    hairColor: fieldValue(field32),
    eyeColor: fieldValue(field31),
  };
};

export const generateAggregatedResponses = (applicantData: any) => {
  return applicantData.descriptors.data.type2.field75;
};

export const generateTransactionalInformation = (applicantData: any) => {
  const { field4, field8, field9, field10 } =
    applicantData.descriptors.data.type1;
    const type2field9 = applicantData.descriptors.data.type2.field9;
    const {field37} = applicantData.descriptors.data.type2;

    console.log('!!!field37', field37)
    console.log('!!!field9', type2field9)
    console.log('!!!applicantData', applicantData);
  console.log(
    "debug applicantData applicantData.datelastmodified ?? '' :",
    dayjs(applicantData.datelastmodified).format("MM/DD/YYYY") ?? ""
  );
  return {
    ORI: field8,
    UEID: fieldValue(""),
    incomingTCN: fieldValue(field10), //this is tcr check
    transactionNumber: fieldValue(field9),
    transactionType: fieldValue(field4),
    assignedAgent: fieldValue(""),
    lastModifiedBy: applicantData.datelastmodified ?? "",
    statusTimeout: fieldValue(""),
    transactionTimeout: fieldValue(""),
    currentStatus: fieldValue(
      applicantData.attributes.finalDecision ||
        applicantData.attributes.transactionStatus
    ),
    agencyIdentifier: fieldValue(type2field9),
    reasonFingerprinted: fieldValue(field37)
  };
};

export const generateStatusHistories = (applicantData: any, index: any) => {
  console.log(
    "debug data applicantData in generateStatusHistories: ",
    applicantData
  );
  return {
    key: index,
    id: applicantData.uuid,
    status: applicantData.status,
    statusDate: applicantData.insert_date
      ? dayjs(applicantData.insert_date).format("MMMM Do YYYY, h:mm:ss a")
      : "",
    agency: applicantData.agency,
  };
};

export const generateDataName = (field118: any, field18: any, field708: any) => {
  let dataName: any = {
    fistName: '',
    middleName: '',
    lastName: '',
    suffix: ''
  };

  if(field118 && Array.isArray(field118)) {
    dataName.lastName = field118[0]?.V1_field118?.trim();
    dataName.firstName = field118[0]?.V2_field118?.trim();
    dataName.middleName = field118[0]?.V3_field118?.trim();
    dataName.suffix = field118[0]?.V4_field118?.trim();

    if(dataName.lastName === '' && dataName.firstName === '' && dataName.middleName === '') {
      dataName = getNameParts(field18);
    }
  } else {
    dataName = getNameParts(field18);
  }
  
  return dataName;
}