/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react';
import { generateAggregatedResponses } from 'utils/generatePersonData';
import { urls } from '../urls'
//import { ApplicantFetchStatus } from './shared/types';

export type ApplicantFetchStatus = 'idle' | 'loading' | 'success' | 'error';

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json');

export const useAggregatedResponses = (ids: any) => {
    console.log('debug useAggregatedResponses: ids', ids)
    const [applicantFetchStatus, setApplicantFetchStatus] = useState<ApplicantFetchStatus>('idle');
    const [aggregatedResponses, setaggregatedResponses] = useState([]);
    const url = urls.PERSON
    console.log('useAggResponses url: ', url)

    useEffect(() => {
        setApplicantFetchStatus('loading');
        const controller = new AbortController();
        const { signal } = controller;

        const fetchApplicantData = async () => {
            try {
                const responses: any = []
                ids.forEach( async (id: string) => {
                    console.log('debug response id: ', id)
                    const applicantData = await (await fetch(`${process.env.NODE_ENV === 'production' ? url : ""}/registration/${id}`, {
                        method: 'GET',
                        credentials: "include",
                        signal
                    })).json();

                    console.log('debug response applicantData: ', applicantData)

                    const responseInfo = generateAggregatedResponses(applicantData)

                    console.log('debug response resposneInfo: ', responseInfo)
                      
                    responses.push(responseInfo)
                    setaggregatedResponses(responses);
                })
            
                

                setApplicantFetchStatus('success');
            } catch (err) {
                console.error(err);
                setApplicantFetchStatus('error');
            }
        }

        //if(applicantFetchStatus !== 'loading') fetchApplicantData();
        return () => controller.abort();
    }, [ids.length, ids, applicantFetchStatus, url]);

    return { aggregatedResponses };
}