/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { AutoComplete, Form, Input, Select } from "antd";
import { USSTATES } from "../USStates";
import "../AgencyManagement.scss";

const fieldNames = [
  "agencyORI",
  "reasonFingerprinted",
  "agencyName",
  "distributionMethod",
  "agencyEmail",
  "agencyAddress",
  "agencyCity",
  "agencyState",
  "agencyZipCode",
  "agencyCounty"
];

const titleToNameMap = {
  agencyORI: "ORI",
  reasonFingerprinted: "Reason Fingerprinted",
  agencyName: "Name",
  distributionMethod: "Distribution Method",
  agencyEmail: "E-mail",
  agencyAddress: "Address",
  agencyCity: "City",
  agencyState: "State",
  agencyZipCode: "Zip Code",
  agencyCounty: "County"
} as any;

const distributionMethodOptions = [
  { label: "Applicant", value: "Applicant" },
  { label: "Agency", value: "Agency" },
  { label: "Both", value: "Both" },
  { label: "No Distribution", value: "No Distribution" },
];

const everythingAllowed = /.*/;

const inputsConfig = {
  agencyORI: {
    element: <Input bordered={false} autoComplete="none" />,
    required: true,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  reasonFingerprinted: {
    element: <Input bordered={false} autoComplete="none" />,
    required: true,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  agencyName: {
    element: <Input bordered={false} autoComplete="none" />,
    required: true,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  distributionMethod: {
    element: <Select options={distributionMethodOptions} />,
    required: true,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  agencyEmail: {
    element: <Input type="email" bordered={false} autoComplete="none" />,
    required: true,
    pattern: /\S+@\S+\.\S+/,
    validateTrigger: "onChange",
  },
  agencyAddress: {
    element: <Input bordered={false} autoComplete="none" />,
    required: false,
    pattern: everythingAllowed,
    validateTrigger: "onChange",
  },
  agencyState: {
    element: (
      <AutoComplete
        backfill
        options={USSTATES}
        filterOption={(inputValue, option) => option!.label.toUpperCase().startsWith(inputValue.toUpperCase())}
      />
    ),
    required: false,
    pattern: /^[^0-9]*$/,
    validateTrigger: "onBlur",
  },
  agencyCity: {
    element: <Input bordered={false} autoComplete="none" />,
    required: false,
    pattern: /^[^0-9]*$/,
    validateTrigger: "onChange",
  },
  agencyZipCode: {
    element: <Input bordered={false} autoComplete="none" />,
    required: false,
    pattern: /^\d{5}(?:[-\s]\d{4})?$/,
    validateTrigger: "onChange",
  },
  agencyCounty: {
    element: <Input bordered={false} autoComplete="none" />,
    required: false,
    pattern: /^[^0-9]*$/,
    validateTrigger: "onChange",
  },
} as any;

const NewFieldForm: React.FC<any> = ({ form, agencies }) => {
  return (
    <Form form={form} className="new-agency-form" layout="vertical">
      {fieldNames.map((fieldName) => {
        return (
          <Form.Item
            key={fieldName}
            name={fieldName}
            style={{ margin: 0, gridArea: fieldName }}
            label={titleToNameMap[fieldName]}
            validateTrigger={inputsConfig[fieldName].validateTrigger ?? "onChange"}
            rules={[
              (form) => {
                const { required = false, pattern = null } = inputsConfig[fieldName] || {};
                return {
                required,
                pattern,
                message: `Please Input a Valid ${titleToNameMap[fieldName]}!`,
                validator: async (_, fieldValue) => {
                  if (required && (!fieldValue || fieldValue.toString().trim() === ''))
                    throw new Error(`Please Input an ${titleToNameMap[fieldName]} !`);

                  if (pattern && fieldValue && !pattern.test(fieldValue))
                    throw new Error(`Please Input a Valid ${titleToNameMap[fieldName]}!`);

                  if (fieldName === "agencyState") {
                    if (!fieldValue) return;
                    const matchingUSState = USSTATES.find(
                      (state) => state.value.toUpperCase() === fieldValue.toUpperCase() ||
                                 state.label.toUpperCase() === fieldValue.toUpperCase()
                    );
                    if (!matchingUSState) throw new Error("Please Input a Valid State!");
                    form.setFieldsValue({ agencyState: matchingUSState.value });
                  }
                  if (fieldName === "agencyORI") {
                    agencies.forEach((agency: any) => {
                      if (agency.agencyORI === fieldValue) throw new Error("Agency ORI already exists!");
                    })
                  }
                },
              }},
            ]}
          >
            {inputsConfig[fieldName]?.element ?? <Input />}
          </Form.Item>
        );
      })}
    </Form>
  );
};

export default NewFieldForm;
